import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';

import { toast } from 'core/utils/toast';

import { PersonalAnnotations } from 'core/annotation/queries';
import { AssociateAnnotationsToAnnotationBundle } from 'core/bundle/mutations';
import { MyBundle } from 'core/bundle/queries';

import {
  FlexRowWrapWrapper,
  RightAlignedWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { AuthButton } from 'views/elements/Buttons';
import { CircleLoader } from 'views/elements/Loaders';
import { Info } from 'views/elements/Texts';
import { Annotation } from 'views/pages/NewBundle/Annotation';

export class AssociateAnnotations extends React.Component {
  handleInputChange = (ev, field, values, setFieldValue, counterValues) => {
    const {
      target: { value, checked },
    } = ev;

    if (checked) {
      values.push(value);
      const index = counterValues.indexOf(value);
      if (index !== -1) counterValues.splice(index, 1);
    } else {
      const index = values.indexOf(value);
      if (index !== -1) values.splice(index, 1);
    }
    setFieldValue(field, values);
  };

  render() {
    const {
      props: { bundleId, historyPush },
      handleInputChange,
    } = this;

    return (
      <React.Fragment>
        <Mutation
          mutation={AssociateAnnotationsToAnnotationBundle}
          refetchQueries={[
            {
              query: MyBundle,
              variables: {
                bundle_id: bundleId,
              },
            },
          ]}
          onCompleted={() => {
            toast.success('Anotações associadas com successo');
            historyPush(`/my_bundles/${bundleId}`);
          }}
          onError={() => toast.error('Não foi possível associar as anotações')}
        >
          {mutate => (
            <Formik
              initialValues={{
                copy_annotations: [],
                move_annotations: [],
              }}
              onSubmit={(values, actions) =>
                mutate({
                  variables: {
                    data: {
                      bundle_id: bundleId,
                      copy_annotations: values.copy_annotations,
                      move_annotations: values.move_annotations,
                    },
                  },
                })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                values,
              }) => (
                <Form>
                  <FlexRowWrapWrapper>
                    <Query query={PersonalAnnotations}>
                      {({ data, loading, error }) => {
                        if (loading)
                          return (
                            <CenteredWrapper>
                              <CircleLoader />
                            </CenteredWrapper>
                          );
                        if (
                          error ||
                          data.personalAnnotations == null ||
                          data.personalAnnotations.length === 0
                        )
                          return (
                            <CenteredWrapper>
                              <Info>Não possui anotações próprias</Info>
                            </CenteredWrapper>
                          );

                        const options = data.personalAnnotations.map(
                          annotation => ({
                            id: annotation.id,
                            text: annotation.text,
                            name: `annotation_${annotation.id}`,
                            contentName: annotation.section.content.name,
                            sectionName: annotation.section.full_name,
                          }),
                        );

                        return options.map(option => (
                          <Annotation
                            key={option.id}
                            option={option}
                            copyAnnotations={values.copy_annotations}
                            moveAnnotations={values.move_annotations}
                            handleInputChange={handleInputChange}
                            setFieldValue={setFieldValue}
                          />
                        ));
                      }}
                    </Query>
                  </FlexRowWrapWrapper>
                  <RightAlignedWrapper>
                    {values.copy_annotations.length +
                      values.move_annotations.length >
                      0 && (
                      <AuthButton type="submit" disabled={isSubmitting}>
                        associar
                      </AuthButton>
                    )}
                    <Link to={`/my_bundles/${bundleId}`}>
                      <AuthButton>cancelar</AuthButton>
                    </Link>
                  </RightAlignedWrapper>
                </Form>
              )}
            />
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

AssociateAnnotations.propTypes = {
  bundleId: PropTypes.string,
  historyPush: PropTypes.func,
};
