import React from 'react';
// import PropTypes from 'prop-types';

import styled from 'styled-components';

import { eventManager, ACTION } from 'core/utils/toast';

import Toast from './Toast';

const StyledToastContainer = styled.div`
  z-index: 9;
  position: fixed;
  right: 3rem;
  top: 0;
  padding-top: 13rem;
`;

export default class ToastContainer extends React.PureComponent {
  state = {
    toasts: [],
  };

  componentDidMount() {
    eventManager
      .on(ACTION.SHOW, (content, options) => this.show(content, options))
      .on(
        ACTION.CLEAR,
        id => (id !== null ? this.removeToast(id) : this.clear()),
      );
  }

  componentWillUnmount() {
    eventManager.off(ACTION.SHOW).off(ACTION.CLEAR);
  }

  clear() {
    this.setState({ toasts: [] });
  }

  removeToast(id) {
    this.setState({
      toasts: this.state.toasts.filter(t => t.toastId !== id),
    });
  }

  show(content, options) {
    const newToast = {
      text: content,
      ...options,
    };
    this.setState({ toasts: [newToast, ...this.state.toasts] });
  }

  render() {
    const { toasts } = this.state;
    return (
      <StyledToastContainer>
        {toasts.map(t => <Toast key={t.toastId} {...t} />)}
      </StyledToastContainer>
    );
  }
}
