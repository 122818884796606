import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApollo } from 'react-apollo';

import { GetApp } from 'core/app/queries';

import { CenteredWrapper } from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';
import { Info } from 'views/elements/Texts';

import CPC from './cpc';
import FAM from './fam';
import LMS from './lms';

const APPS = {
  cpc: CPC,
  fam: FAM,
  lms: LMS,
};

class AppScreen extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        slug: PropTypes.string,
      }),
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
    client: PropTypes.shape({
      query: PropTypes.func,
    }),
  };

  state = {
    app: null,
  };

  getApp = slug =>
    this.props.client
      .query({
        query: GetApp,
        variables: {
          slug,
        },
      })
      .then(({ loading, error, data }) => {
        if (loading) return;
        if (error) {
          this.setState({ app: { slug: '404' } });
          return;
        }

        const { app } = data;

        if (app == null) {
          this.setState({ app: { slug: '404' } });
          return;
        }

        this.setState({ app });
      });

  render() {
    const {
      props: {
        match: {
          params: { slug },
        },
      },
      state: { app },
      getApp,
    } = this;

    if (app == null) getApp(slug);
    if (app != null && app.slug !== slug) getApp(slug);

    const App = app != null ? APPS[app.slug] : null;

    return (
      <React.Fragment>
        {app != null ? (
          App != null ? (
            <App app={app} {...this.props} />
          ) : (
            <CenteredWrapper>
              <Info>Este conteúdo não está disponível</Info>
            </CenteredWrapper>
          )
        ) : (
          <CenteredWrapper>
            <CircleLoader slug={slug} />
          </CenteredWrapper>
        )}
      </React.Fragment>
    );
  }
}

export default withApollo(AppScreen);
