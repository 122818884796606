import gql from 'graphql-tag';

export const CreateAnnotationBundle = gql`
  mutation createAnnotationBundle($data: CreateAnnotationBundleInput!) {
    createAnnotationBundle(input: $data) {
      id
      title
      description
      annotations_count
    }
  }
`;

export const UpdateAnnotationBundle = gql`
  mutation updateAnnotationBundle($data: UpdateAnnotationBundleInput!) {
    updateAnnotationBundle(input: $data) {
      id
      title
      description
      annotations_count
      owner
      annotations {
        id
        text
      }
    }
  }
`;

export const InviteAuthor = gql`
  mutation inviteAuthorToAnnotationBundle(
    $data: InviteAuthorToAnnotationBundleInput!
  ) {
    inviteAuthorToAnnotationBundle(input: $data)
  }
`;

export const RemoveAuthor = gql`
  mutation removeAuthorFromAnnotationBundle(
    $data: RemoveAuthorFromAnnotationBundleInput!
  ) {
    removeAuthorFromAnnotationBundle(input: $data)
  }
`;

export const AcceptInvite = gql`
  mutation acceptInviteToAnnotationBundle(
    $data: AcceptInviteToAnnotationBundleInput!
  ) {
    acceptInviteToAnnotationBundle(input: $data) {
      id
    }
  }
`;

export const DeclineInvite = gql`
  mutation declineInviteToAnnotationBundle(
    $data: DeclineInviteToAnnotationBundleInput!
  ) {
    declineInviteToAnnotationBundle(input: $data) {
      id
    }
  }
`;

export const AssociateAnnotationsToAnnotationBundle = gql`
  mutation associateAnnotationsToAnnotationBundle(
    $data: AssociateAnnotationsToAnnotationBundleInput!
  ) {
    associateAnnotationsToAnnotationBundle(input: $data) {
      id
      title
      description
      annotations_count
      owner
      annotations {
        id
        text
      }
    }
  }
`;

export const SellAnnotationBundle = gql`
  mutation sellAnnotationBundle($data: SellAnnotationBundleInput!) {
    sellAnnotationBundle(input: $data) {
      id
      price
      buyable
    }
  }
`;
