import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { BuyAnnotationBundle } from 'core/purchase/mutations';
import { GetBuyableBundles, GetBuyableBundle } from 'core/bundle/queries';
import { listNames } from 'core/utils/helpers';

import { Checkout } from 'views/components/Checkout';

import {
  FlexSpaceBetweenWrapper,
  FlexColWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { PremiumBundleCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

const Bundle = ({ id }) => (
  <Query query={GetBuyableBundle} variables={{ id }}>
    {({ data, loading, error }) => {
      if (loading)
        return (
          <CenteredWrapper>
            <CircleLoader />
          </CenteredWrapper>
        );
      if (error || data.buyableBundle == null)
        return (
          <CenteredWrapper>
            <Info>Bundle não encontrado</Info>
          </CenteredWrapper>
        );

      const bundle = { ...data.buyableBundle };

      return (
        <PremiumBundleCard>
          <FlexSpaceBetweenWrapper>
            <FlexColWrapper>
              <h1>{bundle.title}</h1>
              <h2>{bundle.description}</h2>
              <h2>
                {bundle.annotations_count} anotações distribuídas por{' '}
                {listNames(bundle.contents)}
              </h2>
              <h3>$RS {bundle.price}</h3>
            </FlexColWrapper>
            <Checkout
              mutation={BuyAnnotationBundle}
              mutationData={{ bundle_id: bundle.id }}
              refetchQueries={[{ query: GetBuyableBundles }]}
              price={bundle.price}
              description={`Collab Bundle ${bundle.id}`}
              active={!bundle.purchased}
            />
          </FlexSpaceBetweenWrapper>
          <h4>Anotação Exemplo:</h4>
          <h5>{bundle.free_annotation.section.full_name}</h5>
          <h6>{bundle.free_annotation.section.content.name}</h6>
          <p>{bundle.free_annotation.text}</p>
          <h4>
            Anotações por {listNames(bundle.annotations_authors)}
            .
          </h4>
        </PremiumBundleCard>
      );
    }}
  </Query>
);

Bundle.propTypes = {
  id: PropTypes.string,
};

export default Bundle;
