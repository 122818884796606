import React from 'react';
import PropTypes from 'prop-types';
import Raven from 'raven-js';
import { DEV } from 'core/constants';

class PotentialError extends React.Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
  };

  componentDidCatch(error) {
    if (!DEV) {
      Raven.captureException(error);
    }
  }

  render() {
    return this.props.children;
  }
}

export default PotentialError;
