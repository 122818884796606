import React from 'react';
import PropTypes from 'prop-types';

import { TitleWrapper } from 'views/elements/Wrappers';

export const TitleCard = ({
  data,
  selectedCode,
  selectedPart,
  selectedBook,
  selectedTitle,
  selectTitle,
}) => {
  const path = selectedPart
    ? `/app/cpc/code/${selectedCode}/part/${selectedPart}/book/${selectedBook}/title/${
        data.number
      }`
    : `/app/cpc/code/${selectedCode}/book/${selectedBook}/title/${data.number}`;

  return (
    <TitleWrapper
      to={path}
      selected={selectedTitle === data.number}
      onClick={() => selectTitle(data.number)}
      slug="cpc"
    >
      <h1>{data.name}</h1>
      <h2>{data.text}</h2>
      <h3>{data.info}</h3>
    </TitleWrapper>
  );
};

TitleCard.propTypes = {
  data: PropTypes.shape({}),
  selectedCode: PropTypes.string,
  selectedPart: PropTypes.number,
  selectedBook: PropTypes.number,
  selectedTitle: PropTypes.number,
  selectTitle: PropTypes.func,
};

export default TitleCard;
