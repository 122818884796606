import React, { Component } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { toast } from 'core/utils/toast';
import { login, loginOAuth } from 'core/user/api';

import { UserConsumer } from 'views/providers/user';
import Facebook from 'views/components/Facebook';
import Google from 'views/components/Google';

import emailIcon from 'views/assets/icons/mail.svg';
import { AuthCard } from 'views/elements/Cards';
import { AuthLink, SocialButton } from 'views/elements/Buttons';

import { LoginForm } from './LoginForm';

export class LoginScreen extends Component {
  setError = message => {
    toast.error(message);
  };

  handleOAuthLogin = (oauthUser, loginUser) => {
    loginOAuth(oauthUser)
      .then(async res => {
        if (res.ok) {
          const user = await res.json();
          const token = `Bearer ${user.jwt}`;
          loginUser(user, token);
        } else {
          toast.error('Não foi possível efetuar o login');
        }
      })
      .catch(error => {
        toast.error('Ocorreu um problema');
      });
  };

  handleSubmit = (values, actions, loginUser) => {
    login(values.email, values.pass)
      .then(async res => {
        if (res.ok) {
          const token = res.headers.get('authorization');
          const user = await res.json();
          loginUser(user, token);
        } else {
          actions.setSubmitting(false);
          toast.error('Não foi possível efetuar o login');
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        toast.error('Ocorreu um problema');
      });
  };

  render() {
    const { handleSubmit, handleOAuthLogin, setError } = this;

    return (
      <AuthCard>
        <UserConsumer>
          {({ actions: { loginUser } }) => (
            <Switch>
              <Route
                exact
                path="/login/email"
                render={() => (
                  <LoginForm
                    handleSubmit={handleSubmit}
                    loginUser={loginUser}
                  />
                )}
              />
              <Route
                exact
                path="/login"
                render={() => (
                  <React.Fragment>
                    <Facebook
                      loginFacebook={handleOAuthLogin}
                      setError={setError}
                      loginUser={loginUser}
                    />
                    <Google
                      loginGoogle={handleOAuthLogin}
                      setError={setError}
                      loginUser={loginUser}
                    />
                    <Link to="/login/email">
                      <SocialButton>
                        <SVG src={emailIcon} />
                        <p>Continuar com o seu email</p>
                      </SocialButton>
                    </Link>
                    <AuthLink to="/signup">
                      Ainda não tem conta? Cadastre-se usando o seu email.
                    </AuthLink>
                  </React.Fragment>
                )}
              />
            </Switch>
          )}
        </UserConsumer>
      </AuthCard>
    );
  }
}

export default LoginScreen;
