import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Info = styled.h1`
  text-transform: uppercase;
  font-size: 1.2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textGreySecondary};
  text-align: center;
  padding: 1rem;
`;

export const Text = styled.p`
  font-size: 1.8rem;
  color: ${({ theme }) => theme.colors.textPrimary}
  font-family: 'Source Sans Pro', sans-serif;
  white-space: pre-wrap;
  line-height: 2.8rem;
`;

export const Title = styled.h1`
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin-bottom: 1rem;
`;

export const SubTitle = styled.h2`
  font-size: 1.4rem;
  font-weight: 100;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin-bottom: 1rem;
`;
export const Error = styled.p`
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme }) => theme.colors.textError};
`;
Error.displayName = 'Error';

export const BookTitle = styled.h1`
  font-size: 2.8rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding-left: 5rem;
  margin-top: 2.2rem;
`;

export const TitleTitle = styled.h2`
  text-transform: uppercase;
  font-size: 1.8rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  padding-left: 6rem;
  margin-top: 2.1rem;
`;

export const TitleInfo = styled.h3`
  text-transform: uppercase;
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding-left: 6rem;
  margin-top: 1.2rem;
  font-weight: 100;
`;

export const ChapterTitle = styled.h2`
  text-transform: uppercase;
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  padding-left: 7rem;
  margin-top: 2.1rem;
`;

export const ChapterInfo = styled.h3`
  text-transform: uppercase;
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding-left: 7rem;
  margin-top: 1.2rem;
  font-weight: 100;
`;

export const ArticleTitleLink = styled(Link)`
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin-left: 1.3rem;
  font-family: 'Futura', sans-serif;
  font-weight: bold;
`;

export const ArticleTitle = styled.h1`
  text-transform: uppercase;
  font-size: 1.4rem;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  font-family: 'Futura', sans-serif;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const AnnotationTitle = styled.h1`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.textPrimary}
  font-family: 'Futura', sans-serif;
  font-weight: bold;
  margin-bottom: 2rem;
`;

export const LawTitle = styled.h1`
  font-size: 2.8rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  padding-left: 5rem;
  margin-top: 2.2rem;
`;

export const NotFoundTitle = styled.h1`
  font-size: 2.8rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin-bottom: 1rem;
`;

export const CardTitle = styled.h1`
  font-size: 2.4rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  margin: 1rem 0 3rem 0;
  height: 100%;
  color: ${({ theme }) => theme.appColors.anotado.primary};
`;

export const CardSubTitle = styled.h2`
  font-size: 2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  margin: 1rem 0 3rem 0;
  height: 100%;
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const CardText = styled.p`
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  margin: 1rem 0 3rem 1rem;
  height: 100%;
  color: ${({ theme }) => theme.colors.textSecondary};
`;

export const TrialText = styled.p`
  font-size: 1.2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.textGreySecondary};
  text-align: center;

  button {
    font-size: 1.2rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.textPrimary};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const AdvancedSearch = styled.h1`
  font-size: 1.8rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin: 2rem 0;
`;

export const BackTitle = styled.h1`
  font-size: 2.2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  margin: 2rem 0;
`;

export const SearchOption = styled.h1`
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  color: ${({ theme }) => theme.colors.textPrimary};
  padding: 0 1rem;
`;
