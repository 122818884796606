import React from 'react';
import PropTypes from 'prop-types';

import SVG from 'react-inlinesvg';
import styled, { keyframes } from 'styled-components';

import successIcon from 'views/assets/icons/success.svg';
import errorIcon from 'views/assets/icons/error.svg';
import { eventManager, ACTION, TYPE } from 'core/utils/toast';

const bounceInRight = keyframes`
  from,
  60%,
  75%,
  90%,
  to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1); }
  from {
    opacity: 0;
    transform: translate3d(3000px, 0, 0); }
  60% {
    opacity: 1;
    transform: translate3d(-25px, 0, 0); }
  75% {
    transform: translate3d(10px, 0, 0); }
  90% {
    transform: translate3d(-5px, 0, 0); }
  to {
    transform: none; } }
`;

const bounceOutRight = keyframes`
  20% {
    opacity: 1;
    transform: translate3d(-20px, 0, 0); }
  to {
    opacity: 0;
    transform: translate3d(2000px, 0, 0); } }
`;

const COLORS = {
  [TYPE.INFO]: {
    background: '#eaf0fa',
    shadow: '#e2e2e2',
    text: '#428ef8',
  },
  [TYPE.ERROR]: {
    background: '#ffe3e2',
    shadow: '#e2e2e2',
    text: '#d93a37',
  },
  [TYPE.SUCCESS]: {
    background: '#dffcf6',
    shadow: '#e2e2e2',
    text: '#3bccb2',
  },
};

const ICONS = {
  [TYPE.INFO]: null,
  [TYPE.ERROR]: errorIcon,
  [TYPE.SUCCESS]: successIcon,
};

const StyledCloseIcon = styled.div`
  width: 1.4rem;
  height: 1.4rem;
  cursor: pointer;
  margin-left: 1rem;
`;

const CloseIcon = ({ color, onClick }) => (
  <StyledCloseIcon onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14">
      <g fill="none" fillRule="evenodd">
        <circle cx="7" cy="7" r="7" fill={color} />
        <path
          fill="#FBFBFB"
          d="M7.53 7l1.86-1.86-.53-.53L7 6.47 5.14 4.61l-.53.53L6.47 7 4.61 8.86l.53.53L7 7.53l1.86 1.86.53-.53z"
        />
      </g>
    </svg>
  </StyledCloseIcon>
);
CloseIcon.propTypes = {
  color: PropTypes.string,
  onClick: PropTypes.func,
};
CloseIcon.defaultProps = {
  color: '#428EF8',
  onClick: () => null,
};

const ToastWrapper = styled.div`
  width: 100%
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  border-radius: 4px;
  background-color: ${({ type }) => COLORS[type].background};
  box-shadow: 0 6px 15px 0 ${({ type }) => COLORS[type].shadow};
  margin-bottom: 1.5rem;
  padding: 1rem;
  cursor: pointer;
  min-width: 35rem;
  animation: ${({ out }) => (!out ? bounceInRight : bounceOutRight)} 800ms
    linear;
`;

const StyledText = styled.div`
  font-size: 1.4rem;
  color: ${({ type }) => COLORS[type].text};
`;

const LeftIcon = styled.div`
  margin-right: 0.9rem;
`;

const IconText = styled.div`
  display: flex;
  align-items: center;
`;

export default class Toast extends React.PureComponent {
  static propTypes = {
    type: PropTypes.string,
    text: PropTypes.string,
    toastId: PropTypes.number.isRequired,
    closeButton: PropTypes.bool,
    autoClose: PropTypes.number,

    // pauseOnHover: PropTypes.bool.isRequired,
    // pauseOnFocusLoss: PropTypes.bool.isRequired,

    // onExited: PropTypes.func,
    onOpen: PropTypes.func,
    onClose: PropTypes.func,
  };

  static defaultProps = {
    autoClose: 5000,
    closeButton: true,
  };

  state = {
    closing: false,
  };

  componentDidMount() {
    const { onOpen, autoClose } = this.props;
    onOpen && onOpen();
    if (autoClose > 0) {
      this.timerClose = setTimeout(this.handleClose, autoClose);
    }
  }

  componentWillUnmount() {
    this.props.onClose && this.props.onClose();
    this.timerClose && clearTimeout(this.timerClose);
    this.timerClear && clearTimeout(this.timerClear);
  }

  handleClose = () => {
    this.setState({ closing: true });
    this.timerClear = setTimeout(
      () => eventManager.emit(ACTION.CLEAR, this.props.toastId),
      800,
    );
  };

  render() {
    const { type, text, closeButton } = this.props;
    return (
      <ToastWrapper
        out={this.state.closing}
        type={type}
        onClick={this.handleClose}
      >
        <IconText>
          <LeftIcon>
            {ICONS[type] && <SVG src={ICONS[type]} cacheGetRequests />}
          </LeftIcon>
          <StyledText type={type}>{text}</StyledText>
        </IconText>
        {closeButton && (
          <CloseIcon onClick={this.handleClose} color={COLORS[type].text} />
        )}
      </ToastWrapper>
    );
  }
}
