import React from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import back from 'views/assets/icons/back.svg';
import { FlexRowWrapper } from 'views/elements/Wrappers';
import { BackTitle } from 'views/elements/Texts';
import { BackButton } from 'views/elements/Buttons';

export const Back = ({ slug, url, title }) => (
  <FlexRowWrapper style={{ marginBottom: '4rem' }}>
    <BackButton slug={slug} to={url}>
      <SVG src={back} />
    </BackButton>
    <BackTitle slug={slug}>{title}</BackTitle>
  </FlexRowWrapper>
);

Back.propTypes = {
  slug: PropTypes.string,
  url: PropTypes.string,
  title: PropTypes.string,
};

export default Back;
