import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { Query } from 'react-apollo';

import { MyBundle } from 'core/bundle/queries';

import { CardTitle, CardSubTitle, Info } from 'views/elements/Texts';
import { CenteredWrapper } from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';

import { Annotation } from './Annotation';
import { AssociateAnnotations } from './AssociateAnnotations';
import { NewAnnotation } from './NewAnnotation';
import { Bundle } from './Bundle';
import { SellBundle } from './Sell';

export const MyBundleScreen = ({
  match: {
    path,
    url,
    params: { id },
  },
  history: { push },
}) => (
  <Query query={MyBundle} variables={{ bundle_id: id }}>
    {({ data, loading, error }) => {
      if (loading)
        return (
          <CenteredWrapper>
            <CircleLoader />
          </CenteredWrapper>
        );
      if (error || data.myBundle == null)
        return (
          <CenteredWrapper>
            <Info>Bundle não encontrado</Info>
          </CenteredWrapper>
        );

      const { myBundle } = data;
      return (
        <Switch>
          <Route
            exact
            path={`${path}/new_annotation`}
            render={() => (
              <React.Fragment>
                <Link to={url}>
                  <CardTitle>{myBundle.title}</CardTitle>
                </Link>
                <CardSubTitle>Nova Anotação</CardSubTitle>
                <NewAnnotation bundleId={id} historyPush={push} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={`${url}/associate_annotations`}
            render={() => (
              <React.Fragment>
                <Link to={url}>
                  <CardTitle>{myBundle.title}</CardTitle>
                </Link>
                <CardSubTitle>Associar Anotações</CardSubTitle>
                <AssociateAnnotations bundleId={id} historyPush={push} />
              </React.Fragment>
            )}
          />
          <Route
            exact
            path={`${url}/annotation/:annotationId`}
            render={props => (
              <React.Fragment>
                <Link to={url}>
                  <CardTitle>{myBundle.title}</CardTitle>
                </Link>
                <CardSubTitle>Anotação</CardSubTitle>
                <Annotation
                  bundleId={id}
                  annotationId={props.match.params.annotationId}
                  historyPush={push}
                />
              </React.Fragment>
            )}
          />
          <Route
            path={`${url}/sell`}
            render={() => (
              <React.Fragment>
                <Link to={url}>
                  <CardTitle>{myBundle.title}</CardTitle>
                </Link>
                <CardSubTitle>Vender</CardSubTitle>
                <SellBundle bundleId={id} historyPush={push} />
              </React.Fragment>
            )}
          />
          <Route
            path={path}
            render={() => (
              <Bundle bundle={myBundle} url={url} historyPush={push} />
            )}
          />
        </Switch>
      );
    }}
  </Query>
);

MyBundleScreen.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      id: PropTypes.string.isRequired,
      annotationId: PropTypes.string,
    }),
  }),
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default MyBundleScreen;
