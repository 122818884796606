import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';
import { Switch, Route } from 'react-router-dom';
import { Query } from 'react-apollo';

import { GetApps } from 'core/app/queries';

import logo from 'views/assets/logo.svg';

import { LeftSidebarWrapper, FlexColWrapper } from 'views/elements/Wrappers';
import { NavButton } from 'views/elements/Buttons';
import { NavSeparator } from 'views/elements/Separators';
import { SidebarSearchBar } from 'views/elements/Inputs';

export class Sidebar extends Component {
  state = {
    text: '',
    slug: null,
  };

  changeSlug = slug => this.setState({ slug });
  changeText = text => this.setState({ text });

  handleKeyDown = e => {
    if (e.key === 'Enter') {
      const { text, slug } = this.state;
      const encodedText = encodeURIComponent(text);

      this.props.history.push(
        `/search?text=${encodedText}${
          slug != null ? `&app=${slug}` : ''
        }&source=sidebar`,
      );
    }
  };

  render() {
    const {
      state: { text, slug },
      changeSlug,
      changeText,
      handleKeyDown,
    } = this;

    return (
      <LeftSidebarWrapper>
        <FlexColWrapper>
          <SVG src={logo} />
          <SidebarSearchBar
            value={text}
            placeholder="Pesquisar..."
            onChange={e => changeText(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <NavButton to="/apps">Minha Biblioteca</NavButton>
          <Switch>
            <Route
              exact={false}
              path={['/app/:slug/bookmarks', '/app/:slug', '/apps']}
              render={props => {
                const selectedApp = props.match.params.slug;
                if (selectedApp != null && selectedApp !== slug)
                  changeSlug(selectedApp);

                return (
                  <Query query={GetApps}>
                    {({ loading, error, data }) => {
                      if (loading) return null;
                      if (error || data.apps == null || data.apps.length === 0)
                        return null;

                      const { apps } = data;

                      return apps.map(app => (
                        <React.Fragment key={app.id}>
                          <NavButton
                            level={1}
                            to={`/app/${app.slug}`}
                            slug={app.slug}
                            selected={props.match.params.slug === app.slug}
                          >
                            {app.name}
                          </NavButton>
                          {props.match.params.slug === app.slug && (
                            <NavButton
                              level={2}
                              to={`/app/${app.slug}/bookmarks`}
                              slug={app.slug}
                              selected={props.match.url.includes('/bookmarks')}
                            >
                              Favoritos
                            </NavButton>
                          )}
                        </React.Fragment>
                      ));
                    }}
                  </Query>
                );
              }}
            />
          </Switch>
          <NavSeparator />
          <NavButton to="/search">Procurar</NavButton>
          <NavSeparator />
          <NavButton to="/premium">Mercado Premium</NavButton>
          <NavSeparator />
          <NavButton to="/collaborative">Mercado Colaborativo</NavButton>
          <NavSeparator />
          <NavButton to="/redeem">Redeem</NavButton>
          <NavSeparator />
          <NavButton to="/new_bundle">Criar Bundle</NavButton>
          <NavSeparator />
          <NavButton to="/my_bundles">Meus Bundles</NavButton>
          <NavSeparator />
        </FlexColWrapper>
        <FlexColWrapper>
          <NavSeparator />
          <NavButton to="/profile">Perfil</NavButton>
          <NavSeparator />
        </FlexColWrapper>
      </LeftSidebarWrapper>
    );
  }
}

Sidebar.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};
