import React from 'react';
import { Query, Mutation } from 'react-apollo';

import { toast } from 'core/utils/toast';
import { GetCurrentUser } from 'core/user/queries';
import { ActivateTrial } from 'core/user/mutations';

import { DotLoader } from 'views/elements/Loaders';
import { CenteredWrapper, FlexColWrapper } from 'views/elements/Wrappers';
import { TrialText } from 'views/elements/Texts';

const TrialActivation = () => (
  <CenteredWrapper>
    <Query query={GetCurrentUser}>
      {({ data, loading, error }) => {
        if (loading) return <DotLoader />;
        if (error)
          return (
            <TrialText>
              Não foi possível obter informação sobre o seu trial
            </TrialText>
          );

        const user = data.currentUser;
        return user != null && user.trial === 'unused' ? (
          <Mutation
            mutation={ActivateTrial}
            variables={{ data: {} }}
            onCompleted={() =>
              toast.success('O seu trial foi ativado com sucesso')
            }
            onError={() => toast.error('Não foi possível ativar o trial')}
          >
            {mutate => {
              const onClick = () => {
                if (
                  window.confirm('Tem a certeza que pretende ativar o trial?')
                ) {
                  mutate();
                } else {
                  toast.info('A ativação do trial foi cancelada.');
                }
              };

              return (
                <FlexColWrapper>
                  <TrialText>
                    Teste durante 10 dias as funcionalidades premium!
                  </TrialText>
                  <TrialText>
                    <button onClick={onClick}>Clique aqui</button> e ative
                    gratuitamente o seu trial!
                  </TrialText>
                </FlexColWrapper>
              );
            }}
          </Mutation>
        ) : user.trial !== 'expired' ? (
          <TrialText>
            Faltam <u>{user.trial} dias</u> para acabar o teu período
            experimental grátis.
          </TrialText>
        ) : (
          <TrialText>Já utilizou o tempo disponível do seu trial.</TrialText>
        );
      }}
    </Query>
  </CenteredWrapper>
);

export default TrialActivation;
