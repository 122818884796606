import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import SVG from 'react-inlinesvg';
import { withRouter } from 'react-router-dom';
import throttle from 'lodash/throttle';

import { GetCPCBook } from 'core/app/cpc/queries';

import text from 'views/assets/icons/text.svg';
import {
  RightSidebarWrapper,
  FlexWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { BookTitle, Info } from 'views/elements/Texts';
import { CircleLoader } from 'views/elements/Loaders';

import TitleCard from '../TitleCard';
import Title from '../Title';

export class Book extends React.Component {
  state = {
    titleRefs: {},
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);

    if (!this.isInViewport(this.props.titleNumber).visible) {
      setTimeout(() => {
        this.jumpToTitleOnMount();
      }, 500);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  setTitleRef = (number, ref) =>
    this.setState(prevState => ({
      titleRefs: { ...prevState.titleRefs, [number]: ref },
    }));

  handleScroll = throttle(() => {
    const {
      props: { version, partNumber, bookNumber, titleNumber, selectTitle },
      state: { titleRefs },
    } = this;

    const titles = Object.keys(titleRefs)
      .map(number => parseInt(number, 10))
      .sort((a, b) => b - a);

    for (const number of titles) {
      if (this.isInViewport(number).visible && number !== titleNumber) {
        const path = partNumber
          ? `/app/cpc/code/${version}/part/${partNumber}/book/${bookNumber}/title/${number}`
          : `/app/cpc/code/${version}/book/${bookNumber}/title/${number}`;
        this.props.history.replace(path);
        selectTitle(number);
        return;
      }
    }
  }, 500);

  sidebarSelectTitle = titleNumber => {
    this.props.selectTitle(titleNumber);
    this.scrollTo(titleNumber);
  };

  jumpToTitleOnMount = () => {
    const { visible, loaded } = this.isInViewport(this.props.titleNumber);

    if (!visible && loaded) {
      this.scrollTo(this.props.titleNumber);
    } else {
      setTimeout(() => {
        this.jumpToTitleOnMount();
      }, 500);
    }
  };

  scrollTo(number) {
    const {
      state: { titleRefs },
    } = this;

    if (titleRefs[number] != null && titleRefs[number].current != null) {
      titleRefs[number].current.scrollIntoView();
    }
  }

  isInViewport(titleNumber) {
    const {
      state: { titleRefs },
    } = this;

    if (!titleRefs[titleNumber] || titleRefs[titleNumber].current == null)
      return {
        visible: false,
        loaded: false,
      };

    const { top, height } = titleRefs[
      titleNumber
    ].current.getBoundingClientRect();

    return {
      visible:
        (top < 0 && height + top > 0) || (top > 0 && top < window.innerHeight),
      loaded: true,
    };
  }

  render() {
    const {
      props: {
        version,
        partNumber,
        partLevel,
        bookNumber,
        bookLevel,
        titleNumber,
        selectTitle,
        selectArticle,
      },
      state: { titleRefs },
      sidebarSelectTitle,
      setTitleRef,
    } = this;

    return (
      <Query
        query={GetCPCBook}
        variables={{
          version,
          partNumber,
          partLevel,
          bookNumber,
          bookLevel,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <CircleLoader slug="cpc" />
              </CenteredWrapper>
            );
          if (error)
            return (
              <CenteredWrapper>
                <Info>Livro não encontrado</Info>
              </CenteredWrapper>
            );

          const book = data.cpcBook;

          return book != null ? (
            <React.Fragment>
              <BookTitle>{book.name}</BookTitle>
              {book.sections.map(section => (
                <Title
                  key={section.id}
                  version={version}
                  partNumber={partNumber}
                  partLevel={partLevel}
                  bookNumber={bookNumber}
                  bookLevel={bookLevel}
                  titleNumber={section.number}
                  titleLevel={section.level}
                  selectArticle={selectArticle}
                  selectTitle={selectTitle}
                  setTitleRef={setTitleRef}
                  titleRefs={titleRefs}
                />
              ))}
              <RightSidebarWrapper>
                <FlexWrapper>
                  <SVG src={text} />
                  <p>Conteúdos</p>
                </FlexWrapper>
                {book.sections.map(section => (
                  <TitleCard
                    key={section.id}
                    data={section}
                    selectedCode={version}
                    selectedPart={partNumber}
                    selectedBook={bookNumber}
                    selectedTitle={titleNumber}
                    selectTitle={sidebarSelectTitle}
                  />
                ))}
              </RightSidebarWrapper>
            </React.Fragment>
          ) : (
            <CenteredWrapper>
              <Info>Livro não encontrado</Info>
            </CenteredWrapper>
          );
        }}
      </Query>
    );
  }
}

Book.propTypes = {
  version: PropTypes.string,
  partNumber: PropTypes.number,
  partLevel: PropTypes.number,
  bookNumber: PropTypes.number,
  bookLevel: PropTypes.number,
  titleNumber: PropTypes.number,
  selectTitle: PropTypes.func,
  selectArticle: PropTypes.func,
  history: PropTypes.shape({
    replace: PropTypes.func,
  }),
};

export default withRouter(Book);
