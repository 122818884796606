import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlexColWrapper as Wrapper } from 'views/elements/Wrappers';
import { Error } from 'views/elements/Texts';
import { Label, AuthInput as Input } from 'views/elements/Inputs';
import { AuthLine } from 'views/elements/Separators';

export class AuthInput extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape(),
      touched: PropTypes.shape(),
    }).isRequired,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    label: PropTypes.string,
  };

  static defaultProps = {
    type: 'text',
  };

  state = {
    active: false,
  };

  setActive = active => this.setState({ active });

  render() {
    const {
      props: {
        field: { name, value, onChange, onBlur },
        form: { errors, touched },
        placeholder,
        label,
        type,
      },
      state: { active },
      setActive,
    } = this;

    return (
      <Wrapper>
        <Label>{label}</Label>
        <Input
          type={type}
          placeholder={placeholder}
          name={name}
          value={value}
          active={active}
          onChange={onChange}
          onFocus={() => setActive(true)}
          onBlur={e => {
            onBlur(e);
            setActive(false);
          }}
        />
        <AuthLine error={touched[name] && errors[name]} />
        <Error>{touched[name] && errors[name]}</Error>
      </Wrapper>
    );
  }
}

export default AuthInput;
