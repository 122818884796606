import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Elements } from 'react-stripe-elements';

import { Modal } from 'views/components/Modal';
import { BuyButton } from 'views/elements/Buttons';
import { TextSeparator } from 'views/elements/Separators';

import StripeCheckout from './StripeCheckout';
import PayPalCheckout from './PayPalCheckout';

export class Checkout extends Component {
  static propTypes = {
    mutation: PropTypes.shape({}).isRequired,
    mutationData: PropTypes.shape({}).isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.shape({})),
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    active: PropTypes.bool.isRequired,
    slug: PropTypes.string,
  };

  static defaultProps = {
    slug: 'anotado',
  };

  state = {
    payment: false,
  };

  setPayment = payment => this.setState({ payment });

  render() {
    const {
      props: {
        mutation,
        mutationData,
        refetchQueries,
        price,
        description,
        slug,
        active,
      },
      state: { payment },
      setPayment,
    } = this;

    return (
      <React.Fragment>
        <Modal isOpen={payment} closeModal={() => setPayment(false)}>
          <PayPalCheckout
            mutation={mutation}
            mutationData={mutationData}
            refetchQueries={refetchQueries}
            price={price}
            description={description}
            close={() => setPayment(false)}
            slug={slug}
          />
          <TextSeparator>Ou</TextSeparator>
          <Elements>
            <StripeCheckout
              mutation={mutation}
              mutationData={mutationData}
              refetchQueries={refetchQueries}
              price={price}
              close={() => setPayment(false)}
              slug={slug}
            />
          </Elements>
        </Modal>
        {active ? (
          <BuyButton onClick={() => setPayment(true)}>
            Prosseguir com a compra
          </BuyButton>
        ) : (
          <BuyButton disabled slug={slug}>
            Já possui este Premium
          </BuyButton>
        )}
      </React.Fragment>
    );
  }
}
