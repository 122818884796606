import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { injectStripe, CardElement } from 'react-stripe-elements';
import { Mutation } from 'react-apollo';

import { toast } from 'core/utils/toast';
import { theme } from 'core/theme';

import { InputWrapper, TextInput } from 'views/elements/Inputs';
import { CheckoutButton } from 'views/elements/Buttons';
import { FlexColWrapper, CenteredWrapper } from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';

const cardStyle = fontSize => ({
  base: {
    iconColor: theme.colors.textPrimary,
    color: theme.colors.textPrimary,
    fontWeight: 300,
    fontFamily: 'Source Sans Pro, Open Sans, Segoe UI, sans-serif',
    fontSize,
    fontSmoothing: 'antialiased',
  },
  invalid: {
    iconColor: '#ff000',
    color: '#ff0000',
  },
});

class StripeCheckout extends Component {
  static propTypes = {
    mutation: PropTypes.shape({}).isRequired,
    mutationData: PropTypes.shape({}).isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    price: PropTypes.number.isRequired,
    close: PropTypes.func.isRequired,
    slug: PropTypes.string,
    stripe: PropTypes.shape({
      createToken: PropTypes.func,
    }),
  };

  state = {
    name: '',
  };

  getFontSize = () => {
    const fontClassName = document.documentElement.className;

    if (fontClassName === 'fontSize100') {
      return '20px';
    } else if (fontClassName === 'fontSize125') {
      return '24px';
    }
    return '28.8px';
  };

  changeName = name => this.setState({ name });

  handleSubmit = (ev, mutate) => {
    ev.preventDefault();

    this.props.stripe
      .createToken({
        type: 'card',
        name: this.state.name,
      })
      .then(({ token }) => {
        if (token != null) {
          mutate({
            variables: {
              data: {
                ...this.props.mutationData,
                provider: 'web-stripe',
                method: 'stripe',
                stripe_token: token.id,
              },
            },
          });
        }
      });
  };

  render() {
    const {
      state: { name },
      props: { close, slug, mutation, refetchQueries, price },
      changeName,
      handleSubmit,
      getFontSize,
    } = this;

    const fontSize = getFontSize();

    return (
      <Mutation
        mutation={mutation}
        refetchQueries={refetchQueries}
        onCompleted={() => {
          toast.success('Compra efetuada com sucesso');
          close();
        }}
        onError={() => toast.error('Não foi possível completar a compra')}
      >
        {(mutate, { loading }) =>
          loading ? (
            <CenteredWrapper>
              <CircleLoader slug={slug} />
            </CenteredWrapper>
          ) : (
            <form onSubmit={ev => handleSubmit(ev, mutate)}>
              <FlexColWrapper>
                <InputWrapper>
                  <TextInput
                    type="text"
                    value={name}
                    placeholder="Card holder"
                    onChange={ev => changeName(ev.target.value)}
                  />
                </InputWrapper>
                <InputWrapper>
                  <CardElement style={cardStyle(fontSize)} />
                </InputWrapper>
                <CheckoutButton slug={slug}>
                  Pagar R${price} com Cartão de Crédito
                </CheckoutButton>
              </FlexColWrapper>
            </form>
          )
        }
      </Mutation>
    );
  }
}

export default injectStripe(StripeCheckout);
