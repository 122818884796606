import gql from 'graphql-tag';

export const GetCurrentUser = gql`
  query getCurrentUser {
    currentUser {
      about
      email
      id
      job
      name
      picture
      trial
    }
  }
`;
