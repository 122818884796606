import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';

import { CreateFavourite, DeleteFavourite } from 'core/favourite/mutations';
import { theme } from 'core/theme';

import Icon from 'views/components/Icon';
import { IconButton } from 'views/elements/Buttons';

export const FavouriteButton = ({
  section: { id, favourited = false },
  slug = 'anotado',
  buttonWidth = '3.2rem',
  buttonHeight = '3.2rem',
  iconWidth = '17',
  iconHeight = '17',
}) => (
  <Mutation
    mutation={favourited ? DeleteFavourite : CreateFavourite}
    variables={{
      data: {
        section_id: id,
      },
    }}
  >
    {mutate => (
      <IconButton
        onClick={mutate}
        slug={slug}
        width={buttonWidth}
        height={buttonHeight}
      >
        <Icon
          name={favourited ? 'bookmark_fill' : 'bookmark'}
          width={iconWidth}
          height={iconHeight}
          color={theme.appColors[slug].primary}
        />
      </IconButton>
    )}
  </Mutation>
);

FavouriteButton.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    favourited: PropTypes.bool,
  }),
  slug: PropTypes.string,
  buttonWidth: PropTypes.string,
  buttonHeight: PropTypes.string,
  iconWidth: PropTypes.string,
  iconHeight: PropTypes.string,
};
