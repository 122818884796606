import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'views/components/Icon';
import { IconButton } from 'views/elements/Buttons';
import { RightAlignedWrapper } from 'views/elements/Wrappers';
import { AnnotationTitle, Text, Info } from 'views/elements/Texts';
import { HorizontalLine } from 'views/elements/Separators';

const CommentsBody = ({ comments, title, index, commentsCount, slug }) => (
  <React.Fragment>
    {comments.length > 0 && <AnnotationTitle>{title}</AnnotationTitle>}
    {comments.map((annotation, index) => (
      <React.Fragment key={annotation.id}>
        <Text>{annotation.text}</Text>
        {index < comments.length - 1 && <HorizontalLine slug={slug} />}
      </React.Fragment>
    ))}
    {comments.length > 0 &&
      commentsCount.slice(index + 1).reduce((a, b) => a + b, 0) > 0 && (
        <HorizontalLine slug={slug} />
      )}
  </React.Fragment>
);

CommentsBody.propTypes = {
  comments: PropTypes.arrayOf(
    PropTypes.shape({ text: PropTypes.string, id: PropTypes.string }),
  ),
  title: PropTypes.string,
  index: PropTypes.number,
  commentsCount: PropTypes.arrayOf(PropTypes.number),
  slug: PropTypes.string,
};

export const Comments = ({
  premiumAnnotations,
  collaborativeAnnotations,
  privateAnnotations,
  jurisprudences,
  questions,
  norms,
  toggleEditor,
  slug,
}) => {
  const commentsCount = [
    premiumAnnotations.length,
    collaborativeAnnotations.length,
    jurisprudences.length,
    questions.length,
    norms.length,
    privateAnnotations.length,
  ];

  const availableComments = commentsCount.reduce((a, b) => a + b, 0) > 0;

  return (
    <React.Fragment>
      {!availableComments && (
        <Info>Não foram encontradas anotações para este artigo</Info>
      )}
      <CommentsBody
        comments={premiumAnnotations}
        title="Anotações Premium"
        index={0}
        commentsCount={commentsCount}
        slug={slug}
      />
      <CommentsBody
        comments={collaborativeAnnotations}
        title="Anotações Colaborativas"
        index={1}
        commentsCount={commentsCount}
        slug={slug}
      />
      <CommentsBody
        comments={jurisprudences}
        title="Jurisprudências e Enunciados Relacionados"
        index={2}
        commentsCount={commentsCount}
        slug={slug}
      />
      <CommentsBody
        comments={questions}
        title="Questões de Concurso Relacionadas"
        index={3}
        commentsCount={commentsCount}
        slug={slug}
      />
      <CommentsBody
        comments={norms}
        title="Normas Relacionadas"
        index={4}
        commentsCount={commentsCount}
        slug={slug}
      />
      {privateAnnotations.length > 0 && (
        <AnnotationTitle>Anotações Privadas</AnnotationTitle>
      )}
      {privateAnnotations.map((annotation, index) => (
        <React.Fragment key={annotation.id}>
          <Text>{annotation.text}</Text>
          <RightAlignedWrapper>
            <IconButton
              onClick={() => toggleEditor(annotation.id, annotation.text)}
            >
              <Icon name="edit" color="black" />
            </IconButton>
          </RightAlignedWrapper>
          {index < privateAnnotations.length - 1 && (
            <HorizontalLine slug={slug} />
          )}
        </React.Fragment>
      ))}
    </React.Fragment>
  );
};

Comments.propTypes = {
  premiumAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  collaborativeAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  privateAnnotations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  jurisprudences: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  questions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  norms: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      text: PropTypes.text,
    }),
  ),
  toggleEditor: PropTypes.func,
  slug: PropTypes.string,
};
