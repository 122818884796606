import React from 'react';
import PropTypes from 'prop-types';

import { GetLMSArticle } from 'core/app/lms/queries';

import { SidesMarginWrapper } from 'views/elements/Wrappers';
import { Section } from 'views/components/Section';
import ParagraphCard from '../ParagraphCard';
import PointCard from '../PointCard';

export const Sections = (
  articleNumber,
  selectParagraph,
  selectPoint,
  articlePath,
) => {
  const PointsAndParagraphs = ({
    section: { paragraphs = [], points = [] },
  }) => (
    <SidesMarginWrapper level={1}>
      {points.length > 0 &&
        points.map(s => (
          <PointCard
            key={s.id}
            data={s}
            parentPath={articlePath}
            selectPoint={pointNumber =>
              selectPoint(pointNumber, articleNumber, null)
            }
          />
        ))}
      {paragraphs.length > 0 &&
        paragraphs.map(s => (
          <ParagraphCard
            key={s.id}
            data={s}
            articlePath={articlePath}
            selectParagraph={paragraphNumber =>
              selectParagraph(paragraphNumber, articleNumber)
            }
            selectPoint={(pointNumber, paragraphNumber) =>
              selectPoint(pointNumber, articleNumber, paragraphNumber)
            }
          />
        ))}
    </SidesMarginWrapper>
  );

  PointsAndParagraphs.propTypes = {
    section: PropTypes.shape({
      paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
      points: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  };

  return PointsAndParagraphs;
};

export const Article = props => (
  <Section
    {...props}
    slug="lms"
    query={GetLMSArticle}
    variables={{
      articleNumber: props.articleNumber,
    }}
    sectionKey="lmsArticle"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    }`}
    Sections={Sections(
      props.articleNumber,
      props.selectParagraph,
      props.selectPoint,
      props.match.url,
    )}
  />
);

Article.propTypes = {
  articleNumber: PropTypes.number,
  selectParagraph: PropTypes.func,
  selectPoint: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Article;
