import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';

import AuthInput from 'views/components/AuthInput';

import { PersonalAnnotations } from 'core/annotation/queries';
import { CreateAnnotationBundle } from 'core/bundle/mutations';
import { MyBundles } from 'core/bundle/queries';

import { CardTitle, Info } from 'views/elements/Texts';
import { AuthButton } from 'views/elements/Buttons';
import {
  SidesMarginWrapper,
  CenteredWrapper,
  FlexRowWrapWrapper,
} from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';

import { Annotation } from './Annotation';

const schema = yup.object().shape({
  title: yup.string().required('Título Inválido'),
  description: yup.string().required('Descrição Inválida'),
});

export class NewBundleScreen extends React.Component {
  handleInputChange = (ev, field, values, setFieldValue, counterValues) => {
    const { value, checked } = ev.target;

    if (checked) {
      values.push(value);
      const index = counterValues.indexOf(value);
      if (index !== -1) counterValues.splice(index, 1);
    } else {
      const index = values.indexOf(value);
      if (index !== -1) values.splice(index, 1);
    }
    setFieldValue(field, values);
  };

  render() {
    const {
      props: {
        history: { push },
      },
      handleInputChange,
    } = this;

    return (
      <React.Fragment>
        <Mutation
          mutation={CreateAnnotationBundle}
          refetchQueries={[
            {
              query: MyBundles,
            },
          ]}
          onCompleted={() => {
            toast.success('Bundle criado com successo');
            push('/my_bundles');
          }}
          onError={() => toast.error('Não foi possível criar o bundle')}
        >
          {mutate => (
            <Formik
              validationSchema={schema}
              initialValues={{
                title: '',
                description: '',
                copy_annotations: [],
                move_annotations: [],
              }}
              onSubmit={(values, actions) =>
                mutate({
                  variables: {
                    data: {
                      title: values.title,
                      description: values.description,
                      copy_annotations: values.copy_annotations,
                      move_annotations: values.move_annotations,
                    },
                  },
                })
              }
              render={({
                errors,
                touched,
                isSubmitting,
                setFieldValue,
                values,
              }) => (
                <Form>
                  <CardTitle>Informações</CardTitle>
                  <SidesMarginWrapper level={1}>
                    <Field
                      type="text"
                      name="title"
                      label="Título"
                      placeholder="Insira um título"
                      component={AuthInput}
                    />
                    <Field
                      type="text"
                      name="description"
                      label="Descrição"
                      placeholder="Insira uma descrição"
                      component={AuthInput}
                    />
                  </SidesMarginWrapper>
                  <CardTitle>Anotações</CardTitle>
                  <SidesMarginWrapper level={1}>
                    <Query query={PersonalAnnotations}>
                      {({ data, loading, error }) => {
                        if (loading)
                          return (
                            <CenteredWrapper>
                              <CircleLoader />
                            </CenteredWrapper>
                          );
                        if (
                          error ||
                          data.personalAnnotations == null ||
                          data.personalAnnotations.length === 0
                        )
                          return (
                            <CenteredWrapper>
                              <Info>
                                Não possui anotações que possa associar a este
                                bundle
                              </Info>
                            </CenteredWrapper>
                          );

                        const options = data.personalAnnotations.map(
                          annotation => ({
                            id: annotation.id,
                            text: annotation.text,
                            name: `annotation_${annotation.id}`,
                            contentName: annotation.section.content.name,
                            sectionName: annotation.section.full_name,
                          }),
                        );

                        return (
                          <FlexRowWrapWrapper>
                            {options.map(option => (
                              <Annotation
                                key={option.id}
                                option={option}
                                copyAnnotations={values.copy_annotations}
                                moveAnnotations={values.move_annotations}
                                handleInputChange={handleInputChange}
                                setFieldValue={setFieldValue}
                              />
                            ))}
                          </FlexRowWrapWrapper>
                        );
                      }}
                    </Query>
                  </SidesMarginWrapper>
                  <CenteredWrapper style={{ marginTop: '5rem' }}>
                    <AuthButton type="submit" disabled={isSubmitting}>
                      criar bundle
                    </AuthButton>
                  </CenteredWrapper>
                </Form>
              )}
            />
          )}
        </Mutation>
      </React.Fragment>
    );
  }
}

NewBundleScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

export default NewBundleScreen;
