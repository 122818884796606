export const theme = {
  appColors: {
    anotado: {
      primary: '#FFB711',
      secondary: '#FEF8EC',
    },
    cpc: {
      primary: '#FF8585',
      secondary: '#FCEDED',
    },
    fam: {
      primary: '#FD9A6A',
      secondary: '#FBE9DF',
    },
    lms: {
      primary: '#0C7674',
      secondary: '#D3EDEB',
    },
  },
  colors: {
    textPrimary: '#242A31',
    textSecondary: '#585D61',
    textGreyPrimary: '#74818D',
    textGreySecondary: '#9DAAB6',
    textError: 'red',
    backgroundPrimary: '#F5F7F9',
    backgroundSecondary: '#FFFFFF',
    backgroundDarkPrimary: '#272D34',

    title: '#1E344E',
    beige: '#F6F4F2',
    lightGrey2: '#9DAAB6',
    lightGrey3: '#D8D8D8',
  },
};
