import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';

const yupToFormErrors = yupError => {
  const errors = {};
  for (const err of yupError.inner) {
    if (!errors[err.path]) {
      errors[err.path] = err.message;
    }
  }
  return errors;
};

export default class FormWizard extends React.PureComponent {
  static Page = ({ children }) => <div>{children}</div>;

  static propTypes = {
    children: PropTypes.arrayOf(PropTypes.element),
    initialValues: PropTypes.shape({}),
    onSubmit: PropTypes.func,
    submitLabel: PropTypes.string,
  };

  static defaultProps = {
    submitLabel: 'Submit',
  };

  state = {
    page: 0,
  };

  next = validate => {
    this.setState(
      state => ({
        page: Math.min(state.page + 1, this.props.children.length - 1),
      }),
      () => validate(),
    );
  };

  previous = validate =>
    this.setState(
      state => ({
        page: Math.max(state.page - 1, 0),
      }),
      () => validate(),
    );

  pageCount = () => React.Children.count(this.props.children);

  validate = values => {
    const activePage = React.Children.toArray(this.props.children)[
      this.state.page
    ];

    const { schema, validate } = activePage.props;
    if (!schema && !validate) return {};
    if (validate) return validate(values);
    return new Promise((resolve, reject) => {
      schema
        .validate(values, { abortEarly: false })
        .then(() => resolve({}))
        .catch(err => reject(yupToFormErrors(err)));
    });
  };

  render() {
    const { children, onSubmit, initialValues } = this.props;
    const { page } = this.state;
    const activePage = React.Children.toArray(children)[page];
    const isLastPage = page === React.Children.count(children) - 1;
    return (
      <Formik
        initialValues={initialValues}
        validate={this.validate}
        onSubmit={(values, actions) => onSubmit(values)}
        render={({
          handleSubmit,
          values,
          setFieldValue,
          isValid,
          validateForm,
          dirty,
        }) => (
          <Form onSubmit={handleSubmit}>
            {activePage &&
              React.cloneElement(activePage, { values, setFieldValue })}
            <div>
              {page > 0 && (
                <button onClick={() => this.previous(validateForm)}>
                  Previous
                </button>
              )}
              <div style={{ float: 'right' }}>
                {!isLastPage && (
                  <button
                    onClick={() => this.next(validateForm)}
                    disabled={!isValid && dirty}
                  >
                    Next
                  </button>
                )}
                {isLastPage && (
                  <button type="submit">{this.props.submitLabel}</button>
                )}
              </div>
            </div>
          </Form>
        )}
      />
    );
  }
}
