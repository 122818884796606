import React from 'react';
import { Query } from 'react-apollo';

import { MyBundles } from 'core/bundle/queries';

import { FlexRowWrapper, CenteredWrapper } from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { BundleCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

export const MyBundlesScreen = () => (
  <Query query={MyBundles}>
    {({ data, loading, error }) => {
      if (loading)
        return (
          <CenteredWrapper>
            <CircleLoader />
          </CenteredWrapper>
        );
      if (error || data.myBundles == null || data.myBundles.length === 0)
        return (
          <CenteredWrapper>
            <Info>Não existem bundles disponíveis</Info>
          </CenteredWrapper>
        );

      const { myBundles } = data;

      return (
        <FlexRowWrapper>
          {myBundles.map(bundle => (
            <BundleCard to={`/my_bundles/${bundle.id}`} key={bundle.id}>
              <h1>{bundle.title}</h1>
              <h2>{bundle.description}</h2>
              <h3>{bundle.annotations_count} anotações</h3>
            </BundleCard>
          ))}
        </FlexRowWrapper>
      );
    }}
  </Query>
);

export default MyBundlesScreen;
