import gql from 'graphql-tag';

export const GetBundles = gql`
  query bundles {
    bundles {
      id
      price
      category
      title
      slug
      annotations_count
      purchased
    }
  }
`;

export const MyBundles = gql`
  query myBundles {
    myBundles {
      id
      title
      description
      annotations_count
    }
  }
`;

export const MyBundle = gql`
  query myBundle($bundle_id: ID!) {
    myBundle(bundle_id: $bundle_id) {
      id
      title
      description
      annotations_count
      owner
      price
      buyable
      annotations {
        id
        text
        section {
          id
          full_name
          content {
            id
            name
          }
        }
      }
      authors {
        id
        status
        invited
        author {
          id
          name
        }
      }
      invites {
        id
        email
      }
      author {
        id
      }
    }
  }
`;

export const GetBuyableBundles = gql`
  query buyableBundles {
    buyableBundles {
      id
      price
      title
      description
      annotations_count
      annotations_authors {
        id
        name
      }
      purchased
    }
  }
`;

export const GetBuyableBundle = gql`
  query buyableBundle($id: ID!) {
    buyableBundle(id: $id) {
      id
      price
      title
      description
      annotations_count
      annotations_authors {
        id
        name
      }
      free_annotation {
        id
        text
        section {
          full_name
          content {
            id
            name
          }
        }
      }
      contents {
        id
        name
      }
      purchased
    }
  }
`;
