import React from 'react';
import { Text, NotFoundTitle } from 'views/elements/Texts';
import {
  CenteredWrapper,
  FlexColWrapper,
  NotFoundWrapper,
} from 'views/elements/Wrappers';
import { NotFoundLink } from 'views/elements/Buttons';
import { NotFoundImage } from 'views/elements/Images';

import underConstruction from 'views/assets/images/under_construction.png';

const NotFoundScreen = () => (
  <NotFoundWrapper>
    <CenteredWrapper>
      <FlexColWrapper>
        <NotFoundTitle>
          Erro 404,
          <br />
          Página não encontrada.
        </NotFoundTitle>
        <Text>Não foi possível encontrar a página que procura.</Text>
        <NotFoundLink to="/">Voltar à página inicial.</NotFoundLink>
        <NotFoundImage
          src={underConstruction}
          alt="Website under construction."
        />
      </FlexColWrapper>
    </CenteredWrapper>
  </NotFoundWrapper>
);

export default NotFoundScreen;
