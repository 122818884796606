import gql from 'graphql-tag';

export const GetFamLaw = gql`
  query getFamLaw($slug: String!) {
    famLaw(slug: $slug) {
      id
      name
      main_sections {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
        paragraphs {
          category
          id
          info
          level
          name
          number
          position
          text
          favourited
        }
      }
    }
  }
`;

export const GetFamArticle = gql`
  query getFamArticle(
    $slug: String!
    $articleNumber: Int!
    $articleItem: String
  ) {
    famArticle(
      slug: $slug
      articleNumber: $articleNumber
      articleItem: $articleItem
    ) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
      jurisprudences {
        id
        text
      }
      norms {
        id
        text
      }
      questions {
        id
        text
      }
      paragraphs {
        id
        number
        text
        favourited
      }
    }
  }
`;

export const GetFamParagraph = gql`
  query getFamParagraph(
    $slug: String!
    $articleNumber: Int!
    $paragraphNumber: Int!
  ) {
    famParagraph(
      slug: $slug
      articleNumber: $articleNumber
      paragraphNumber: $paragraphNumber
    ) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
      jurisprudences {
        id
        text
      }
      norms {
        id
        text
      }
      questions {
        id
        text
      }
    }
  }
`;
