import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { GetSections } from 'core/section/queries';

import { EmptyBookCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

import BookCard from '../BookCard';

const Error = () => (
  <EmptyBookCard>
    <h1>Não existem livros disponíveis</h1>
  </EmptyBookCard>
);

const Loader = () => (
  <React.Fragment>
    <EmptyBookCard>
      <CircleLoader slug="cpc" />
    </EmptyBookCard>
  </React.Fragment>
);

export const Part = ({ part, selectedCode, selectedPart }) => (
  <Query
    key={part.id}
    query={GetSections}
    variables={{
      section_id: part.id,
    }}
  >
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error) return <Error />;

      const { sections } = data;

      return sections.map(section => (
        <BookCard
          key={section.id}
          data={section}
          selectedCode={selectedCode}
          selectedPart={selectedPart}
        />
      ));
    }}
  </Query>
);

Part.propTypes = {
  part: PropTypes.shape({}),
  selectedCode: PropTypes.string,
  selectedPart: PropTypes.number,
};

export default Part;
