import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { GetUserFavourites } from 'core/favourite/queries';

import { BookmarkCard } from 'views/elements/Cards';
import {
  FlexRowWrapWrapper,
  CenteredWrapper,
  FlexColWrapper,
} from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';
import { Info, NotFoundTitle } from 'views/elements/Texts';
import { NotFoundLink } from 'views/elements/Buttons';
import { NotFoundImage } from 'views/elements/Images';

import noBookmarks from 'views/assets/images/no_bookmarks.png';

export const BookmarksScreen = ({ slug }) => (
  <Query
    query={GetUserFavourites}
    variables={{
      slug,
    }}
    fetchPolicy="cache-and-network"
  >
    {({ loading, error, data }) => {
      if (loading) {
        return (
          <CenteredWrapper>
            <CircleLoader slug={slug} />;
          </CenteredWrapper>
        );
      }
      if (error)
        return (
          <CenteredWrapper>
            <Info>Não foram encontrados favoritos</Info>
          </CenteredWrapper>
        );

      const { favourites } = data;
      return (
        <FlexRowWrapWrapper>
          {favourites.length > 0 ? (
            favourites.map(fav => {
              const { section } = fav;
              return (
                <BookmarkCard key={fav.id} to={section.path} slug={slug}>
                  <h1>{section.full_name}</h1>
                  <h2>{section.content.name}</h2>
                  <p>{section.text}</p>
                </BookmarkCard>
              );
            })
          ) : (
            <CenteredWrapper>
              <FlexColWrapper>
                <NotFoundTitle>Não foram encontrados favoritos.</NotFoundTitle>
                <NotFoundLink to="/">
                  Adicione um favorito a partir da sua biblioteca.
                </NotFoundLink>
                <NotFoundImage
                  src={noBookmarks}
                  alt="Homem não sabe onde está o marcador."
                />
              </FlexColWrapper>
            </CenteredWrapper>
          )}
        </FlexRowWrapWrapper>
      );
    }}
  </Query>
);

BookmarksScreen.propTypes = {
  slug: PropTypes.string,
};

export default BookmarksScreen;
