import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Switch, Route } from 'react-router-dom';

import { GetBuyableBundles } from 'core/bundle/queries';
import { listBundleAuthors } from 'core/utils/helpers';

import {
  FlexRowWrapWrapper,
  FlexColWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { CollaborativeBundleCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

import Bundle from './Bundle';

class CollaborativeMarketScreen extends Component {
  state = {
    selectedBundleId: null,
  };

  selectBundle = id => this.setState({ selectedBundleId: id });

  render() {
    const {
      state: { selectedBundleId },
      selectBundle,
    } = this;

    return (
      <Query query={GetBuyableBundles}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <CircleLoader />
              </CenteredWrapper>
            );
          if (
            error ||
            data.buyableBundles == null ||
            data.buyableBundles.length === 0
          )
            return (
              <CenteredWrapper>
                <Info>Não existem bundles disponíveis</Info>
              </CenteredWrapper>
            );

          const bundles = [...data.buyableBundles];
          return (
            <Switch>
              <Route
                exact
                path="/collaborative"
                render={() => (
                  <FlexColWrapper>
                    <FlexRowWrapWrapper>
                      {bundles.map(bundle => (
                        <CollaborativeBundleCard
                          key={bundle.id}
                          onClick={() => selectBundle(bundle.id)}
                          disabled={bundle.purchased}
                          to={`/collaborative/${bundle.id}`}
                        >
                          <h1>{bundle.title}</h1>
                          <h2>{bundle.description}</h2>
                          <h3>{bundle.annotations_count} Anotações</h3>
                          <h4>
                            Anotações por{' '}
                            {listBundleAuthors(bundle.annotations_authors)}
                          </h4>
                          {bundle.purchased ? (
                            <h5>Comprado</h5>
                          ) : (
                            <h5>Comprar por R${bundle.price}</h5>
                          )}
                        </CollaborativeBundleCard>
                      ))}
                    </FlexRowWrapWrapper>
                  </FlexColWrapper>
                )}
              />
              <Route
                exact
                path="/collaborative/:id"
                render={props => {
                  const selectedBundle = bundles.find(
                    bundle =>
                      bundle.id === (selectedBundleId || props.match.params.id),
                  );

                  return selectedBundle != null ? (
                    <CenteredWrapper>
                      <Bundle id={selectedBundle.id} />
                    </CenteredWrapper>
                  ) : (
                    <CenteredWrapper>
                      <Info>Bundle não encontrado</Info>
                    </CenteredWrapper>
                  );
                }}
              />
            </Switch>
          );
        }}
      </Query>
    );
  }
}

export default CollaborativeMarketScreen;
