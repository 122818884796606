import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import back from 'views/assets/icons/back.svg';
import { ContentWrapper, CenteredWrapper } from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { BackButton } from 'views/elements/Buttons';
import { Back } from 'views/components/Back';

import { BookmarksScreen } from 'views/pages/Bookmarks';
import Law from './Law';
import Article from './Article';
import Paragraph from './Paragraph';
import Point from './Point';

class LMS extends Component {
  static propTypes = {
    app: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        articleNumber: PropTypes.string,
        paragraphNumber: PropTypes.string,
        pointNumber: PropTypes.string,
      }),
    }),
  };

  state = {
    selectedArticle:
      parseInt(this.props.match.params.articleNumber, 10) || null,
    selectedParagraph:
      parseInt(this.props.match.params.paragraphNumber, 10) || null,
    selectedPoint: parseInt(this.props.match.params.pointNumber, 10) || null,
  };

  componentDidUpdate() {
    if (
      (parseInt(this.props.match.params.articleNumber, 10) || null) !==
      this.state.selectedArticle
    ) {
      this.selectPoint(
        parseInt(this.props.match.params.pointNumber, 10) || null,
        parseInt(this.props.match.params.articleNumber, 10) || null,
        parseInt(this.props.match.params.paragraphNumber, 10) || null,
      );
    }
    if (
      (parseInt(this.props.match.params.paragraphNumber, 10) || null) !==
      this.state.selectedParagraph
    ) {
      this.selectPoint(
        parseInt(this.props.match.params.pointNumber, 10) || null,
        parseInt(this.props.match.params.articleNumber, 10) || null,
        parseInt(this.props.match.params.paragraphNumber, 10) || null,
      );
    }
    if (
      (parseInt(this.props.match.params.pointNumber, 10) || null) !==
      this.state.selectedPoint
    ) {
      this.selectPoint(
        parseInt(this.props.match.params.pointNumber, 10) || null,
        parseInt(this.props.match.params.articleNumber, 10) || null,
        parseInt(this.props.match.params.paragraphNumber, 10) || null,
      );
    }
  }

  selectArticle = articleNumber =>
    this.setState({
      selectedArticle: articleNumber,
      selectedParagraph: null,
      selectedPoint: null,
    });

  selectParagraph = (paragraphNumber, articleNumber) =>
    this.setState({
      selectedArticle: articleNumber,
      selectedParagraph: paragraphNumber,
    });

  selectPoint = (pointNumber, articleNumber, paragraphNumber) =>
    this.setState({
      selectedArticle: articleNumber,
      selectedParagraph: paragraphNumber,
      selectedPoint: pointNumber,
    });

  render() {
    const {
      props: {
        app,
        match: { path, url },
      },
      state: { selectedArticle, selectedParagraph, selectedPoint },
      selectArticle,
      selectParagraph,
      selectPoint,
    } = this;

    return (
      <Switch>
        <Route
          exact
          path={path}
          render={props => (
            <ContentWrapper>
              <Law
                selectArticle={selectArticle}
                selectParagraph={selectParagraph}
                selectPoint={selectPoint}
              />
            </ContentWrapper>
          )}
        />
        <Route
          path={[
            `${path}/article/:articleNumber/point/:pointNumber`,
            `${path}/article/:articleNumber/paragraph/:paragraphNumber/point/:pointNumber`,
          ]}
          render={props => {
            const law =
              app.contents != null && app.contents.length > 0
                ? app.contents[0]
                : null;
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);
            const paragraphNumber =
              selectedParagraph ||
              parseInt(props.match.params.paragraphNumber, 10);
            const pointNumber =
              selectedPoint || parseInt(props.match.params.pointNumber, 10);

            return law != null ? (
              <ContentWrapper>
                <BackButton
                  slug="lms"
                  to={`${url}/article/${articleNumber}${
                    paragraphNumber > 0 ? `/paragraph/${paragraphNumber}` : ''
                  }`}
                >
                  <SVG src={back} />
                </BackButton>
                <Point
                  {...props}
                  articleNumber={articleNumber}
                  paragraphNumber={paragraphNumber}
                  pointNumber={pointNumber}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          path={`${path}/article/:articleNumber/paragraph/:paragraphNumber`}
          render={props => {
            const law =
              app.contents != null && app.contents.length > 0
                ? app.contents[0]
                : null;
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);
            const paragraphNumber =
              selectedParagraph ||
              parseInt(props.match.params.paragraphNumber, 10);

            return law != null ? (
              <ContentWrapper>
                <BackButton slug="lms" to={`${url}/article/${articleNumber}`}>
                  <SVG src={back} />
                </BackButton>
                <Paragraph
                  {...props}
                  articleNumber={articleNumber}
                  paragraphNumber={paragraphNumber}
                  selectPoint={selectPoint}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          path={`${path}/article/:articleNumber`}
          render={props => {
            const law =
              app.contents != null && app.contents.length > 0
                ? app.contents[0]
                : null;
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);

            return law != null ? (
              <ContentWrapper>
                <BackButton slug="lms" to={url}>
                  <SVG src={back} />
                </BackButton>
                <Article
                  {...props}
                  articleNumber={articleNumber}
                  selectParagraph={selectParagraph}
                  selectPoint={selectPoint}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact
          path={`${path}/bookmarks`}
          render={() => (
            <ContentWrapper>
              <Back
                slug="lms"
                url={url}
                title="Favoritos em Lei do Mandado de Segurança"
              />
              <BookmarksScreen slug="lms" />
            </ContentWrapper>
          )}
        />
      </Switch>
    );
  }
}

export default LMS;
