import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const TableStyle = styled.table`
  width: 100%;
  padding: 0;
  border-collapse: separate;
`;

/* eslint-disable */
export const Table = ({ children, colsWidth = []  }) => (
  <TableStyle>
    {colsWidth && (
      <colgroup>
        {colsWidth.map((e, i) => (
          <col key={i} width={e} />
        ))}
      </colgroup>
    )}
    <tbody>{children}</tbody>
  </TableStyle>
);
/* eslint-enable */

Table.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  colsWidth: PropTypes.arrayOf(PropTypes.string),
};

export const Row = styled.tr`
  height: 3rem;
`;

export const Cell = styled.td`
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 1rem;
  vertical-align: middle;
`;

export const CellTitle = styled.th`
  color: ${({ theme }) => theme.appColors.anotado.primary};
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 1.3rem;
  text-transform: uppercase;
  text-align: left;
`;
