import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const NavButton = styled(Link)`
  font-size: 1.2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${({ theme, selected, slug }) =>
    selected
      ? theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary
      : theme.colors.textPrimary};

  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${({ level }) => (level ? `margin-left: ${level * 1.5}rem` : '')};
`;

export const SidebarButton = styled(Link)`
  font-size: 2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: 500;
  color: ${({ theme, selected }) =>
    selected ? theme.appColors.anotado.primary : theme.colors.textPrimary};

  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 0 1rem;
`;

export const LinkButton = styled(Link)`
  font-size: 1.2rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: bold;
  color: ${props => props.theme.colors.textPrimary};

  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  border-radius: 6px;

  text-decoration: ${({ selected }) => (selected ? 'underline' : 'none')};
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
`;

export const IconButton = styled.button`
  width: ${({ width }) => width || '3.2rem'};
  height: ${({ height }) => height || '3.2rem'};
  background-color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].secondary
      : theme.appColors.anotado.secondary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin: 1.5rem;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const IconLink = styled(Link)`
  width: ${({ width }) => width || '3.2rem'};
  height: ${({ height }) => height || '3.2rem'};
  background-color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].secondary
      : theme.appColors.anotado.secondary};
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  margin: 1.5rem;

  &[disabled] {
    cursor: default;
    opacity: 0.7;
    pointer-events: none;
  }
`;

export const BackButton = styled(Link)`
  cursor: pointer;
  margin-left: 5rem;
  margin-right: 1rem;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  svg {
    width: ${({ width }) => width || '2.2rem'};
    height: ${({ height }) => height || '2.2rem'};
    g,
    path,
    rect {
      fill: ${({ theme, slug }) =>
        theme.appColors[slug]
          ? theme.appColors[slug].primary
          : theme.appColors.anotado.primary};
    }
  }
`;

export const SaveButton = styled.button`
  width: ${({ width }) => width || '11.8rem'};
  height: ${({ height }) => height || '3.4rem'};
  background-color: ${({ theme }) => theme.colors.beige};
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;

  margin: 1rem;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }
`;

export const DeleteButton = styled(SaveButton)`
  color: ${({ theme }) => theme.colors.textPrimary};
`;

export const MoreButton = styled.button`
  width: 30rem;
  height: 8rem;
  background-color: white;
  color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 4px;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  font-weight: 700;

  margin-top: 5rem;

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &:hover {
    box-shadow: 0 0 34px #e4e4e4;
  }
`;

export const BuyButton = styled.button`
  width: 20rem;
  height: 5rem;
  background-color: ${({ theme }) => theme.colors.textPrimary};
  color: white;

  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 6px;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: 700;
  font-size: 1.4rem;
  text-align: center;
  padding: 0 1rem;
  margin: 1rem;

  &:disabled {
    cursor: default;
    background-color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
  }
`;

export const CheckoutButton = styled.button`
  background-color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  border-radius: 6px;
  width: 50rem;
  height: 4.5rem;
  margin: 1rem;
  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: white;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 2rem;
  font-weight: 100;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

export const AuthButton = styled.button`
  background-color: white;
  border-radius: 6px;
  width: 30rem;
  height: 5rem;
  margin: 1rem;
  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 2rem;
  font-weight: 100;

  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
  }
`;

export const AuthSubmitButton = styled.button`
  background-color: white;
  border-radius: 2.5rem;
  width: 20rem;
  height: 5rem;
  margin: 1rem;
  padding: 1rem;

  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  align-self: center;
  cursor: pointer;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
  }

  span, svg {
    height: 3rem;
    width: 3rem;
  }

  p {
    width:100%
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2rem;
    text-align: center;
    padding-left: 2rem;
    padding-right: 5rem;
  }
`;
export const SocialButton = styled.div`
  background-color: white;
  border-radius: 2.5rem;
  width: 35rem;
  height: 5rem;
  margin: 1rem;
  padding: 1rem;

  display: flex;
  justify-content: flex-start;
  align-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 2px 14px rgba(0, 0, 0, 0.4);
  }

  span,
  svg {
    height: 3rem;
    width: 3rem;
  }

  p {
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Source Sans Pro', sans-serif;
    font-size: 2rem;
    padding-left: 2rem;
  }
`;

export const AuthLink = styled(Link)`
  font-size: 1.4rem;
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;
  text-align: center;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 1rem 0;

  &:hover {
    color: ${({ theme }) => theme.appColors.anotado.primary};
  }
`;

export const NotFoundLink = styled(Link)`
  font-size: 1.4rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: 500;
  color: ${({ theme }) => theme.colors.textPrimary};
  text-decoration: underline;

  cursor: pointer;
  margin: 1rem 0;

  &:hover {
    color: ${({ theme }) => theme.appColors.anotado.primary};
  }
`;
