import React from 'react';
import { Redirect } from 'react-router';
import { UserConsumer } from 'views/providers/user';

export const Logout = () => (
  <UserConsumer>
    {({ actions: { logoutUser } }) => {
      logoutUser();
      return <Redirect to="/" />;
    }}
  </UserConsumer>
);
