import React from 'react';
import PropTypes from 'prop-types';

import { LawCard as Card } from 'views/elements/Cards';

export const LawCard = ({ data, selectLaw }) => {
  const path = `/app/fam/law/${data.slug}`;

  return (
    <Card slug="fam" to={path} onClick={() => selectLaw(data.slug)}>
      <h1>{data.name}</h1>
      <h4>{data.description}</h4>
    </Card>
  );
};

LawCard.propTypes = {
  data: PropTypes.shape({}),
  selectLaw: PropTypes.func,
};

export default LawCard;
