import gql from 'graphql-tag';

export const SearchAnnotation = gql`
  query annotationSearch(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $text: String!
    $appSlug: String!
  ) {
    annotationSearch(
      first: $first
      after: $after
      last: $last
      before: $before
      text: $text
      appSlug: $appSlug
    ) {
      edges {
        cursor
        node {
          id
          text
          section {
            id
            path
            full_name
            content {
              id
              name
            }
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total_count
    }
  }
`;

export const PersonalAnnotations = gql`
  query personalAnnotations {
    personalAnnotations {
      id
      text
      section {
        id
        path
        full_name
        content {
          id
          name
        }
      }
    }
  }
`;

export const GetBundleAnnotation = gql`
  query bundleAnnotation($bundle_id: ID!, $annotation_id: ID!) {
    bundleAnnotation(bundle_id: $bundle_id, annotation_id: $annotation_id) {
      id
      text
      history
      section {
        id
        full_name
        content {
          id
          name
        }
      }
    }
  }
`;
