import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import AuthInput from 'views/components/AuthInput';

import emailIcon from 'views/assets/icons/mail.svg';
import { AuthSubmitButton, AuthLink } from 'views/elements/Buttons';
import { AuthForm } from 'views/elements/Inputs';

import {
  MIN_PASSWORD_LENGTH,
  SHORT_PASSWORD_ERROR,
  EMPTY_PASSWORD_ERROR,
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERROR,
} from 'core/constants';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL_ERROR)
    .required(EMPTY_EMAIL_ERROR),
  pass: yup
    .string()
    .required(EMPTY_PASSWORD_ERROR)
    .min(MIN_PASSWORD_LENGTH, SHORT_PASSWORD_ERROR),
});

export const LoginForm = ({ handleSubmit, loginUser }) => (
  <Formik
    validationSchema={schema}
    initialValues={{
      email: '',
      pass: '',
    }}
    onSubmit={(values, actions) => handleSubmit(values, actions, loginUser)}
    render={({ errors, touched, isSubmitting }) => (
      <React.Fragment>
        <AuthForm>
          <Field
            type="text"
            name="email"
            placeholder="Escreva o seu email"
            label="Email"
            component={AuthInput}
          />
          <Field
            type="password"
            name="pass"
            placeholder="Escreva a sua senha"
            label="Senha"
            component={AuthInput}
          />
          <AuthSubmitButton type="submit" disabled={isSubmitting}>
            <SVG src={emailIcon} />
            <p>entrar</p>
          </AuthSubmitButton>
        </AuthForm>
        <AuthLink to="/recover">Esqueceu sua senha?</AuthLink>
        <AuthLink to="/resend">Não recebeu o email de confirmação?</AuthLink>
        <AuthLink to="/signup">
          Ainda não tem conta? Cadastre-se usando o seu email.
        </AuthLink>
      </React.Fragment>
    )}
  />
);

LoginForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  loginUser: PropTypes.func.isRequired,
};
