import React, { Component } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';

import { toast } from 'core/utils/toast';

import { CenteredWrapper } from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';

const env = process.env.REACT_APP_PAYPAL_ENV;

const client = {
  sandbox: process.env.REACT_APP_PAYPAL_SANDBOX,
  production: process.env.REACT_APP_PAYPAL_PRODUCTION,
};

const style = {
  shape: 'rect',
  size: 'responsive',
  label: 'pay',
  tagline: 'false',
  height: 45,
};

const PayPalWrapper = styled.div`
  width: 50rem;
  height: 5rem;
  margin: 1rem;
`;

class PayPalCheckout extends Component {
  static propTypes = {
    mutation: PropTypes.shape({}).isRequired,
    mutationData: PropTypes.shape({}).isRequired,
    refetchQueries: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    price: PropTypes.number.isRequired,
    description: PropTypes.string.isRequired,
    close: PropTypes.func.isRequired,
    slug: PropTypes.string,
  };

  state = {
    paypal: null,
    showButton: false,
  };

  componentDidMount() {
    if (window.paypal) {
      // eslint-disable-next-line
      this.setState({
        paypal: window.paypal,
        showButton: true,
      });
    } else {
      const scriptTag = document.querySelector('#paypal-js');
      if (scriptTag) {
        scriptTag.addEventListener('load', this.loadPayPal);
      }
    }
  }

  onAuthorize = (data, mutate, mutationData) => {
    const { paymentID, payerID } = data;

    if (payerID != null && paymentID != null) {
      mutate({
        variables: {
          data: {
            ...mutationData,
            provider: 'web-paypal',
            method: 'paypal',
            payment_id: paymentID,
            payer_id: payerID,
          },
        },
      });
    }
  };

  payment = (data, actions, price, description) =>
    actions.payment.create({
      transactions: [
        {
          amount: {
            total: price,
            currency: 'BRL',
          },
          description,
        },
      ],
    });

  loadPayPal = () => {
    this.setState({
      paypal: window.paypal,
      showButton: true,
    });
  };

  render() {
    const {
      state: { paypal, showButton },
      props: {
        close,
        slug,
        description,
        price,
        mutation,
        mutationData,
        refetchQueries,
      },
      payment,
      onAuthorize,
    } = this;

    let PayPalButton = null;
    if (showButton) {
      PayPalButton = paypal.Button.driver('react', { React, ReactDOM });
    }

    return (
      showButton && (
        <Mutation
          mutation={mutation}
          refetchQueries={refetchQueries}
          onCompleted={() => {
            toast.success('Compra efetuada com sucesso');
            close();
          }}
          onError={() => toast.error('Não foi possível completar a compra')}
        >
          {(mutate, { loading }) =>
            loading ? (
              <CenteredWrapper>
                <CircleLoader slug={slug} />
              </CenteredWrapper>
            ) : (
              <PayPalWrapper>
                <PayPalButton
                  style={style}
                  env={env}
                  client={client}
                  payment={(data, actions) =>
                    payment(data, actions, price, description)
                  }
                  commit
                  onAuthorize={data => onAuthorize(data, mutate, mutationData)}
                  onCancel={() => toast.error('Pagamento cancelado!')}
                  onError={() =>
                    toast.error('Ocorreu um erro com o pagamento!')
                  }
                />
              </PayPalWrapper>
            )
          }
        </Mutation>
      )
    );
  }
}

export default PayPalCheckout;
