import React, { Component } from 'react';
import PropTypes from 'prop-types';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import { FACEBOOK_PERMISSIONS_ERROR } from 'core/constants';

import logo from 'views/assets/icons/facebook.svg';
import { SocialButton } from 'views/elements/Buttons';

const APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID;

const schema = yup.object().shape({
  email: yup.string().email(),
  id: yup.string().required(),
  accessToken: yup.string().required(),
  name: yup.string().required(),
  picture: yup.object().shape({
    data: yup.object().shape({
      url: yup.string().required(),
    }),
  }),
  age_range: yup.object().shape({
    min: yup.number(),
  }),
  link: yup.string(),
});

class Facebook extends Component {
  static propTypes = {
    loginFacebook: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
  };

  handleFacebookResponse = (response, loginFacebook, setError, loginUser) => {
    schema.isValid(response).then(valid => {
      if (valid) {
        const user = {
          email: response.email,
          uid: response.id,
          access_token: response.accessToken,
          provider: 'Facebook',
          name: response.name,
          picture: response.picture.data.url,
          age_range: response.age_range && response.age_range.min,
          link: response.link,
        };
        loginFacebook(user, loginUser);
      } else {
        setError(FACEBOOK_PERMISSIONS_ERROR);
      }
    });
  };

  render() {
    const {
      props: { loginFacebook, setError, loginUser },
      handleFacebookResponse,
    } = this;

    const handleCallback = response => {
      handleFacebookResponse(response, loginFacebook, setError, loginUser);
    };

    return (
      <FacebookLogin
        appId={APP_ID}
        autoLoad={false}
        authType="rerequest"
        fields="email,name,picture,age_range,link"
        scope="public_profile,email,user_age_range,user_link"
        callback={handleCallback}
        render={props => (
          <button onClick={props.onClick}>
            <SocialButton>
              <SVG src={logo} />
              <p>Continuar com o Facebook</p>
            </SocialButton>
          </button>
        )}
      />
    );
  }
}

export default Facebook;
