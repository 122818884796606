import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, Link } from 'react-router-dom';
import { Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';

import { MyBundle } from 'core/bundle/queries';
import { UpdateAnnotationBundle } from 'core/bundle/mutations';

import { CardTitle, CardSubTitle, CardText } from 'views/elements/Texts';
import { AnnotationCard } from 'views/elements/Cards';
import { AuthButton, SaveButton, DeleteButton } from 'views/elements/Buttons';
import {
  CenteredWrapper,
  FlexRowWrapWrapper,
  FlexSpaceBetweenWrapper,
  RightAlignedWrapper,
} from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';

import Icon from 'views/components/Icon';
import AuthInput from 'views/components/AuthInput';
import { Authors } from './Authors';

const schema = yup.object().shape({
  title: yup.string().required('Título inválido'),
  description: yup.string().required('Descrição inválida'),
});

export const Bundle = ({ bundle, url, historyPush }) => (
  <React.Fragment>
    <Switch>
      <Route
        exact
        path={`${url}/edit`}
        render={props => (
          <Mutation
            mutation={UpdateAnnotationBundle}
            refetchQueries={[
              {
                query: MyBundle,
                variables: {
                  bundle_id: bundle.id,
                },
              },
            ]}
            onCompleted={() => {
              toast.success('Bundle atualizado com successo');
              historyPush(url);
            }}
            onError={() => {
              toast.error('Não foi possível atualizar o bundle');
              historyPush(url);
            }}
          >
            {(mutate, { loading }) =>
              loading ? (
                <CenteredWrapper>
                  <CircleLoader />
                </CenteredWrapper>
              ) : (
                <Formik
                  validationSchema={schema}
                  initialValues={{
                    title: bundle.title,
                    description: bundle.description,
                  }}
                  onSubmit={(values, actions) => {
                    mutate({
                      variables: {
                        data: {
                          bundle_id: bundle.id,
                          title: values.title,
                          description: values.description,
                        },
                      },
                    });
                  }}
                  render={({
                    errors,
                    touched,
                    isValid,
                    isValidating,
                    isSubmitting,
                  }) => (
                    <Form>
                      <Field
                        type="text"
                        name="title"
                        label="Título"
                        placeholder="Insira um título"
                        component={AuthInput}
                      />
                      <Field
                        type="text"
                        name="description"
                        label="Descrição"
                        placeholder="Insira uma descrição"
                        component={AuthInput}
                      />
                      <RightAlignedWrapper>
                        <SaveButton
                          type="submit"
                          disabled={isSubmitting || isValidating || !isValid}
                        >
                          Guardar
                        </SaveButton>
                        <Link to={url}>
                          <DeleteButton>Cancelar</DeleteButton>
                        </Link>
                      </RightAlignedWrapper>
                    </Form>
                  )}
                />
              )
            }
          </Mutation>
        )}
      />
      <Route
        path={url}
        render={props => (
          <React.Fragment>
            <FlexSpaceBetweenWrapper>
              <CardTitle>
                {bundle.title}
                {bundle.buyable && ` - R$${bundle.price}`}
              </CardTitle>
              {bundle.owner && (
                <Link to={`${url}/edit`}>
                  <Icon name="edit" color="black" />
                </Link>
              )}
            </FlexSpaceBetweenWrapper>
            <CardText>{bundle.description}</CardText>
          </React.Fragment>
        )}
      />
    </Switch>
    <CardSubTitle>Anotações</CardSubTitle>
    <FlexRowWrapWrapper>
      {bundle.annotations &&
        bundle.annotations.map(ann => (
          <Link
            className="annotation"
            to={`/my_bundles/${bundle.id}/annotation/${ann.id}`}
            key={ann.id}
          >
            <AnnotationCard>
              <h1>{ann.section.full_name}</h1>
              <h2>{ann.section.content.name}</h2>
              <p>{ann.text}</p>
            </AnnotationCard>
          </Link>
        ))}
    </FlexRowWrapWrapper>
    <RightAlignedWrapper>
      <Link to={`/my_bundles/${bundle.id}/new_annotation`}>
        <AuthButton>criar anotação</AuthButton>
      </Link>
      <Link to={`/my_bundles/${bundle.id}/associate_annotations`}>
        <AuthButton>associar anotação</AuthButton>
      </Link>
    </RightAlignedWrapper>
    <CardSubTitle>Autores</CardSubTitle>
    <Authors
      bundleId={bundle.id}
      owner={bundle.owner}
      ownerId={bundle.author.id}
      authors={bundle.authors}
      invites={bundle.invites}
    />
    {bundle.owner &&
      !bundle.buyable && (
        <CenteredWrapper>
          <Link to={`/my_bundles/${bundle.id}/sell`}>
            <AuthButton>colocar para venda</AuthButton>
          </Link>
        </CenteredWrapper>
      )}
  </React.Fragment>
);

Bundle.propTypes = {
  bundle: PropTypes.shape({}).isRequired,
  url: PropTypes.string.isRequired,
  historyPush: PropTypes.func.isRequired,
};
