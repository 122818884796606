import gql from 'graphql-tag';

export const GetLMS = gql`
  query getLMS {
    lms {
      id
      name
      main_sections {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
        paragraphs {
          category
          id
          info
          level
          name
          number
          position
          text
          favourited
          points {
            category
            id
            info
            level
            name
            number
            position
            text
            favourited
          }
        }
        points {
          category
          id
          info
          level
          name
          number
          position
          text
          favourited
        }
      }
    }
  }
`;

export const GetLMSArticle = gql`
  query getLMSArticle($articleNumber: Int!) {
    lmsArticle(articleNumber: $articleNumber) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
      jurisprudences {
        id
        text
      }
      norms {
        id
        text
      }
      questions {
        id
        text
      }
      paragraphs {
        id
        number
        text
        favourited
        points {
          id
          number
          text
          favourited
        }
      }
      points {
        id
        number
        text
        favourited
      }
    }
  }
`;

export const GetLMSParagraph = gql`
  query getLMSParagraph($articleNumber: Int!, $paragraphNumber: Int!) {
    lmsParagraph(
      articleNumber: $articleNumber
      paragraphNumber: $paragraphNumber
    ) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
      jurisprudences {
        id
        text
      }
      norms {
        id
        text
      }
      questions {
        id
        text
      }
      points {
        id
        number
        text
        favourited
      }
    }
  }
`;

export const GetLMSPoint = gql`
  query getLMSPoint(
    $articleNumber: Int!
    $paragraphNumber: Int
    $pointNumber: Int!
  ) {
    lmsPoint(
      articleNumber: $articleNumber
      paragraphNumber: $paragraphNumber
      pointNumber: $pointNumber
    ) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
      jurisprudences {
        id
        text
      }
      norms {
        id
        text
      }
      questions {
        id
        text
      }
    }
  }
`;
