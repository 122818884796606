import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SVG from 'react-inlinesvg';

import { confirmAccount } from 'core/user/api';
import { parseQueryString } from 'core/utils/helpers';
import { CONFIRM_ACCOUNT_SUCCESS } from 'core/constants';

import { AuthCard } from 'views/elements/Cards';
import { Info } from 'views/elements/Texts';
import { AuthLink } from 'views/elements/Buttons';

import successSrc from 'views/assets/icons/success.svg';
import errorSrc from 'views/assets/icons/error.svg';

export class AccountConfirmationScreen extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  state = {
    error: null,
    success: null,
  };

  componentDidMount() {
    this.confirm();
  }

  confirm = async () => {
    const token = parseQueryString(this.props.location.search).token || '';
    confirmAccount(token)
      .then(async res => {
        if (res.ok) {
          this.setState({
            error: null,
            success: true,
          });
        } else {
          const data = await res.json();
          this.setState({
            error: data.error,
            success: false,
          });
        }
      })
      .catch(error => {
        this.setState({
          error: error.message,
          success: false,
        });
      });
  };

  render() {
    const { error, success } = this.state;

    const message = success
      ? CONFIRM_ACCOUNT_SUCCESS
      : error != null
        ? error
        : '';

    return (
      <AuthCard>
        <SVG
          style={{ width: '6rem', height: '6rem' }}
          src={success ? successSrc : errorSrc}
        />
        <Info>{message}</Info>
        <AuthLink to="/login">Voltar à página de login.</AuthLink>
      </AuthCard>
    );
  }
}

export default AccountConfirmationScreen;
