import React, { Component } from 'react';
import { Mutation } from 'react-apollo';
import styled from 'styled-components';
import { RedeemCode } from 'core/redeem/mutations';
import { GetBundles } from 'core/bundle/queries';
import { toast } from 'core/utils/toast';

const Wrapper = styled.div`
  background-color: #f3f3f3;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: -webkit-fill-available;
`;

const InputWrapper = styled.div`
  width: 50rem;
  height: 4.5rem;
  background-color: var(--medium_pink);

  padding: 1rem;
  margin: 1rem;

  box-shadow: 0 6px 34px #e4e4e4;
  border-radius: 6px;

  input {
    width: 100%;
    color: white;
    font-family: 'Roboto', 'Open Sans', 'Segoe UI', sans-serif;
    font-size: 2rem;
    font-weight: 300;
    &::placeholder {
      color: white;
    }
  }
`;

const Button = styled.button`
  background-color: var(--light_pink);
  border-radius: 6px;
  width: 50rem;
  margin: 1rem;
  padding: 1rem;

  justify-content: center;
  align-items: center;
  cursor: pointer;

  color: white;
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 2rem;
  font-weight: 700;

  &:disabled {
    cursor: default;
    opacity: 0.5;
  }
`;

class RedeemCodeScreen extends Component {
  state = {
    code: '',
  };

  changeCode = code => this.setState({ code });

  render() {
    const {
      state: { code },
      changeCode,
    } = this;

    return (
      <Mutation
        mutation={RedeemCode}
        variables={{
          data: {
            code,
            provider: 'webapp',
          },
        }}
        refetchQueries={[
          {
            query: GetBundles,
          },
        ]}
        onCompleted={() => {
          toast.success('Código resgatado com sucesso');
          changeCode('');
        }}
        onError={() => toast.error('Não foi possível resgatar o código')}
      >
        {(mutate, { loading }) => (
          <Wrapper>
            <InputWrapper>
              <input
                type="text"
                value={code}
                placeholder="Código Promocional"
                onChange={ev => changeCode(ev.target.value)}
              />
            </InputWrapper>
            <Button onClick={() => mutate()} disabled={code === '' || loading}>
              Resgatar Código
            </Button>
          </Wrapper>
        )}
      </Mutation>
    );
  }
}
export default RedeemCodeScreen;
