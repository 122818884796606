import gql from 'graphql-tag';

export const GetApps = gql`
  query getApps {
    apps {
      description
      id
      name
      slug
      has_premium
    }
  }
`;

export const GetApp = gql`
  query getApp($slug: String!) {
    app(slug: $slug) {
      description
      id
      name
      slug
      contents {
        category
        description
        id
        main_sections {
          content_id
          category
          id
          info
          level
          name
          number
          position
          text
        }
        name
        slug
        version
        year
      }
    }
  }
`;

export const GetPremiumApps = gql`
  query getApps {
    apps {
      description
      id
      name
      slug
      premium_price
      has_premium
      annotations_count
      premium_authors {
        name
        id
      }
    }
  }
`;

export const GetPremiumApp = gql`
  query getApp($slug: String!) {
    app(slug: $slug) {
      description
      id
      name
      slug
      premium_price
      has_premium
      annotations_count
      premium_authors {
        name
        id
      }
      premium_bundles {
        annotations {
          section {
            full_name
            content {
              id
              name
            }
          }
          text
          id
        }
      }
    }
  }
`;
