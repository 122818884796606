import cookie from 'cookie';

export const load = key => {
  const cookies = cookie.parse(document.cookie);

  let cookieVal = cookies && cookies[key];

  try {
    cookieVal = JSON.parse(cookieVal);
  } catch (e) {
    // Not serialized object
  }

  return cookieVal;
};

export const save = (name, val, opt) => {
  let newVal = val;

  if (typeof val === 'object') {
    newVal = JSON.stringify(val);
  }

  document.cookie = cookie.serialize(name, newVal, opt);
};

export const remove = name => {
  const opt = { expires: new Date(1970, 1, 1, 0, 0, 1) };
  document.cookie = cookie.serialize(name, '', opt);
};
