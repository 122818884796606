import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { toast } from 'core/utils/toast';
import { MyBundle } from 'core/bundle/queries';
import {
  RemoveAuthor,
  AcceptInvite,
  DeclineInvite,
} from 'core/bundle/mutations';

import Icon from 'views/components/Icon';
import { Button } from 'views/elements/Buttons';
import { DotLoader } from 'views/elements/Loaders';
import { CenteredWrapper, FlexRowWrapper } from 'views/elements/Wrappers';
import { Row, Cell } from 'views/elements/Tables';

export const AuthorRow = ({
  owner,
  ownerId,
  authorId,
  authorName,
  inviteStatus,
  inviteType,
  inviteId,
  invited,
  bundleId,
}) => (
  <Row>
    <Cell>{authorName}</Cell>
    <Cell>{inviteStatus}</Cell>
    <Cell>
      <FlexRowWrapper>
        {owner &&
          ownerId !== authorId && (
            <Mutation
              mutation={RemoveAuthor}
              variables={{
                data: {
                  bundle_id: bundleId,
                  type: inviteType,
                  invite_id: inviteId,
                },
              }}
              refetchQueries={[
                {
                  query: MyBundle,
                  variables: {
                    bundle_id: bundleId,
                  },
                },
              ]}
              onCompleted={() => toast.success('Autor removido com successo')}
              onError={() => toast.error('Não foi possível remover o autor')}
            >
              {(mutate, { loading }) =>
                loading ? (
                  <CenteredWrapper>
                    <DotLoader />
                  </CenteredWrapper>
                ) : (
                  <Button onClick={() => mutate()}>
                    <Icon name="delete" color="black" />
                  </Button>
                )
              }
            </Mutation>
          )}
        {invited &&
          inviteStatus === 'invited' && (
            <React.Fragment>
              <Mutation
                mutation={AcceptInvite}
                variables={{
                  data: {
                    invite_id: inviteId,
                  },
                }}
                refetchQueries={[
                  {
                    query: MyBundle,
                    variables: {
                      bundle_id: bundleId,
                    },
                  },
                ]}
                onCompleted={() => toast.success('Convite aceite com successo')}
                onError={() =>
                  toast.error('Não foi possível aceitar o convite')
                }
              >
                {(mutate, { loading }) =>
                  loading ? (
                    <CenteredWrapper>
                      <DotLoader />
                    </CenteredWrapper>
                  ) : (
                    <Button onClick={() => mutate()}>
                      <Icon name="checkmark" color="green" />
                    </Button>
                  )
                }
              </Mutation>
              <Mutation
                mutation={DeclineInvite}
                variables={{
                  data: {
                    invite_id: inviteId,
                  },
                }}
                refetchQueries={[
                  {
                    query: MyBundle,
                    variables: {
                      bundle_id: bundleId,
                    },
                  },
                ]}
                onCompleted={() =>
                  toast.success('Convite rejeitado com successo')
                }
                onError={() =>
                  toast.error('Não foi possível rejeitar o convite')
                }
              >
                {(mutate, { loading }) =>
                  loading ? (
                    <CenteredWrapper>
                      <DotLoader />
                    </CenteredWrapper>
                  ) : (
                    <Button onClick={() => mutate()}>
                      <Icon name="cross" color="red" width={20} />
                    </Button>
                  )
                }
              </Mutation>
            </React.Fragment>
          )}
      </FlexRowWrapper>
    </Cell>
  </Row>
);

AuthorRow.propTypes = {
  owner: PropTypes.bool,
  ownerId: PropTypes.string,
  authorId: PropTypes.string,
  authorName: PropTypes.string,
  inviteStatus: PropTypes.string,
  inviteType: PropTypes.string,
  inviteId: PropTypes.string,
  invited: PropTypes.bool,
  bundleId: PropTypes.string,
};
