import gql from 'graphql-tag';

export const UpdateUser = gql`
  mutation updateUser($data: EditUserInput!) {
    updateUser(input: $data) {
      id
      name
      email
      about
      job
      unconfirmed_email
      picture
    }
  }
`;

export const ActivateTrial = gql`
  mutation activateTrial($data: ActivateTrialInput!) {
    activateTrial(input: $data) {
      about
      email
      id
      job
      name
      picture
      trial
    }
  }
`;
