import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'views/elements/Inputs';
import { AnnotationCard } from 'views/elements/Cards';

export const Annotation = ({
  option,
  copyAnnotations,
  moveAnnotations,
  handleInputChange,
  setFieldValue,
}) => (
  <AnnotationCard>
    <h1>{option.sectionName}</h1>
    <h2>{option.contentName}</h2>
    <p>{option.text}</p>
    <h3>Acções</h3>
    <Checkbox htmlFor={`${option.name}_copy`}>
      copiar
      <input
        type="checkbox"
        name={`${option.name}_copy`}
        id={`${option.name}_copy`}
        value={option.id}
        onChange={ev =>
          handleInputChange(
            ev,
            'copy_annotations',
            copyAnnotations,
            setFieldValue,
            moveAnnotations,
          )
        }
        checked={copyAnnotations.find(a => a === option.id) != null}
      />
      <span />
    </Checkbox>
    <Checkbox htmlFor={`${option.name}_move`}>
      mover
      <input
        type="checkbox"
        name={`${option.name}_move`}
        id={`${option.name}_move`}
        value={option.id}
        onChange={ev =>
          handleInputChange(
            ev,
            'move_annotations',
            moveAnnotations,
            setFieldValue,
            copyAnnotations,
          )
        }
        checked={moveAnnotations.find(a => a === option.id) != null}
      />
      <span />
    </Checkbox>
  </AnnotationCard>
);

Annotation.propTypes = {
  option: PropTypes.shape({}).isRequired,
  copyAnnotations: PropTypes.arrayOf(PropTypes.string).isRequired,
  moveAnnotations: PropTypes.arrayOf(PropTypes.string).isRequired,
  handleInputChange: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
};
