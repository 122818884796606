import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';
import { Switch, Route } from 'react-router-dom';

import { theme } from 'core/theme';

import Icon from 'views/components/Icon';
import { IconLink } from 'views/elements/Buttons';
import {
  FlexColWrapper,
  CenteredWrapper,
  FlexSpaceBetweenWrapper,
  ArticleWrapper,
} from 'views/elements/Wrappers';
import {
  ArticleTitle,
  AnnotationTitle,
  Text,
  Info,
} from 'views/elements/Texts';
import { VerticalLine } from 'views/elements/Separators';
import { CircleLoader } from 'views/elements/Loaders';

import AnnotationEditor from 'views/components/AnnotationEditor';
import { FavouriteButton } from 'views/components/FavouriteButton';
import { Comments } from './Comments';

const SectionBody = ({ sectionName, sectionText, slug, splitted }) => (
  <ArticleWrapper splitted={splitted}>
    <ArticleTitle slug={slug}>{sectionName}</ArticleTitle>
    <Text>{sectionText}</Text>
  </ArticleWrapper>
);

SectionBody.propTypes = {
  sectionName: PropTypes.string,
  sectionText: PropTypes.string,
  slug: PropTypes.string,
  splitted: PropTypes.bool,
};

export class Section extends Component {
  static propTypes = {
    slug: PropTypes.string,

    query: PropTypes.shape({}),
    variables: PropTypes.shape({}),
    sectionKey: PropTypes.string,
    sectionName: PropTypes.string,

    Sections: PropTypes.func,

    match: PropTypes.shape({
      url: PropTypes.string,
    }),
    history: PropTypes.shape({
      push: PropTypes.func,
    }),
  };

  state = {
    annotationId: null,
    annotationText: null,
    editor: false,
    annotation: false,
    expanded: false,
  };

  toggleAnnotation = () => {
    this.setState(prevState => ({
      annotation: !prevState.annotation,
    }));
  };

  toggleEditor = () => {
    this.setState(prevState => ({
      editor: !prevState.editor,
      annotationId: null,
      annotationText: null,
    }));
  };

  toggleEditorForAnnotation = (id, text) => {
    this.props.history.push(`${this.props.match.url}/edit_annotation/${id}`);
    this.setState({
      editor: true,
      annotationId: id,
      annotationText: text,
    });
  };

  expandEditor = () => {
    this.setState(prevState => ({
      expanded: !prevState.expanded,
    }));
  };

  annotationCreatedWithSuccess = () => {
    this.props.history.push(`${this.props.match.url}/annotations`);
    this.setState({
      editor: false,
      annotationId: null,
      annotationText: null,
    });
  };

  render() {
    const {
      props: {
        slug,
        query,
        variables,
        sectionKey,
        sectionName,
        Sections,
        match: { path, url },
      },
      state: { editor, annotationId, annotationText, expanded, annotation },
      toggleAnnotation,
      toggleEditor,
      toggleEditorForAnnotation,
      expandEditor,
      annotationCreatedWithSuccess,
    } = this;

    const color = theme.appColors[slug].primary;

    return (
      <Query query={query} variables={variables}>
        {({ loading, error, data }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <CircleLoader slug={slug} />
              </CenteredWrapper>
            );
          if (error || data[sectionKey] == null)
            return <Info>Artigo não encontrado.</Info>;

          const section = data[sectionKey];

          const premiumAnnotations = section.premium_annotations;
          const collaborativeAnnotations = section.collaborative_annotations;
          const privateAnnotations = section.private_annotations;
          const { jurisprudences = [], questions = [], norms = [] } = section;
          const hasAnnotations =
            premiumAnnotations.length +
              collaborativeAnnotations.length +
              privateAnnotations.length +
              jurisprudences.length +
              questions.length +
              norms.length >
            0;

          return (
            <FlexColWrapper>
              <CenteredWrapper>
                <IconLink
                  slug={slug}
                  width="4rem"
                  height="4rem"
                  disabled={!hasAnnotations}
                  onClick={() => toggleAnnotation()}
                  to={annotation ? url : `${url}/annotations`}
                >
                  <Icon name="comment" color={color} />
                </IconLink>
                <IconLink
                  width="4rem"
                  height="4rem"
                  slug={slug}
                  onClick={() => toggleEditor()}
                  to={editor ? url : `${url}/new_annotation`}
                >
                  <Icon name="add_comment" color={color} />
                </IconLink>
                <FavouriteButton
                  section={section}
                  slug={slug}
                  buttonWidth="4rem"
                  buttonHeight="4rem"
                  iconWidth="22"
                  iconHeight="22"
                />
              </CenteredWrapper>
              <Switch>
                <Route
                  path={path}
                  exact
                  render={props => (
                    <SectionBody
                      sectionName={sectionName}
                      sectionText={section.text}
                      slug={slug}
                    />
                  )}
                />
                <Route
                  path={`${path}/annotations`}
                  exact
                  render={props => (
                    <FlexSpaceBetweenWrapper>
                      <SectionBody
                        sectionName={sectionName}
                        sectionText={section.text}
                        slug={slug}
                        splitted
                      />
                      <VerticalLine slug={slug} />
                      <ArticleWrapper splitted>
                        <Comments
                          premiumAnnotations={premiumAnnotations}
                          collaborativeAnnotations={collaborativeAnnotations}
                          privateAnnotations={privateAnnotations}
                          jurisprudences={jurisprudences}
                          questions={questions}
                          norms={norms}
                          toggleEditor={toggleEditorForAnnotation}
                          slug={slug}
                        />
                      </ArticleWrapper>
                    </FlexSpaceBetweenWrapper>
                  )}
                />
                <Route
                  path={[
                    `${path}/new_annotation`,
                    `${path}/edit_annotation/:id`,
                  ]}
                  exact
                  render={props => (
                    <FlexSpaceBetweenWrapper>
                      {!expanded && (
                        <React.Fragment>
                          <SectionBody
                            sectionName={sectionName}
                            sectionText={section.text}
                            slug={slug}
                            splitted
                          />
                          <VerticalLine slug={slug} />
                        </React.Fragment>
                      )}
                      <ArticleWrapper splitted={!expanded}>
                        <AnnotationTitle>Anotação</AnnotationTitle>
                        <AnnotationEditor
                          slug={slug}
                          sectionId={section.id}
                          newAnnotation={annotationId == null}
                          annotationId={annotationId}
                          annotationText={annotationText || ''}
                          successFunc={annotationCreatedWithSuccess}
                          expanded={expanded}
                          expandFunc={expandEditor}
                        />
                      </ArticleWrapper>
                    </FlexSpaceBetweenWrapper>
                  )}
                />
              </Switch>
              {Sections != null && <Sections section={section} />}
            </FlexColWrapper>
          );
        }}
      </Query>
    );
  }
}

export default Section;
