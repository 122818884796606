import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import { Link } from 'react-router-dom';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';

import AuthInput from 'views/components/AuthInput';

import { SellAnnotationBundle } from 'core/bundle/mutations';
import { MyBundle } from 'core/bundle/queries';

import { AuthButton } from 'views/elements/Buttons';
import { SidesMarginWrapper, CenteredWrapper } from 'views/elements/Wrappers';

const schema = yup.object().shape({
  price: yup.number().positive('Preço negativo'),
});

export const SellBundle = ({ bundleId, historyPush }) => (
  <React.Fragment>
    <Mutation
      mutation={SellAnnotationBundle}
      refetchQueries={[
        {
          query: MyBundle,
          variables: {
            bundle_id: bundleId,
          },
        },
      ]}
      onCompleted={() => {
        toast.success('O seu Bundle foi adicionado ao mercado');
        historyPush(`/my_bundles/${bundleId}`);
      }}
      onError={() =>
        toast.error('Não foi possível colocar o bundle para venda')
      }
    >
      {mutate => (
        <Formik
          validationSchema={schema}
          initialValues={{
            price: '',
          }}
          onSubmit={(values, actions) =>
            mutate({
              variables: {
                data: {
                  price: parseFloat(values.price),
                  bundle_id: bundleId,
                },
              },
            })
          }
          render={({ errors, touched, isSubmitting, values }) => (
            <Form>
              <SidesMarginWrapper level={1}>
                <Field
                  type="text"
                  name="price"
                  label={`Preço R$${
                    isNaN(parseFloat(values.price))
                      ? ''
                      : parseFloat(values.price)
                  }`}
                  placeholder="Insira um preço"
                  component={AuthInput}
                />
              </SidesMarginWrapper>
              <CenteredWrapper style={{ marginTop: '5rem' }}>
                <AuthButton type="submit" disabled={isSubmitting}>
                  iniciar venda
                </AuthButton>
                <Link to={`/my_bundles/${bundleId}`}>
                  <AuthButton>cancelar</AuthButton>
                </Link>
              </CenteredWrapper>
            </Form>
          )}
        />
      )}
    </Mutation>
  </React.Fragment>
);

SellBundle.propTypes = {
  bundleId: PropTypes.string,
  historyPush: PropTypes.func,
};
