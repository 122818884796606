import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  ${props => props.verticalCentering && 'justify-content: center'};
  width: ${props => props.width || '20%'};
  min-height: ${props => props.height || '30rem'};
  margin: ${props => props.margin || '2.5%'};
  padding: 2rem;
  background-color: white;
  box-shadow: 0 0 34px #e4e4e4;
  border-radius: 10px;
`;

export const BookCard = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 23rem;
  height: 30rem;
  margin: 1.5rem;
  padding: 3.7rem 1.8rem 3rem 3.2rem;
  background-color: white;
  border-radius: 0 6px 6px 0;
  position: relative;

  h1 {
    font-size: 2.8rem;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 100;
  }

  h2 {
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-top: 1.6rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 100;
    text-transform: lowercase;
    &::first-letter {
      text-transform: uppercase;
    }
  }

  h3 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    position: absolute;
    bottom: 5.2rem;
    font-family: 'Source Sans Pro', sans-serif;
  }

  h4 {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    position: absolute;
    bottom: 3rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
  }
`;

export const EmptyBookCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 23rem;
  height: 30rem;
  margin: 1.5rem;
  padding: 3.7rem 1.8rem 3rem 3.2rem;
  background-color: white;
  border-radius: 0 6px 6px 0;

  h1 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.textGreySecondary};
    text-align: center;
    padding: 1rem;
  }
`;

export const AppCard = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 20rem;
  height: 22rem;
  margin: 2rem;
  background-color: white;
  border-radius: 6px;
  border-left: 1.2rem solid ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  position: relative;
  font-family: 'Source Sans Pro', sans-serif;
  box-shadow: 0 2px 4px 0 rgba(40,43,47,0.10);

  h1 {
    text-transform: uppercase;
    font-size: 2.2rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 4.3rem;
    font-family: 'Futura', sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 0 1rem;
  }

  h2 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    position: absolute;
    bottom: 4.9rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 0 1rem;
  }

  h3 {
    text-transform: uppercase;
    font-size: 1rem;
    color: white;
    background-color: ${({ theme }) => theme.colors.backgroundDarkPrimary};
    position: absolute;
    bottom: 1.8rem;
    border-radius: 10.5px
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem;
  }

  svg {
    height: 3.4rem;
    position: absolute;
    top: 0;
    right: 1.8rem;
    path {
      fill: ${({ theme, slug }) =>
        theme.appColors[slug]
          ? theme.appColors[slug].primary
          : theme.appColors.anotado.primary};
    }
  }
`;

export const EmptyAppCard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20rem;
  height: 22rem;
  margin: 2rem;
  background-color: white;
  border-radius: 0 6px 6px 0;

  h1 {
    text-transform: uppercase;
    font-size: 1.2rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.textGreySecondary};
    text-align: center;
    padding: 1rem;
  }
`;

export const ArticleCard = styled.div`
  display: flex;
  width: 100%;
  min-height: 20rem;
  background-color: white;
  position: relative;
  padding: 2rem 2rem 2rem 0.5rem;
  margin-top: 4rem;
  align-items: center;

  p {
    margin-top: 1rem;
    margin-left: 1.3rem;
    margin-right: 6rem;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    white-space: pre-wrap;
  }
`;

export const LawCard = styled(Link)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 60rem;
  height: 15rem;
  margin: 1.5rem;
  padding: 3.7rem 1.8rem 3rem 3.2rem;
  background-color: white;
  border-radius: 0 6px 6px 0;
  position: relative;

  h1 {
    font-size: 2.8rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 700;
  }

  h4 {
    text-transform: uppercase;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    position: absolute;
    bottom: 3rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
  }
`;

export const BookmarkCard = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 40rem;
  height: 30rem;
  background-color: white;
  position: relative;
  padding: 2rem;
  margin: 1rem;
  border-radius: 6px;

  h1 {
    font-size: 2rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 700;
  }

  h2 {
    font-size: 1rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 700;
  }

  p {
    margin-top: 0.5rem;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    white-space: pre-wrap;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
`;

export const SearchResultsCard = styled(Link)`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: white;
  padding: 2rem;
  margin-top: 2rem;

  h1 {
    font-size: 2rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 700;
  }

  h2 {
    font-size: 1rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 0.5rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 700;
  }

  p {
    margin-top: 0.5rem;
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    white-space: pre-wrap;

    .highlight {
      background-color: ${({ theme, slug }) =>
        theme.appColors[slug]
          ? theme.appColors[slug].secondary
          : theme.appColors.anotado.secondary};
    }
  }
`;

export const PremiumCard = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 31rem;
  height: 36rem;
  margin: 2rem;
  padding: 3rem;
  background-color: white;
  border-radius: 0px 6px 6px 0px;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;

  h1 {
    text-transform: uppercase;
    font-size: 2.2rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 1.9rem;
    font-family: 'Futura', sans-serif;
    font-weight: bold;
    padding-right: 9rem;
  }

  h2 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    text-align: left;
    position: absolute;
    top: 14rem;
  }

  h3 {
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Source Sans Pro', sans-serif;
    font-style: italic;
    text-align: left;
    position: absolute;
    top: 15.5rem;
  }

  h4 {
    position: absolute;
    top: 20rem;
    left: 0;
    right: 0;
    margin: 0 6rem;
    text-transform: uppercase;
    font-size: 1.4rem;
    color: white;
    background-color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    border-radius: 14.7px
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 1.5rem;
  }

  div {
    position: absolute;
    top: 30rem;
    align-items: center;

    p {
      text-transform: uppercase;
      font-size: 1.2rem;
      color: ${({ theme }) => theme.colors.textPrimary};
      font-family: 'NB International Regular Webfont', sans-serif;
      font-weight: 700;
      margin-left: 1.6rem;
      margin-right: 4rem;
    }
  }
`;

export const PremiumBundleCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 6rem 12rem;
  width: 100%;
  background-color: white;
  border-radius: 6px;
  font-family: 'Source Sans Pro', sans-serif;

  h1 {
    font-size: 2.8rem;
    margin-bottom: 0.5rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
  }

  h2 {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  h3 {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    font-family: 'NB International Regular Webfont', sans-serif;
  }

  h4 {
    font-size: 2.2rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: 100;
    font-style: italic;
    text-align: left;
    margin-top: 6rem;
  }

  h5 {
    text-transform: uppercase;
    font-size: 1.4rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-top: 3.4rem;
    margin-bottom: 0.5rem;
    font-family: 'Futura', sans-serif;
    font-weight: bold;
  }

  h6 {
    font-size: 1rem;
    color: ${({ theme, slug }) =>
      theme.appColors[slug]
        ? theme.appColors[slug].primary
        : theme.appColors.anotado.primary};
    margin-bottom: 0.5rem;
    font-family: 'Futura', sans-serif;
  }

  p {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    white-space: pre-wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }
`;

export const AuthCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4rem 8rem;
  background-color: white;
  border-radius: 10px;
  margin: 2rem;
`;

export const AnnotationCard = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  border-radius: 6px;
  margin: 2rem;
  width: 35rem;

  h1 {
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-size: 2rem;
    line-height: 2.2rem;
    height: 2.2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  h2 {
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-size: 1rem;
  }

  h3 {
    font-family: 'NB International Regular Webfont', sans-serif;
    font-size: 1.4rem;
    margin-top: 2rem;
  }

  p {
    margin: 0.5rem 0;
    font-size: 1.8rem;
    line-height: 2rem;
    height: 10rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Open Sans', sans-serif;
    white-space: pre-wrap;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
`;

export const BundleCard = styled(Link)`
  display: flex;
  flex-direction: column;
  padding: 2rem;
  background-color: white;
  border-radius: 6px;
  margin: 2rem;
  width: 20rem;
  width: 30rem;

  h1 {
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-size: 2rem;
    line-height: 2.2rem;
    height: 2.2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  h2 {
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 400;
    color: ${({ theme }) => theme.colors.textPrimary};
    margin-top: 1rem;
    font-size: 1.6rem;
    line-height: 1.6rem;
    height: 8rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  h3 {
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-size: 1.2rem;
    margin-top: 1rem;
  }
`;

export const ChangesCard = styled.div`
  width: 100%;
  background-color: white;
  position: relative;
  padding: 2rem;
  margin-bottom: 2rem;
  align-items: center;

  h1 {
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-size: 2rem;
  }

  h2 {
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: 100;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-size: 1.4rem;
  }
  p {
    margin-top: 1rem;
    font-size: 1.6rem;
    color: ${({ theme }) => theme.colors.textPrimary};
    font-family: 'Open Sans', sans-serif;
    text-align: justify;
    white-space: pre-wrap;
  }
`;

export const CollaborativeBundleCard = styled(Link)`
  cursor: pointer;
  display: flex;
  flex-direction: column;
  width: 31rem;
  height: 36rem;
  margin: 2rem;
  padding: 3rem;
  background-color: white;
  border-radius: 0px 6px 6px 0px;
  font-family: 'Source Sans Pro', sans-serif;
  position: relative;

  h1 {
    text-transform: uppercase;
    font-size: 2.2rem;
    color: ${({ theme }) => theme.appColors.anotado.primary};
    font-family: 'Futura', sans-serif;
    font-weight: bold;

    line-height: 2.4rem;
    height: 7.2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  h2 {
    margin-top: 1rem;
    font-size: 1.4rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    text-align: left;

    line-height: 1.6rem;
    height: 11.2rem;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 7;
    -webkit-box-orient: vertical;
  }

  h3 {
    margin-top: 1rem;
    font-size: 1rem;
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    font-family: 'Source Sans Pro', sans-serif;
    font-style: italic;
    text-align: left;
  }

  h4 {
    margin-top: 1rem;
    font-style: italic;
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.textSecondary};
    font-family: 'Source Sans Pro', sans-serif;
  }

  h5 {
    text-transform: uppercase;
    font-size: 1.4rem;
    color: white;
    background-color: ${({ theme }) => theme.appColors.anotado.primary};
    border-radius: 14.7px
    font-family: 'NB International Regular Webfont', sans-serif;
    font-weight: bold;
    text-align: center;
    padding: 0.5rem 1.5rem;
    bottom: 4rem;
    left: 0;
    right: 0;
    margin: 0 4rem;
    position: absolute;
  }
`;
