import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  width: 30rem;
  height: 4rem;
  background-color: 'white';
  cursor: pointer;
`;

export const DropdownButton = styled.div`
  &:after {
    position: absolute;
    content: '';
    top: ${({ visible }) => (visible ? '7px' : '14px')};
    right: 10px;
    width: 0;
    height: 0;
    border: 6px solid transparent;
    border-color: ${({ visible }) =>
      visible
        ? 'transparent transparent #ddd transparent'
        : '#ddd transparent transparent transparent'};
  }
`;
DropdownButton.displayName = 'DropdownButton';

export const DropdownOptions = styled.div`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
  position: absolute;
  background-color: white;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
  box-shadow: 0 2px 4px 0 rgba(40, 43, 47, 0.1);
`;

export const DropdownSelectedOption = styled.div`
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  padding: 1rem;
  background-color: white;
  height: 4rem;
  display: flex;
  align-items: center;
  border: 1px solid #000;
`;

export const DropdownOption = styled.div`
  font-size: 1.6rem;
  font-family: 'NB International Regular Webfont', sans-serif;
  padding: 1rem;
  background-color: ${({ selected }) => (selected ? 'grey' : 'white')};
  height: 4rem;
  display: flex;
  align-items: center;
  &:hover {
    background-color: lightgrey;
  }
`;
DropdownOption.displayName = 'DropdownOption';

export class Dropdown extends Component {
  static propTypes = {
    options: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      }),
    ).isRequired,
    selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      .isRequired,
    selectValue: PropTypes.func.isRequired,
  };

  state = {
    visible: false,
  };

  show = () => {
    this.setState({ visible: true });
    document.addEventListener('click', this.hide);
  };

  hide = () => {
    this.setState({ visible: false });
    document.removeEventListener('click', this.hide);
  };

  select = value => {
    this.props.selectValue(value);
  };

  render() {
    const {
      props: { options, selectedValue },
      state: { visible },
      select,
      show,
    } = this;

    const selectedItem = options.find(opt => opt.value === selectedValue);

    return (
      <DropdownContainer>
        <DropdownButton onClick={show} visible={visible}>
          <DropdownSelectedOption>
            {selectedItem && selectedItem.label}
          </DropdownSelectedOption>
        </DropdownButton>
        <DropdownOptions visible={visible}>
          {options.map(opt => (
            <DropdownOption
              key={opt.value}
              onClick={() => select(opt.value)}
              selected={opt.value === selectedValue}
            >
              {opt.label}
            </DropdownOption>
          ))}
        </DropdownOptions>
      </DropdownContainer>
    );
  }
}

Dropdown.displayName = 'Dropdown';
