import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';
import { recoverPassword } from 'core/user/api';

import AuthInput from 'views/components/AuthInput';

import emailIcon from 'views/assets/icons/mail.svg';
import { AuthCard } from 'views/elements/Cards';
import { Info } from 'views/elements/Texts';
import { AuthSubmitButton, AuthLink } from 'views/elements/Buttons';
import { AuthForm } from 'views/elements/Inputs';

import {
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERROR,
  RECOVER_PASSWORD_SUCCESS,
} from 'core/constants';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL_ERROR)
    .required(EMPTY_EMAIL_ERROR),
});

export class RecoverPasswordScreen extends Component {
  state = {
    success: null,
  };

  handleSubmit = (values, actions) => {
    recoverPassword(values.email)
      .then(async res => {
        actions.setSubmitting(false);
        if (res.ok) {
          this.setState({
            success: true,
          });
        } else {
          this.setState({
            success: false,
          });
          toast.error(
            'Não foi possível reenviar o email de alteração de password',
          );
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        this.setState({
          success: false,
        });
        toast.error('Ocorreu um problema');
      });
  };

  render() {
    const {
      state: { success },
      handleSubmit,
    } = this;

    return (
      <AuthCard>
        {success ? (
          <Info>{RECOVER_PASSWORD_SUCCESS}</Info>
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              email: '',
            }}
            onSubmit={handleSubmit}
            render={({ errors, touched, isSubmitting }) => (
              <AuthForm>
                <Field
                  name="email"
                  label="Email"
                  placeholder="Escreva o seu email"
                  component={AuthInput}
                />
                <AuthSubmitButton type="submit" disabled={isSubmitting}>
                  <SVG src={emailIcon} />
                  <p>enviar</p>
                </AuthSubmitButton>
              </AuthForm>
            )}
          />
        )}
        <AuthLink to="/login">Voltar à página de login.</AuthLink>
      </AuthCard>
    );
  }
}

export default RecoverPasswordScreen;
