import React from 'react';
import { Route, Redirect } from 'react-router-dom';

import {
  AccountConfirmation,
  App,
  Apps,
  CollaborativeMarket,
  Login,
  MyBundle,
  MyBundles,
  NewBundle,
  NotFound,
  PremiumMarket,
  Profile,
  RecoverPassword,
  RedeemCode,
  ResendConfirmation,
  ResetPassword,
  Search,
  SignUp,
} from 'views/pages';
import { Logout } from 'views/components/Logout';

const ROUTES = [
  {
    path: '/',
    exact: true,
    component: Apps,
    needLogin: true,
  },
  {
    path: '/confirm',
    exact: true,
    component: AccountConfirmation,
    allowLoggedIn: false,
  },
  {
    path: '/reset',
    exact: true,
    component: ResetPassword,
    allowLoggedIn: false,
  },
  {
    path: '/signup',
    exact: true,
    component: SignUp,
    allowLoggedIn: false,
  },
  {
    path: '/login',
    exact: false,
    component: Login,
    allowLoggedIn: false,
  },
  {
    path: '/recover',
    exact: true,
    component: RecoverPassword,
    allowLoggedIn: false,
  },
  {
    path: '/resend',
    exact: true,
    component: ResendConfirmation,
    allowLoggedIn: false,
  },
  {
    path: '/profile',
    exact: true,
    component: Profile,
    needLogin: true,
  },
  {
    path: '/apps',
    exact: true,
    component: Apps,
    needLogin: true,
  },
  {
    path: '/app/:slug',
    exact: false,
    component: App,
    needLogin: true,
  },
  {
    path: '/search',
    exact: true,
    component: Search,
    needLogin: true,
  },
  {
    path: '/premium',
    exact: false,
    component: PremiumMarket,
    needLogin: true,
  },
  {
    path: '/collaborative',
    exact: false,
    component: CollaborativeMarket,
    needLogin: true,
  },

  {
    path: '/redeem',
    exact: true,
    component: RedeemCode,
    needLogin: true,
  },

  {
    path: '/new_bundle',
    exact: true,
    component: NewBundle,
    needLogin: true,
  },
  {
    path: '/my_bundles',
    exact: true,
    component: MyBundles,
    needLogin: true,
  },
  {
    path: '/my_bundles/:id',
    exact: false,
    component: MyBundle,
    needLogin: true,
  },
  {
    path: '/logout',
    component: Logout,
    needLogin: false,
    allowLoggedIn: true,
  },
  {
    path: '*',
    component: NotFound,
  },
];

export const routes = isLoggedIn =>
  ROUTES.map(({ exact, path, component, needLogin, allowLoggedIn = true }) => {
    const Screen = component;

    return (
      <Route
        key={path}
        path={path}
        exact={exact}
        render={props => {
          if (needLogin) {
            if (isLoggedIn) {
              return <Screen match={props.match} {...props} />;
            }
            return <Redirect to="/login" />;
          } else if (!allowLoggedIn && isLoggedIn) {
            return <Redirect to="/" />;
          }
          return <Screen match={props.match} {...props} />;
        }}
      />
    );
  });
