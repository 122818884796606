import React from 'react';
import PropTypes from 'prop-types';

const ICONS = {
  compare_arrows: {
    path:
      'M9.01 14H2v2h7.01v3L13 15l-3.99-4v3zm5.98-1v-3H22V8h-7.01V5L11 9l3.99 4z',
    viewBox: '0 0 24 24',
  },
  comment: {
    path:
      'M21.99,4c0-1.1-0.89-2-1.99-2H4C2.9,2,2,2.9,2,4v12c0,1.1,0.9,2,2,2h14l4,4L21.99,4z M17,14H7c-0.55,0-1-0.45-1-1v0 c0-0.55,0.45-1,1-1h10c0.55,0,1,0.45,1,1v0C18,13.55,17.55,14,17,14z M17,11H7c-0.55,0-1-0.45-1-1v0c0-0.55,0.45-1,1-1h10 c0.55,0,1,0.45,1,1v0C18,10.55,17.55,11,17,11z M17,8H7C6.45,8,6,7.55,6,7v0c0-0.55,0.45-1,1-1h10c0.55,0,1,0.45,1,1v0 C18,7.55,17.55,8,17,8z',
    viewBox: '0 0 24 24',
  },
  bookmark_fill: {
    path:
      'M191.716 1022.183c-10.637-0.003-23.113-1.503-33.008-11.003-9.895-9.499-12.42-22.863-12.422-34.427v-885.892c0-50.864 46.107-90.861 102.218-90.861h522.449c56.111 0 102.218 39.997 102.218 90.861v885.892c-0.003 11.564-2.527 24.928-12.422 34.427-9.895 9.501-22.371 11-33.008 11.003-4.713-0.023-9.303-1.511-13.132-4.259l-304.88-217.924-304.88 217.924c-3.83 2.748-8.419 4.236-13.132 4.259z',
    viewBox: '0 0 1024 1024',
  },
  bookmark: {
    path:
      'M191.716 1022.183c-10.637-0.003-23.113-1.503-33.008-11.003-9.895-9.499-12.42-22.863-12.422-34.427v-885.892c0-50.864 46.107-90.861 102.218-90.861h522.449c56.111 0 102.218 39.997 102.218 90.861v885.892c-0.003 11.564-2.527 24.928-12.422 34.427-9.895 9.501-22.371 11-33.008 11.003-4.713-0.023-9.303-1.511-13.132-4.259l-304.88-217.924-304.88 217.924c-3.83 2.748-8.419 4.236-13.132 4.259zM191.716 971.784l304.88-217.924c7.868-5.574 18.397-5.574 26.264 0l304.88 217.924v-880.923c0-23.472-21.122-45.43-56.788-45.43h-522.449c-35.666 0-56.788 21.959-56.788 45.43v880.923z',
    viewBox: '0 0 1024 1024',
  },
  add_comment: {
    path:
      'M21.99 4c0-1.1-.89-2-1.99-2H4c-1.1 0-2 .9-2 2v12c0 1.1.9 2 2 2h14l4 4-.01-18zM17 11h-4v4h-2v-4H7V9h4V5h2v4h4v2z',
    viewBox: '0 0 24 24',
  },
  edit: {
    path:
      'M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34c-.39-.39-1.02-.39-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z',
    viewBox: '0 0 24 24',
  },
  delete: {
    path:
      'M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z',
    viewBox: '0 0 24 24',
  },
  checkmark: {
    path: 'M13.5 2l-7.5 7.5-3.5-3.5-2.5 2.5 6 6 10-10z',
    viewBox: '0 0 16 16',
  },
  cross: {
    path:
      'M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z',
    viewBox: '0 0 16 16',
  },
};

const Icon = ({ name, width, height, color }) => {
  const icon = ICONS[name];

  return (
    <React.Fragment>
      {icon != null && (
        <svg
          width={width || '24'}
          height={height || '24'}
          viewBox={icon.viewBox}
        >
          <path style={{ fill: color || 'black' }} d={icon.path} />
        </svg>
      )}
    </React.Fragment>
  );
};

Icon.propTypes = {
  name: PropTypes.string.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  color: PropTypes.string,
};

export default Icon;
