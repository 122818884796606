import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { CircleLoader } from 'views/elements/Loaders';
import {
  FlexColWrapper as Wrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { Error } from 'views/elements/Texts';
import { Label, AuthInput as Input } from 'views/elements/Inputs';
import { AuthLine } from 'views/elements/Separators';

const Image = styled.img`
  width: 20rem;
  heigth: 20rem;
  border-radius: 100%;
`;

export class ImageInput extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.shape(),
      onBlur: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape(),
      touched: PropTypes.shape(),
    }).isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    setFieldValue: PropTypes.func.isRequired,
    src: PropTypes.string,
  };

  state = {
    active: false,
    loading: false,
    thumb: undefined,
  };

  setActive = active => this.setState({ active });

  handleChange = (name, file, setField) => {
    setField(name, file);

    this.setState({ loading: true }, () => {
      const reader = new FileReader();

      reader.onloadend = () => {
        this.setState({ loading: false, thumb: reader.result });
      };

      reader.readAsDataURL(file);
    });
  };

  render() {
    const {
      props: {
        field: { name, value, onBlur },
        form: { errors, touched },
        setFieldValue,
        label,
        placeholder,
        src,
      },
      state: { active, thumb, loading },
      handleChange,
      setActive,
    } = this;

    return (
      <Wrapper>
        <Label>{label}</Label>
        <CenteredWrapper>
          {loading ? <CircleLoader /> : <Image src={thumb || src} alt="Foto" />}
        </CenteredWrapper>
        <Input
          type="file"
          accept="image/*"
          name={name}
          value={value.fileName}
          active={active}
          onChange={e =>
            handleChange(name, e.currentTarget.files[0], setFieldValue)
          }
          onFocus={() => setActive(true)}
          onBlur={e => {
            onBlur(e);
            setActive(false);
          }}
          placeholder={placeholder}
        />
        <AuthLine error={touched[name] && errors[name]} />
        <Error>{touched[name] && errors[name]}</Error>
      </Wrapper>
    );
  }
}

export default ImageInput;
