import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { searchApps } from 'core/constants';

import { parseQueryString, highlightText } from 'core/utils/helpers';
import { SearchSection } from 'core/section/queries';
import { SearchAnnotation } from 'core/annotation/queries';

import { Dropdown } from 'views/components/Dropdown';

import { FlexColWrapper, CenteredWrapper } from 'views/elements/Wrappers';
import { SearchBar } from 'views/elements/Inputs';
import { Info, SearchOption, AdvancedSearch } from 'views/elements/Texts';
import { SearchResultsCard } from 'views/elements/Cards';
import { MoreButton } from 'views/elements/Buttons';

export class SearchScreen extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string.isRequired,
    }),
    history: PropTypes.shape({
      push: PropTypes.func.isRequired,
      replace: PropTypes.func.isRequired,
    }),
  };

  constructor(props) {
    super(props);

    const { app, category, text } = parseQueryString(
      this.props.location.search,
    );

    const selectedApp = searchApps.find(a => a.slug === app) || searchApps[0];
    const selectedCategory =
      selectedApp.categories.find(c => c.value === category) ||
      selectedApp.categories[0];

    this.state = {
      app: selectedApp.slug,
      category: selectedCategory.value,
      text: decodeURIComponent(text || ''),
    };
    this.changePath();
  }

  componentDidUpdate() {
    const { text, source } = parseQueryString(this.props.location.search);

    if (source === 'sidebar') {
      this.changeText(decodeURIComponent(text || ''));
    }
  }

  changeApp = app => {
    const selectedApp = searchApps.find(a => a.slug === app) || searchApps[0];
    const selectedCategory = selectedApp.categories[0];

    this.setState(
      {
        app: selectedApp.slug,
        category: selectedCategory.value,
      },
      () => this.changePath(),
    );
  };

  changeCategory = category => {
    this.setState(
      {
        category,
      },
      () => this.changePath(),
    );
  };

  changeText = text => {
    this.setState(
      {
        text,
      },
      () => this.changePath(),
    );
  };

  changePath = () => {
    const encodedText = encodeURIComponent(this.state.text);
    const { app, category } = this.state;
    this.props.history.replace(
      `/search?app=${app}&category=${category}&text=${encodedText}`,
    );
  };

  render() {
    const {
      state: { app, category, text },
      changeApp,
      changeCategory,
      changeText,
    } = this;

    const apps = searchApps.map(a => ({ value: a.slug, label: a.name }));
    const selectedApp = searchApps.find(a => a.slug === app) || searchApps[0];

    const categories = selectedApp.categories.map(c => ({
      value: c.value,
      label: c.name,
    }));

    return (
      <React.Fragment>
        <SearchBar
          type="text"
          value={text}
          onChange={e => changeText(e.target.value)}
          placeholder="Pesquisar..."
        />
        <AdvancedSearch>Pesquisa avançada</AdvancedSearch>
        <CenteredWrapper>
          <SearchOption>Pesquisar em</SearchOption>
          <Dropdown
            options={apps}
            selectedValue={app}
            selectValue={changeApp}
          />
          <SearchOption>por</SearchOption>
          <Dropdown
            options={categories}
            selectedValue={category}
            selectValue={changeCategory}
          />
        </CenteredWrapper>
        {app != null && category != null && text !== '' ? (
          <Query
            query={category === 'annotation' ? SearchAnnotation : SearchSection}
            variables={{
              text,
              category,
              appSlug: app,
              first: 12,
            }}
          >
            {({ data, error, fetchMore }) => {
              const results =
                category === 'annotation'
                  ? data && data.annotationSearch
                  : data && data.sectionSearch;

              const loadMore = () =>
                fetchMore({
                  variables: {
                    after: results.pageInfo.endCursor,
                  },
                  updateQuery: (prev, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prev;
                    if (category === 'annotation') {
                      const newData = {
                        annotationSearch: {
                          ...fetchMoreResult.annotationSearch,
                          edges: [
                            ...prev.annotationSearch.edges,
                            ...fetchMoreResult.annotationSearch.edges,
                          ],
                          pageInfo: {
                            ...fetchMoreResult.annotationSearch.pageInfo,
                            startCursor:
                              prev.annotationSearch.pageInfo.startCursor,
                          },
                        },
                      };
                      return newData;
                    }
                    const newData = {
                      sectionSearch: {
                        ...fetchMoreResult.sectionSearch,
                        edges: [
                          ...prev.sectionSearch.edges,
                          ...fetchMoreResult.sectionSearch.edges,
                        ],
                        pageInfo: {
                          ...fetchMoreResult.sectionSearch.pageInfo,
                          startCursor: prev.sectionSearch.pageInfo.startCursor,
                        },
                      },
                    };
                    return newData;
                  },
                });

              return (
                <React.Fragment>
                  {results && results.edges && results.edges.length > 0 ? (
                    <FlexColWrapper>
                      {results.edges.map(edge => {
                        const { node } = edge;

                        const name =
                          category === 'annotation'
                            ? node.section && node.section.full_name
                            : node.full_name;

                        const content =
                          category === 'annotation'
                            ? node.section && node.section.content.name
                            : node.content.name;

                        const path =
                          category === 'annotation'
                            ? node.section && node.section.path
                            : node.path;

                        return (
                          <SearchResultsCard
                            key={edge.node.id}
                            to={path}
                            slug={app}
                          >
                            <h1>{name}</h1>
                            <h2>{content}</h2>
                            <p>{highlightText(node.text, text)}</p>
                          </SearchResultsCard>
                        );
                      })}
                    </FlexColWrapper>
                  ) : (
                    <CenteredWrapper>
                      <Info>
                        Não foram encontrados resultados para a sua pesquisa
                      </Info>
                    </CenteredWrapper>
                  )}
                  {results &&
                    results.pageInfo.hasNextPage && (
                      <CenteredWrapper>
                        <MoreButton slug={app} onClick={() => loadMore()}>
                          Mais resultados
                        </MoreButton>
                      </CenteredWrapper>
                    )}
                </React.Fragment>
              );
            }}
          </Query>
        ) : (
          <CenteredWrapper>
            <Info>Pesquise no Anotado</Info>
          </CenteredWrapper>
        )}
      </React.Fragment>
    );
  }
}

export default SearchScreen;
