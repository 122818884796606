import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { GetCPCChapter } from 'core/app/cpc/queries';
import { romanize, getDescriptionFromTitle } from 'core/utils/helpers';

import { ChapterTitle, ChapterInfo, Info } from 'views/elements/Texts';
import { CenteredWrapper } from 'views/elements/Wrappers';
import { DotLoader } from 'views/elements/Loaders';

import ArticleCard from '../ArticleCard';

export const Chapter = ({
  data,
  version,
  partNumber,
  partLevel,
  bookNumber,
  bookLevel,
  titleNumber,
  titleLevel,
  selectArticle,
}) => {
  const chapterNumber = data.number;
  const chapterLevel = data.level;
  return (
    <Query
      query={GetCPCChapter}
      variables={{
        version,
        partNumber,
        partLevel,
        bookNumber,
        bookLevel,
        titleNumber,
        titleLevel,
        chapterNumber,
        chapterLevel,
      }}
    >
      {({ loading, error, data }) => {
        if (loading)
          return (
            <CenteredWrapper>
              <DotLoader slug="cpc" />
            </CenteredWrapper>
          );
        if (error)
          return (
            <CenteredWrapper>
              <Info>Capítulo não encontrado</Info>
            </CenteredWrapper>
          );

        const chapter = data.cpcChapter;

        return (
          <React.Fragment>
            <ChapterTitle slug="cpc">
              Capítulo {romanize(chapter.number)}
            </ChapterTitle>
            <ChapterInfo>{getDescriptionFromTitle(chapter.name)}</ChapterInfo>
            {chapter.sections.map(section => (
              <ArticleCard
                key={section.id}
                data={section}
                version={version}
                partNumber={partNumber}
                partLevel={partLevel}
                bookNumber={bookNumber}
                bookLevel={bookLevel}
                titleNumber={titleNumber}
                titleLevel={titleLevel}
                chapterNumber={chapterNumber}
                chapterLevel={chapterLevel}
                selectArticle={selectArticle}
              />
            ))}
          </React.Fragment>
        );
      }}
    </Query>
  );
};

Chapter.propTypes = {
  data: PropTypes.shape({}),
  version: PropTypes.string,
  partNumber: PropTypes.number,
  partLevel: PropTypes.number,
  bookNumber: PropTypes.number,
  bookLevel: PropTypes.number,
  titleNumber: PropTypes.number,
  titleLevel: PropTypes.number,
  selectArticle: PropTypes.func,
};

Chapter.displayName = 'Chapter';

export default React.memo(Chapter);
