import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Field } from 'formik';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';
import { parseQueryString } from 'core/utils/helpers';
import { resetPassword } from 'core/user/api';

import AuthInput from 'views/components/AuthInput';

import emailIcon from 'views/assets/icons/mail.svg';
import { AuthCard } from 'views/elements/Cards';
import { Info } from 'views/elements/Texts';
import { AuthSubmitButton, AuthLink } from 'views/elements/Buttons';
import { AuthForm } from 'views/elements/Inputs';

import {
  MIN_PASSWORD_LENGTH,
  UNMATCHED_PASSWORD_ERROR,
  SHORT_PASSWORD_ERROR,
  EMPTY_PASSWORD_ERROR,
  RESET_PASSWORD_SUCCESS,
} from 'core/constants';

const schema = yup.object().shape({
  pass: yup
    .string()
    .required(EMPTY_PASSWORD_ERROR)
    .min(MIN_PASSWORD_LENGTH, SHORT_PASSWORD_ERROR),
  passConf: yup
    .string()
    .required(EMPTY_PASSWORD_ERROR)
    .oneOf([yup.ref('pass')], UNMATCHED_PASSWORD_ERROR),
});

export class ResetPasswordScreen extends Component {
  static propTypes = {
    location: PropTypes.shape({
      search: PropTypes.string,
    }).isRequired,
  };

  state = {
    success: null,
  };

  handleSubmit = (values, actions) => {
    const token = parseQueryString(this.props.location.search).token || '';
    resetPassword(values.pass, token)
      .then(async res => {
        actions.setSubmitting(false);
        if (res.ok) {
          this.setState({
            success: true,
          });
        } else {
          this.setState({
            success: false,
          });
          toast.error('Não foi possível alterar a senha');
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        this.setState({
          success: false,
        });
        toast.error('Ocorreu um problema');
      });
  };

  render() {
    const {
      state: { success },
      handleSubmit,
    } = this;

    return (
      <AuthCard>
        {success ? (
          <Info>{RESET_PASSWORD_SUCCESS}</Info>
        ) : (
          <Formik
            validationSchema={schema}
            initialValues={{
              pass: '',
              passConf: '',
            }}
            onSubmit={handleSubmit}
            render={({ errors, touched, isSubmitting }) => (
              <AuthForm>
                <Field
                  type="password"
                  name="pass"
                  label="Senha"
                  placeholder="Escreva a sua senha"
                  component={AuthInput}
                />
                <Field
                  type="password"
                  name="passConf"
                  label="Confirmar Senha"
                  placeholder="Confirme a sua senha"
                  component={AuthInput}
                />
                <AuthSubmitButton type="submit" disabled={isSubmitting}>
                  <SVG src={emailIcon} />
                  <p>enviar</p>
                </AuthSubmitButton>
              </AuthForm>
            )}
          />
        )}
        <AuthLink to="/login">Voltar à página de login.</AuthLink>
      </AuthCard>
    );
  }
}

export default ResetPasswordScreen;
