import React from 'react';
import PropTypes from 'prop-types';

import { theme } from 'core/theme';

import Icon from 'views/components/Icon';
import { IconLink } from 'views/elements/Buttons';
import { FlexColWrapper } from 'views/elements/Wrappers';
import { FavouriteButton } from 'views/components/FavouriteButton';

export const SectionCardActions = ({
  section,
  selectSection,
  sectionPath,
  slug,
}) => (
  <FlexColWrapper>
    <IconLink
      slug={slug}
      to={`${sectionPath}/annotations`}
      onClick={() => selectSection(section.number)}
    >
      <Icon
        name="comment"
        width="17"
        height="17"
        color={theme.appColors[slug].primary}
      />
    </IconLink>
    <FavouriteButton section={section} slug={slug} />
    <IconLink
      slug={slug}
      to={`${sectionPath}/new_annotation`}
      onClick={() => selectSection(section.number)}
    >
      <Icon
        name="add_comment"
        width="17"
        height="17"
        color={theme.appColors[slug].primary}
      />
    </IconLink>
  </FlexColWrapper>
);

SectionCardActions.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    number: PropTypes.number,
    favourited: PropTypes.bool,
  }),
  selectSection: PropTypes.func,
  sectionPath: PropTypes.string,
  slug: PropTypes.string,
};
