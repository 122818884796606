import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { GetFamLaw } from 'core/app/fam/queries';

import { CenteredWrapper } from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';
import { Info, LawTitle } from 'views/elements/Texts';

import ArticleCard from '../ArticleCard';

export const Law = ({ lawSlug, selectArticle, selectParagraph }) => (
  <Query
    query={GetFamLaw}
    variables={{
      slug: lawSlug,
    }}
  >
    {({ loading, error, data }) => {
      if (loading)
        return (
          <CenteredWrapper>
            <CircleLoader slug="fam" />
          </CenteredWrapper>
        );
      if (error)
        return (
          <CenteredWrapper>
            <Info>Lei não encontrada</Info>
          </CenteredWrapper>
        );

      const law = data.famLaw;

      return law != null &&
        law.main_sections != null &&
        law.main_sections.length > 0 ? (
        <React.Fragment>
          <LawTitle slug="fam">{law.name}</LawTitle>
          {law.main_sections.map(section => (
            <ArticleCard
              key={section.id}
              data={section}
              lawSlug={lawSlug}
              selectArticle={selectArticle}
              selectParagraph={selectParagraph}
            />
          ))}
        </React.Fragment>
      ) : (
        <CenteredWrapper>
          <Info>Esta lei não tem artigos disponíveis</Info>
        </CenteredWrapper>
      );
    }}
  </Query>
);

Law.propTypes = {
  lawSlug: PropTypes.string,
  selectArticle: PropTypes.func,
  selectParagraph: PropTypes.func,
};

export default Law;
