import React from 'react';
import { Query } from 'react-apollo';
import SVG from 'react-inlinesvg';

import { GetApps } from 'core/app/queries';

import marker from 'views/assets/icons/marker.svg';
import { FlexRowWrapWrapper } from 'views/elements/Wrappers';
import { AppCard, EmptyAppCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

const NoApps = () => (
  <EmptyAppCard>
    <h1>Não existem conteúdos disponíveis</h1>
  </EmptyAppCard>
);

const MoreApps = () => (
  <EmptyAppCard>
    <h1>Mais conteúdos brevemente</h1>
  </EmptyAppCard>
);

const apps = ['cpc', 'fam', 'lms', 'anotado'];
const Loader = () => (
  <FlexRowWrapWrapper>
    {apps.map(app => (
      <EmptyAppCard key={app}>
        <CircleLoader slug={app} />
      </EmptyAppCard>
    ))}
  </FlexRowWrapWrapper>
);

export const AppsScreen = () => (
  <Query query={GetApps}>
    {({ loading, error, data }) => {
      if (loading) return <Loader />;
      if (error || data.apps == null || data.apps.length === 0)
        return <NoApps />;

      const { apps } = data;

      return (
        <FlexRowWrapWrapper>
          {apps.map(app => (
            <AppCard to={`/app/${app.slug}`} key={app.id} slug={app.slug}>
              <h1>{app.name}</h1>
              <h2>{app.description}</h2>
              <h3>VER LIVRO</h3>
              <SVG src={marker} />
            </AppCard>
          ))}
          <MoreApps />
        </FlexRowWrapWrapper>
      );
    }}
  </Query>
);

export default AppsScreen;
