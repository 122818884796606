import gql from 'graphql-tag';

export const GetCPCBook = gql`
  query getCPCBook(
    $version: String!
    $partNumber: Int
    $partLevel: Int
    $bookNumber: Int!
    $bookLevel: Int!
  ) {
    cpcBook(
      version: $version
      partNumber: $partNumber
      partLevel: $partLevel
      bookNumber: $bookNumber
      bookLevel: $bookLevel
    ) {
      category
      id
      info
      level
      name
      number
      position
      text
      sections {
        category
        id
        info
        level
        name
        number
        position
        text
      }
    }
  }
`;

export const GetCPCTitle = gql`
  query getCPCTitle(
    $version: String!
    $partNumber: Int
    $partLevel: Int
    $bookNumber: Int!
    $bookLevel: Int!
    $titleNumber: Int!
    $titleLevel: Int!
  ) {
    cpcTitle(
      version: $version
      partNumber: $partNumber
      partLevel: $partLevel
      bookNumber: $bookNumber
      bookLevel: $bookLevel
      titleNumber: $titleNumber
      titleLevel: $titleLevel
    ) {
      category
      id
      info
      level
      name
      number
      position
      text
      sections {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
      }
    }
  }
`;

export const GetCPCChapter = gql`
  query getCPCChapter(
    $version: String!
    $partNumber: Int
    $partLevel: Int
    $bookNumber: Int!
    $bookLevel: Int!
    $titleNumber: Int!
    $titleLevel: Int!
    $chapterNumber: Int!
    $chapterLevel: Int!
  ) {
    cpcChapter(
      version: $version
      partNumber: $partNumber
      partLevel: $partLevel
      bookNumber: $bookNumber
      bookLevel: $bookLevel
      titleNumber: $titleNumber
      titleLevel: $titleLevel
      chapterNumber: $chapterNumber
      chapterLevel: $chapterLevel
    ) {
      category
      id
      info
      level
      name
      number
      position
      text
      sections {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
      }
    }
  }
`;

export const GetCPCArticle = gql`
  query getCPCArticle($version: String!, $articleNumber: Int!) {
    cpcArticle(version: $version, articleNumber: $articleNumber) {
      category
      id
      number
      text
      favourited
      premium_annotations {
        id
        text
      }
      private_annotations {
        id
        text
      }
      collaborative_annotations {
        id
        text
      }
    }
  }
`;
