import React from 'react';
import PropTypes from 'prop-types';
import { romanize, getDescriptionFromTitle } from 'core/utils/helpers';

import { BookCard as Card } from 'views/elements/Cards';

export const BookCard = ({ data, selectedCode, selectedPart, selectBook }) => {
  const path = selectedPart
    ? `/app/cpc/code/${selectedCode}/part/${selectedPart}/book/${data.number}`
    : `/app/cpc/code/${selectedCode}/book/${data.number}`;

  return (
    <Card to={path}>
      <h1>Livro {romanize(data.number)}</h1>
      <h2>{getDescriptionFromTitle(data.name)}</h2>
      <h3>{data.text}</h3>
      <h4>{data.info}</h4>
    </Card>
  );
};

BookCard.propTypes = {
  data: PropTypes.shape({}),
  selectedCode: PropTypes.string,
  selectedPart: PropTypes.number,
  selectBook: PropTypes.func,
};

export default BookCard;
