import React, { Component } from 'react';
import { Formik, Field } from 'formik';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';
import { signUp, loginOAuth } from 'core/user/api';

import { UserConsumer } from 'views/providers/user';
import Facebook from 'views/components/Facebook';
import Google from 'views/components/Google';
import AuthInput from 'views/components/AuthInput';
import AuthSelect from 'views/components/AuthSelect';

import emailIcon from 'views/assets/icons/mail.svg';
import { AuthCard } from 'views/elements/Cards';
import { Info } from 'views/elements/Texts';
import { AuthSubmitButton, AuthLink } from 'views/elements/Buttons';
import { TextSeparator } from 'views/elements/Separators';
import { AuthForm } from 'views/elements/Inputs';

import {
  MIN_PASSWORD_LENGTH,
  EMPTY_NAME_ERROR,
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERROR,
  EMPTY_PASSWORD_ERROR,
  EMPTY_CONFIRM_PASSWORD_ERROR,
  UNMATCHED_PASSWORD_ERROR,
  SHORT_PASSWORD_ERROR,
  INVALID_JOB_ERROR,
  SIGNUP_SUCCESS,
  JOBS,
} from 'core/constants';

const schema = yup.object().shape({
  name: yup.string().required(EMPTY_NAME_ERROR),
  job: yup.string().oneOf(JOBS, INVALID_JOB_ERROR),
  email: yup
    .string()
    .email(INVALID_EMAIL_ERROR)
    .required(EMPTY_EMAIL_ERROR),
  pass: yup
    .string()
    .required(EMPTY_PASSWORD_ERROR)
    .min(MIN_PASSWORD_LENGTH, SHORT_PASSWORD_ERROR),
  passConf: yup
    .string()
    .required(EMPTY_CONFIRM_PASSWORD_ERROR)
    .oneOf([yup.ref('pass')], UNMATCHED_PASSWORD_ERROR),
});

export class SignUpScreen extends Component {
  state = {
    success: null,
  };

  setError = message => {
    toast.error(message);
  };

  handleOAuthLogin = (oauthUser, loginUser) => {
    loginOAuth(oauthUser)
      .then(async res => {
        if (res.ok) {
          const user = await res.json();
          const token = `Bearer ${user.jwt}`;
          loginUser(user, token);
        } else {
          toast.error('Não foi possível efetuar o registo');
        }
      })
      .catch(() => {
        toast.error('Ocorreu um problema');
      });
  };

  handleSubmit = (values, actions) => {
    signUp(values.name, values.email, values.job, values.pass)
      .then(async res => {
        actions.setSubmitting(false);
        if (res.ok) {
          this.setState({
            success: true,
          });
        } else {
          this.setState({
            success: false,
          });
          toast.error('Não foi possível efetuar o registo');
        }
      })
      .catch(() => {
        actions.setSubmitting(false);
        this.setState({
          success: false,
        });
        toast.error('Ocorreu um problema');
      });
  };

  render() {
    const {
      state: { success },
      setError,
      handleSubmit,
      handleOAuthLogin,
    } = this;

    return (
      <AuthCard>
        {success ? (
          <React.Fragment>
            <Info>{SIGNUP_SUCCESS}</Info>
            <AuthLink to="/login">Voltar à página de login.</AuthLink>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <UserConsumer>
              {({ actions: { loginUser } }) => (
                <React.Fragment>
                  <Facebook
                    loginFacebook={handleOAuthLogin}
                    setError={setError}
                    loginUser={loginUser}
                  />
                  <Google
                    loginGoogle={handleOAuthLogin}
                    setError={setError}
                    loginUser={loginUser}
                  />
                </React.Fragment>
              )}
            </UserConsumer>
            <TextSeparator>OU</TextSeparator>
            <Formik
              validationSchema={schema}
              initialValues={{
                name: '',
                job: JOBS[0],
                email: '',
                pass: '',
                passConf: '',
              }}
              onSubmit={handleSubmit}
              render={({ errors, touched, isSubmitting }) => (
                <AuthForm>
                  <Field
                    type="text"
                    name="name"
                    label="Nome"
                    placeholder="Escreva o seu nome"
                    component={AuthInput}
                  />
                  <Field
                    name="job"
                    options={JOBS}
                    label="Profissão"
                    placeholder="Escolha a sua profissão"
                    component={AuthSelect}
                  />
                  <Field
                    name="email"
                    label="Email"
                    placeholder="Escreva o seu email"
                    component={AuthInput}
                  />
                  <Field
                    type="password"
                    name="pass"
                    label="Senha"
                    placeholder="Escreva a sua senha"
                    component={AuthInput}
                  />
                  <Field
                    type="password"
                    name="passConf"
                    label="Confirmar Senha"
                    placeholder="Confirme a sua senha"
                    component={AuthInput}
                  />
                  <AuthSubmitButton type="submit" disabled={isSubmitting}>
                    <SVG src={emailIcon} />
                    <p>registar</p>
                  </AuthSubmitButton>
                </AuthForm>
              )}
            />
          </React.Fragment>
        )}
      </AuthCard>
    );
  }
}

export default SignUpScreen;
