import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { toast } from 'core/utils/toast';
import { MyBundle } from 'core/bundle/queries';
import { InviteAuthor } from 'core/bundle/mutations';
import { EMPTY_EMAIL_ERROR, INVALID_EMAIL_ERROR } from 'core/constants';

import AuthInput from 'views/components/AuthInput';

import { DotLoader } from 'views/elements/Loaders';
import { CenteredWrapper, RightAlignedWrapper } from 'views/elements/Wrappers';
import { Table, Row, CellTitle } from 'views/elements/Tables';
import { SaveButton } from 'views/elements/Buttons';

import { AuthorRow } from './AuthorRow';

const schema = yup.object().shape({
  email: yup
    .string()
    .email(INVALID_EMAIL_ERROR)
    .required(EMPTY_EMAIL_ERROR),
});

export const Authors = ({ bundleId, owner, ownerId, authors, invites }) => (
  <React.Fragment>
    <Table>
      <Row>
        <CellTitle>Nome</CellTitle>
        <CellTitle>Estado</CellTitle>
        <CellTitle>Ações</CellTitle>
      </Row>
      {authors &&
        authors.map(author => (
          <AuthorRow
            owner={owner}
            ownerId={ownerId}
            authorId={author.author.id}
            authorName={author.author.name}
            inviteStatus={author.status}
            inviteType="author"
            inviteId={author.id}
            invited={author.invited}
            bundleId={bundleId}
            key={author.id}
          />
        ))}
      {invites &&
        invites.map(invite => (
          <AuthorRow
            owner={owner}
            ownerId={ownerId}
            authorId={null}
            authorName={invite.email}
            inviteStatus="invited"
            inviteType="invite"
            inviteId={invite.id}
            invited={false}
            bundleId={bundleId}
            key={invite.id}
          />
        ))}
    </Table>
    {owner && (
      <Mutation
        mutation={InviteAuthor}
        refetchQueries={[
          {
            query: MyBundle,
            variables: {
              bundle_id: bundleId,
            },
          },
        ]}
        onCompleted={() => toast.success('Convite enviado com successo')}
        onError={() => toast.error('Não foi possível enviar o convite')}
      >
        {(mutate, { loading }) =>
          loading ? (
            <CenteredWrapper>
              <DotLoader />
            </CenteredWrapper>
          ) : (
            <Formik
              validationSchema={schema}
              initialValues={{
                email: '',
              }}
              onSubmit={(values, actions) => {
                mutate({
                  variables: {
                    data: {
                      bundle_id: bundleId,
                      email: values.email,
                    },
                  },
                });
              }}
              render={({
                errors,
                touched,
                isSubmitting,
                isValidating,
                isValid,
              }) => (
                <Form>
                  <Field
                    type="text"
                    name="email"
                    placeholder="Email"
                    component={AuthInput}
                  />
                  <RightAlignedWrapper>
                    <SaveButton
                      type="submit"
                      disabled={isSubmitting || isValidating || !isValid}
                    >
                      Convidar
                    </SaveButton>
                  </RightAlignedWrapper>
                </Form>
              )}
            />
          )
        }
      </Mutation>
    )}
  </React.Fragment>
);

Authors.propTypes = {
  bundleId: PropTypes.string,
  owner: PropTypes.bool,
  ownerId: PropTypes.string,
  authors: PropTypes.arrayOf(PropTypes.shape({})),
  invites: PropTypes.arrayOf(PropTypes.shape({})),
};
