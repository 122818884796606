import React from 'react';
import SVG from 'react-inlinesvg';
import { Switch, Route } from 'react-router-dom';

import logo from 'views/assets/logo.svg';

import { NavbarWrapper, FlexRowWrapper } from 'views/elements/Wrappers';
import { SidebarButton } from 'views/elements/Buttons';

export const Navbar = () => (
  <NavbarWrapper>
    <SVG src={logo} />
    <FlexRowWrapper>
      <Switch>
        <Route
          exact
          path="/signup"
          render={props => (
            <React.Fragment>
              <SidebarButton to="/signup" selected>
                registar
              </SidebarButton>
              <SidebarButton to="/login">login</SidebarButton>
            </React.Fragment>
          )}
        />
        <Route
          exact
          path={['/login', '/login/email']}
          render={props => (
            <React.Fragment>
              <SidebarButton to="/signup">registar</SidebarButton>
              <SidebarButton to="/login" selected>
                login
              </SidebarButton>
            </React.Fragment>
          )}
        />
        <Route
          path="*"
          render={props => (
            <React.Fragment>
              <SidebarButton to="/signup">registar</SidebarButton>
              <SidebarButton to="/login">login</SidebarButton>
            </React.Fragment>
          )}
        />
      </Switch>
    </FlexRowWrapper>
  </NavbarWrapper>
);
