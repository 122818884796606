import React, { Component } from 'react';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';
import { Mutation } from 'react-apollo';

import { toast } from 'core/utils/toast';
import { UpdateUser } from 'core/user/mutations';
import { capitalize } from 'core/utils/helpers';

import { UserConsumer } from 'views/providers/user';
import AuthInput from 'views/components/AuthInput';
import AuthSelect from 'views/components/AuthSelect';
import ImageInput from 'views/components/ImageInput';

import { AuthCard } from 'views/elements/Cards';
import {
  FlexColWrapper,
  FlexRowWrapper,
  FontSizeEditWrapper,
} from 'views/elements/Wrappers';
import { AuthButton } from 'views/elements/Buttons';
import { CardTitle } from 'views/elements/Texts';

import {
  EMPTY_NAME_ERROR,
  EMPTY_EMAIL_ERROR,
  INVALID_EMAIL_ERROR,
  INVALID_JOB_ERROR,
  JOBS,
  UPDATE_PROFILE_SUCCESS,
  FONT_SIZES,
} from 'core/constants';

const omit = require('lodash/omit');

const schema = yup.object().shape({
  picture: yup.mixed(),
  name: yup.string().required(EMPTY_NAME_ERROR),
  job: yup.string().oneOf(JOBS, INVALID_JOB_ERROR),
  email: yup
    .string()
    .email(INVALID_EMAIL_ERROR)
    .required(EMPTY_EMAIL_ERROR),
  about: yup.string(),
});

export class ProfileScreen extends Component {
  setFontSizeIndex = (currentIndex, value, update) => {
    const newIndex = currentIndex + value;

    if (newIndex >= 0 && newIndex < FONT_SIZES.length) {
      update(newIndex);
    }
  };

  handleSubmit = (values, actions, mutate, update) => {
    actions.setSubmitting(false);
    const data = {
      name: values.name,
      email: values.email,
      about: values.about,
      job: values.job.toLowerCase(),
      picture: values.picture,
    };
    mutate({
      variables: {
        data: omit(data, data.picture.size ? '' : 'picture'),
      },
    })
      .then(({ data: { updateUser } }) => {
        update(updateUser);
        toast.success(UPDATE_PROFILE_SUCCESS);
      })
      .catch(e => {
        toast.error('Não foi possível atualizar o perfil');
      });
  };

  render() {
    const { setFontSizeIndex, handleSubmit } = this;

    return (
      <UserConsumer>
        {({
          state: { user, fontSizeIndex },
          actions: { logoutUser, updateUser, updateFontSize },
        }) => (
          <FlexRowWrapper>
            <AuthCard>
              <CardTitle>Informação de Conta</CardTitle>
              <Mutation mutation={UpdateUser}>
                {mutate => (
                  <Formik
                    validationSchema={schema}
                    initialValues={{
                      picture: new File([''], ''),
                      name: user.name,
                      job: (user.job && capitalize(user.job)) || JOBS[0],
                      email: user.email,
                      about: user.about || '',
                    }}
                    onSubmit={(values, actions) =>
                      handleSubmit(values, actions, mutate, updateUser)
                    }
                    render={({
                      errors,
                      touched,
                      isSubmitting,
                      setFieldValue,
                    }) => (
                      <Form>
                        <Field
                          name="picture"
                          label="Foto"
                          placeholder="Escolha uma foto"
                          component={ImageInput}
                          setFieldValue={setFieldValue}
                          src={user.picture}
                        />
                        <Field
                          type="text"
                          name="name"
                          label="Nome"
                          placeholder="Escreva o seu nome"
                          component={AuthInput}
                        />
                        <Field
                          name="job"
                          options={JOBS}
                          label="Profissão"
                          placeholder="Escolha a sua profissão"
                          component={AuthSelect}
                        />
                        <Field
                          name="email"
                          placeholder="EMAIL"
                          component={AuthInput}
                        />
                        <Field
                          type="text"
                          name="about"
                          label="Biografia"
                          placeholder="Escreva a sua biografia"
                          component={AuthInput}
                        />
                        <AuthButton type="submit" disabled={isSubmitting}>
                          guardar
                        </AuthButton>
                      </Form>
                    )}
                  />
                )}
              </Mutation>
            </AuthCard>
            <FlexColWrapper>
              <AuthCard>
                <CardTitle>Tamanho de Letra</CardTitle>
                <FontSizeEditWrapper>
                  <button
                    disabled={fontSizeIndex === 0}
                    onClick={() =>
                      setFontSizeIndex(fontSizeIndex, -1, updateFontSize)
                    }
                  >
                    -
                  </button>
                  <span>{FONT_SIZES[fontSizeIndex]}</span>
                  <button
                    disabled={fontSizeIndex === FONT_SIZES.length - 1}
                    onClick={() =>
                      setFontSizeIndex(fontSizeIndex, 1, updateFontSize)
                    }
                  >
                    +
                  </button>
                </FontSizeEditWrapper>
              </AuthCard>
              <AuthCard>
                <CardTitle>Opções de Conta</CardTitle>
                <AuthButton onClick={() => logoutUser()}>logout</AuthButton>
              </AuthCard>
            </FlexColWrapper>
          </FlexRowWrapper>
        )}
      </UserConsumer>
    );
  }
}

export default ProfileScreen;
