import gql from 'graphql-tag';

export const GetSections = gql`
  query getSections($section_id: ID!) {
    sections(section_id: $section_id) {
      category
      id
      info
      name
      number
      level
      position
      text
    }
  }
`;

export const GetSection = gql`
  query getSection($id: ID!) {
    section(id: $id) {
      category
      id
      number
      text
      version
      favourited
      premium_annotations {
        category
        id
        text
      }
      private_annotations {
        category
        id
        text
      }
    }
  }
`;

export const SearchSection = gql`
  query sectionSearch(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $text: String!
    $category: String!
    $appSlug: String!
  ) {
    sectionSearch(
      first: $first
      after: $after
      last: $last
      before: $before
      text: $text
      category: $category
      appSlug: $appSlug
    ) {
      edges {
        cursor
        node {
          id
          text
          path
          full_name
          content {
            id
            name
          }
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total_count
    }
  }
`;

export const AnnotatableSectionsSearch = gql`
  query annotatableSectionsSearch(
    $first: Int
    $after: String
    $last: Int
    $before: String
    $text: String!
  ) {
    annotatableSectionsSearch(
      first: $first
      after: $after
      last: $last
      before: $before
      text: $text
    ) {
      edges {
        cursor
        node {
          id
          name
          text
        }
      }
      pageInfo {
        endCursor
        hasNextPage
        hasPreviousPage
        startCursor
      }
      total_count
    }
  }
`;
