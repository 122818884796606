import gql from 'graphql-tag';

export const CreatePrivateAnnotation = gql`
  mutation createPrivateAnnotation($data: CreatePrivateAnnotationInput!) {
    createPrivateAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;

export const EditPrivateAnnotation = gql`
  mutation editPrivateAnnotation($data: EditPrivateAnnotationInput!) {
    editPrivateAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;

export const DeletePrivateAnnotation = gql`
  mutation deletePrivateAnnotation($data: DeletePrivateAnnotationInput!) {
    deletePrivateAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;

export const CreateCollaborativeAnnotation = gql`
  mutation createCollaborativeAnnotation(
    $data: CreateCollaborativeAnnotationInput!
  ) {
    createCollaborativeAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;

export const EditCollaborativeAnnotation = gql`
  mutation editCollaborativeAnnotation(
    $data: EditCollaborativeAnnotationInput!
  ) {
    editCollaborativeAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;

export const DeleteCollaborativeAnnotation = gql`
  mutation deleteCollaborativeAnnotation(
    $data: DeleteCollaborativeAnnotationInput!
  ) {
    deleteCollaborativeAnnotation(input: $data) {
      id
      section_id
      text
    }
  }
`;
