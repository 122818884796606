import gql from 'graphql-tag';

export const CreateFavourite = gql`
  mutation createFavourite($data: CreateFavouriteInput!) {
    createFavourite(input: $data) {
      id
      section_id
      section {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
      }
    }
  }
`;

export const DeleteFavourite = gql`
  mutation deleteFavourite($data: DeleteFavouriteInput!) {
    deleteFavourite(input: $data) {
      id
      section_id
      section {
        category
        id
        info
        level
        name
        number
        position
        text
        favourited
      }
    }
  }
`;
