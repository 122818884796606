import React, { Component } from 'react';
import PropTypes from 'prop-types';
import GoogleLogin from 'react-google-login';
import SVG from 'react-inlinesvg';
import * as yup from 'yup';

import { GOOGLE_PERMISSIONS_ERROR } from 'core/constants';

import logo from 'views/assets/icons/google.svg';
import { SocialButton } from 'views/elements/Buttons';

const APP_ID = process.env.REACT_APP_GOOGLE_APP_ID;

const schema = yup.object().shape({
  profileObj: yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    googleId: yup.string().required(),
    name: yup.string().required(),
    imageUrl: yup.string().required(),
  }),
  accessToken: yup.string().required(),
});

class Google extends Component {
  static propTypes = {
    loginGoogle: PropTypes.func.isRequired,
    setError: PropTypes.func.isRequired,
    loginUser: PropTypes.func.isRequired,
  };

  handleGoogleResponse = (response, loginGoogle, setError, loginUser) => {
    schema.isValid(response).then(valid => {
      if (valid) {
        const user = {
          email: response.profileObj.email,
          uid: response.profileObj.googleId,
          access_token: response.accessToken,
          provider: 'Google',
          name: response.profileObj.name,
          picture: response.profileObj.imageUrl,
        };
        loginGoogle(user, loginUser);
      } else {
        setError(GOOGLE_PERMISSIONS_ERROR);
      }
    });
  };

  render() {
    const {
      props: { loginGoogle, setError, loginUser },
      handleGoogleResponse,
    } = this;

    const handleCallback = response => {
      handleGoogleResponse(response, loginGoogle, setError, loginUser);
    };

    return (
      <GoogleLogin
        clientId={APP_ID}
        buttonText=""
        onSuccess={handleCallback}
        onFailure={() => setError(GOOGLE_PERMISSIONS_ERROR)}
        style={{}}
      >
        <SocialButton>
          <SVG src={logo} />
          <p>Continuar com o Google</p>
        </SocialButton>
      </GoogleLogin>
    );
  }
}

export default Google;
