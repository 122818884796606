import React from 'react';
import PropTypes from 'prop-types';

import { FlexRowWrapWrapper } from 'views/elements/Wrappers';

import Part from '../Part';
import BookCard from '../BookCard';

export const Code = ({ data, selectedCode, selectedPart, selectBook }) => {
  const part = data.main_sections.find(
    section => section.number === selectedPart && section.category === 'part',
  );

  return (
    <FlexRowWrapWrapper>
      {part != null ? (
        <Part
          part={part}
          selectedCode={selectedCode}
          selectedPart={selectedPart}
        />
      ) : (
        data.main_sections.map(section => (
          <BookCard
            key={section.id}
            data={section}
            selectedCode={selectedCode}
            selectedPart={selectedPart}
            selectBook={selectBook}
          />
        ))
      )}
    </FlexRowWrapWrapper>
  );
};

Code.propTypes = {
  data: PropTypes.shape({}),
  selectedCode: PropTypes.string,
  selectedPart: PropTypes.number,
  selectBook: PropTypes.func,
};

export default Code;
