import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { GetCPCTitle } from 'core/app/cpc/queries';

import { TitleTitle, TitleInfo, Info } from 'views/elements/Texts';
import { CenteredWrapper } from 'views/elements/Wrappers';
import { DotLoader } from 'views/elements/Loaders';

import ArticleCard from '../ArticleCard';
import Chapter from '../Chapter';

const sections = {
  article: ArticleCard,
  chapter: Chapter,
};

export class Title extends React.PureComponent {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
  }

  componentDidMount() {
    const { titleNumber, setTitleRef, titleRefs } = this.props;

    if (this.myRef != null && titleRefs[titleNumber] == null) {
      setTitleRef(titleNumber, this.myRef);
    }
  }

  render() {
    const {
      props: {
        version,
        partNumber,
        partLevel,
        bookNumber,
        bookLevel,
        titleNumber,
        titleLevel,
        selectArticle,
      },
      myRef,
    } = this;

    return (
      <Query
        query={GetCPCTitle}
        variables={{
          version,
          partNumber,
          partLevel,
          bookNumber,
          bookLevel,
          titleNumber,
          titleLevel,
        }}
      >
        {({ loading, error, data }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <DotLoader slug="cpc" />
              </CenteredWrapper>
            );
          if (error)
            return (
              <CenteredWrapper>
                <Info>Título não encontrado</Info>
              </CenteredWrapper>
            );

          const title = data.cpcTitle;

          return title != null ? (
            <div ref={myRef}>
              <TitleTitle slug="cpc">{title.name}</TitleTitle>
              <TitleInfo>{title.text}</TitleInfo>
              {title.sections.map(section => {
                const Section = sections[section.category];
                return (
                  <Section
                    key={section.id}
                    data={section}
                    version={version}
                    partNumber={partNumber}
                    partLevel={partLevel}
                    bookNumber={bookNumber}
                    bookLevel={bookLevel}
                    titleNumber={title.number}
                    titleLevel={title.level}
                    selectArticle={selectArticle}
                  />
                );
              })}
            </div>
          ) : (
            <CenteredWrapper>
              <Info>Título não encontrado</Info>
            </CenteredWrapper>
          );
        }}
      </Query>
    );
  }
}

Title.propTypes = {
  version: PropTypes.string,
  partNumber: PropTypes.number,
  partLevel: PropTypes.number,
  bookNumber: PropTypes.number,
  bookLevel: PropTypes.number,
  titleNumber: PropTypes.number,
  titleLevel: PropTypes.number,
  selectArticle: PropTypes.func,
  setTitleRef: PropTypes.func,
  titleRefs: PropTypes.shape({}),
};

export default Title;
