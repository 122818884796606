import React from 'react';
import PropTypes from 'prop-types';

import { GetLMSPoint } from 'core/app/lms/queries';
import { romanize } from 'core/utils/helpers';

import { Section } from 'views/components/Section';

export const Point = props => (
  <Section
    {...props}
    slug="lms"
    query={GetLMSPoint}
    variables={{
      articleNumber: props.articleNumber,
      paragraphNumber: props.paragraphNumber,
      pointNumber: props.pointNumber,
    }}
    sectionKey="lmsPoint"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    }${
      props.paragraphNumber > 0
        ? ` - Parágrafo ${props.paragraphNumber}${
            props.paragraphNumber < 10 ? 'º' : ''
          }`
        : ''
    } - Inciso ${romanize(props.pointNumber)}`}
  />
);

Point.propTypes = {
  articleNumber: PropTypes.number,
  paragraphNumber: PropTypes.number,
  pointNumber: PropTypes.number,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Point;
