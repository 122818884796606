import AccountConfirmationScreen from './AccountConfirmation';
import AppScreen from './App';
import AppsScreen from './Apps';
import CollaborativeMarketScreen from './CollaborativeMarket';
import LoginScreen from './Login';
import MyBundleScreen from './MyBundle';
import MyBundlesScreen from './MyBundles';
import NewBundleScreen from './NewBundle';
import NotFoundScreen from './NotFound';
import PremiumMarketScreen from './PremiumMarket';
import ProfileScreen from './Profile';
import RecoverPasswordScreen from './RecoverPassword';
import RedeemCodeScreen from './RedeemCode';
import ResendConfirmationScreen from './ResendConfirmation';
import ResetPasswordScreen from './ResetPassword';
import SearchScreen from './Search';
import SignUpScreen from './SignUp';

export const AccountConfirmation = AccountConfirmationScreen;
export const App = AppScreen;
export const Apps = AppsScreen;
export const CollaborativeMarket = CollaborativeMarketScreen;
export const Login = LoginScreen;
export const MyBundle = MyBundleScreen;
export const MyBundles = MyBundlesScreen;
export const NewBundle = NewBundleScreen;
export const NotFound = NotFoundScreen;
export const PremiumMarket = PremiumMarketScreen;
export const Profile = ProfileScreen;
export const RecoverPassword = RecoverPasswordScreen;
export const RedeemCode = RedeemCodeScreen;
export const ResendConfirmation = ResendConfirmationScreen;
export const ResetPassword = ResetPasswordScreen;
export const Search = SearchScreen;
export const SignUp = SignUpScreen;
