import styled from 'styled-components';
import { Form } from 'formik';
import searchIcon from 'views/assets/icons/search.svg';

export const TextArea = styled.textarea`
  width: 100%;
  font-family: 'Lora', sans-serif;
  font-size: 1.8rem;
  line-height: 2.8rem;
`;

export const SearchBar = styled.input`
  width: 100%;
  height: 5rem;
  font-size: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 6px;
  background-color: white;
  background-image: url(${searchIcon});
  background-position: 1.5rem 1.5rem;
  background-size: 2rem 2rem;
  background-repeat: no-repeat;
  padding: 1rem 1rem 1rem 5rem;
`;

export const SidebarSearchBar = styled.input`
  width: 100%;
  height: 4rem;
  font-size: 1.2rem;
  font-family: 'Source Sans Pro', sans-serif;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  background-image: url(${searchIcon});
  background-position: 1rem 1.5rem;
  background-size: 1rem 1rem;
  background-repeat: no-repeat;
  padding: 1rem 1rem 1rem 3rem;
  margin-bottom: 5rem;
`;

export const InputWrapper = styled.div`
  width: 50rem;
  height: 4.5rem;
  background-color: white;
  padding: 1rem;
  margin: 1rem;
  border: 1px solid ${({ theme }) => theme.colors.backgroundPrimary};
  border-radius: 6px;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: 'Open Sans', sans-serif;
`;

export const TextInput = styled.input`
  width: 100%;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-size: 2rem;
  font-weight: 300;
  font-family: 'Open Sans', sans-serif;
`;

export const Label = styled.label`
  font-size: 2rem;
  font-family: 'Source Sans Pro', sans-serif;
  text-transform: uppercase;
  margin: 0.5rem 0;
  height: 100%;
  color: ${({ theme }) => theme.appColors.anotado.primary};
  font-weight: 400;
`;

export const AuthForm = styled(Form)`
  width: 35rem;
  display: flex;
  flex-direction: column;
`;

export const AuthInput = styled.input`
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1.4rem;
  height: 100%;
  color: ${({ theme, active }) =>
    active ? theme.appColors.anotado.primary : theme.colors.primary};
  letter-spacing: -0.02;
  margin-bottom: 0.2rem;
`;

export const AuthSelect = styled.select`
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 1.4rem;
  height: 100%;
  color: ${({ theme, active }) =>
    active ? theme.appColors.anotado.primary : theme.colors.primary};
  letter-spacing: -0.02;
  margin-bottom: 0.2rem;

  option {
    font-size: 1.4rem;
    height: 100%;
    color: ${({ theme }) => theme.colors.primary};
    letter-spacing: -0.02;
    margin-bottom: 0.2rem;

    &:hover {
      color: ${({ theme }) => theme.appColors.anotado.primary};
    }
  }
`;

export const Checkbox = styled.label`
  font-family: 'NB International Regular Webfont', sans-serif;
  font-size: 1.4rem;
  display: flex;
  position: relative;
  height: 2rem;
  padding-left: 2.5rem;
  cursor: pointer;
  align-items: center;

  margin: 1rem 0 0 2rem;

  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  span {
    position: absolute;
    top: 0;
    left: 0;
    height: 2rem;
    width: 2rem;
    background-color: #eee;
  }

  &:hover input ~ span {
    background-color: #ccc;
  }

  input:checked ~ span {
    background-color: ${({ theme }) => theme.appColors.anotado.primary};
  }

  span:after {
    content: '';
    position: absolute;
    display: none;
  }

  & input:checked ~ span:after {
    display: block;
  }

  span:after {
    left: 0.6rem;
    top: 0.2rem;
    width: 0.5rem;
    height: 1rem;
    border: solid white;
    border-width: 0 0.3rem 0.3rem 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
`;
