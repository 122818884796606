import React from 'react';
import PropTypes from 'prop-types';

import { ArticleCard as Card } from 'views/elements/Cards';
import { FlexColWrapper, MarginLeftWrapper } from 'views/elements/Wrappers';
import { ArticleTitleLink } from 'views/elements/Texts';
import { SectionCardActions } from 'views/components/SectionCardActions';

import ParagraphCard from '../ParagraphCard';

export const ArticleCard = ({
  data,
  lawSlug,
  selectArticle,
  selectParagraph,
}) => {
  const path =
    data.category === 'article'
      ? `/app/fam/law/${lawSlug}/article/${data.number}`
      : `/app/fam/law/${lawSlug}/article/${data.number}/item/${data.info}`;

  return (
    <React.Fragment>
      <Card slug="fam">
        <SectionCardActions
          section={data}
          selectSection={selectArticle}
          sectionPath={path}
          slug="fam"
        />
        <FlexColWrapper>
          <ArticleTitleLink
            slug="fam"
            to={path}
            onClick={() => selectArticle(data.number)}
          >
            ARTIGO {data.number}
            {data.number < 10 ? 'º' : ''}
            {data.category === 'subarticle' ? `-${data.info}` : ''}
          </ArticleTitleLink>
          <p>{data.text}</p>
        </FlexColWrapper>
      </Card>
      {data.paragraphs.length > 0 ? (
        <MarginLeftWrapper level={1}>
          {data.paragraphs.map(s => (
            <ParagraphCard
              key={s.id}
              data={s}
              articleNumber={data.number}
              articlePath={path}
              selectParagraph={paragraphNumber =>
                selectParagraph(data.number, paragraphNumber)
              }
            />
          ))}
        </MarginLeftWrapper>
      ) : null}
    </React.Fragment>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.shape({}),
  lawSlug: PropTypes.string,
  selectArticle: PropTypes.func,
  selectParagraph: PropTypes.func,
};

ArticleCard.displayName = 'ArticleCard';

export default React.memo(ArticleCard);
