import React from 'react';
import PropTypes from 'prop-types';

import { GetFamParagraph } from 'core/app/fam/queries';

import { Section } from 'views/components/Section';

export const Paragraph = props => (
  <Section
    {...props}
    slug="fam"
    query={GetFamParagraph}
    variables={{
      slug: props.lawSlug,
      articleNumber: props.articleNumber,
      paragraphNumber: props.paragraphNumber,
    }}
    sectionKey="famParagraph"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    } - Parágrafo ${props.paragraphNumber}${
      props.paragraphNumber < 10 ? 'º' : ''
    }`}
  />
);

Paragraph.propTypes = {
  lawSlug: PropTypes.string,
  articleNumber: PropTypes.number,
  paragraphNumber: PropTypes.number,
};

export default Paragraph;
