import React from 'react';
import PropTypes from 'prop-types';
import { Router as AppRouter, Switch } from 'react-router-dom';

import { UserProvider, UserConsumer } from 'views/providers/user';
import { Navbar } from 'views/components/Navbar';
import {
  AppWrapper,
  MainWrapper,
  FlexRowWrapper,
  AuthWrapper,
} from 'views/elements/Wrappers';
import { Sidebar } from 'views/components/Sidebar';
import { routes } from './routes';

const Router = ({ history }) => (
  <div>
    <AppRouter history={history}>
      <UserProvider history={history}>
        <UserConsumer>
          {({ state, actions }) => {
            const isLoggedIn = state.user != null;

            actions.setFontSize();
            return (
              <AppWrapper>
                {isLoggedIn ? (
                  <React.Fragment>
                    <Sidebar history={history} />
                    <MainWrapper>
                      <Switch>{routes(isLoggedIn)}</Switch>
                    </MainWrapper>
                  </React.Fragment>
                ) : (
                  <FlexRowWrapper>
                    <Navbar />
                    <AuthWrapper>
                      <Switch>{routes(isLoggedIn)}</Switch>
                    </AuthWrapper>
                  </FlexRowWrapper>
                )}
              </AppWrapper>
            );
          }}
        </UserConsumer>
      </UserProvider>
    </AppRouter>
  </div>
);

Router.propTypes = {
  history: PropTypes.shape().isRequired,
};

export default Router;
