import React from 'react';
import { Field } from 'formik';

import FormWizard from 'views/components/Wizard';

export const Text = () => (
  <FormWizard.Page>
    <h1>Texto da anotação</h1>
    <Field
      type="text"
      name="text"
      label="Texto"
      component="textarea"
      style={{ border: '2px solid black' }}
    />
  </FormWizard.Page>
);
