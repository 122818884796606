import React from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';

import { MyBundle } from 'core/bundle/queries';
import { CreateCollaborativeAnnotation } from 'core/annotation/mutations';

import FormWizard from 'views/components/Wizard';

import { Text } from './Text';
import { Section } from './Section';

const schema1 = yup.object().shape({
  text: yup.string().required('texto obrigatorio'),
});
const schema2 = yup.object().shape({
  section: yup.string().required('secção obrigatoria'),
});

export const NewAnnotation = ({ bundleId, historyPush }) => (
  <React.Fragment>
    <h1>Nova Anotação</h1>
    <Mutation
      mutation={CreateCollaborativeAnnotation}
      refetchQueries={[
        {
          query: MyBundle,
          variables: {
            bundle_id: bundleId,
          },
        },
      ]}
      onCompleted={() => {
        toast.success('Anotação criada com successo');
        historyPush(`/my_bundles/${bundleId}`);
      }}
      onError={() => toast.error('Não foi possível criar a anotação')}
    >
      {(mutate, { loading }) =>
        loading ? (
          <div className="loader" />
        ) : (
          <FormWizard
            submitLabel="Criar Anotação"
            onSubmit={values =>
              mutate({
                variables: {
                  data: {
                    text: values.text,
                    section_id: values.section,
                    bundle_id: bundleId,
                  },
                },
              })
            }
            initialValues={{
              text: '',
              section: null,
            }}
          >
            <Text schema={schema1} />
            <Section schema={schema2} />
          </FormWizard>
        )
      }
    </Mutation>
  </React.Fragment>
);

NewAnnotation.propTypes = {
  bundleId: PropTypes.string,
  historyPush: PropTypes.func,
};
