import React from 'react';
import PropTypes from 'prop-types';

import { ArticleCard as Card } from 'views/elements/Cards';
import { FlexColWrapper } from 'views/elements/Wrappers';
import { ArticleTitleLink } from 'views/elements/Texts';
import { SectionCardActions } from 'views/components/SectionCardActions';

export const ParagraphCard = ({
  data,
  articleNumber,
  articlePath,
  selectParagraph,
  lawSlug,
}) => {
  const path = `${articlePath}/paragraph/${data.number}`;

  return (
    <Card slug="fam">
      <SectionCardActions
        section={data}
        selectSection={selectParagraph}
        sectionPath={path}
        slug="fam"
      />
      <FlexColWrapper>
        <ArticleTitleLink
          slug="fam"
          to={path}
          onClick={() => selectParagraph(data.number)}
        >
          Parágrafo {data.number}
          {data.number < 10 ? 'º' : ''}
        </ArticleTitleLink>
        <p>{data.text}</p>
      </FlexColWrapper>
    </Card>
  );
};

ParagraphCard.propTypes = {
  data: PropTypes.shape({}),
  lawSlug: PropTypes.string,
  articleNumber: PropTypes.number,
  articlePath: PropTypes.string,
  selectParagraph: PropTypes.func,
};

ParagraphCard.displayName = 'ParagraphCard';

export default React.memo(ParagraphCard);
