import React, { Component } from 'react';
import ReactDOM from 'react-dom';

import Raven from 'raven-js';

import { ApolloProvider } from 'react-apollo';
import { StripeProvider } from 'react-stripe-elements';
import { ThemeProvider } from 'styled-components';

import { client, history } from 'core/setup';
import Router from 'views/router';
import PotentialError from 'views/components/PotentialError';
import ToastContainer from 'views/components/ToastContainer';
import { DEV, STRIPE_PK } from 'core/constants';
import { theme } from 'core/theme';

import 'styles/_index.scss';

// import registerServiceWorker from './registerServiceWorker';

if (!DEV) {
  const dsn = process.env.REACT_APP_SENTRY_DSN;
  if (dsn) Raven.config(dsn).install();
}

window.client = client;

class StripeApp extends Component {
  constructor() {
    super();
    this.state = { stripe: null };
  }
  componentDidMount() {
    if (window.Stripe) {
      // eslint-disable-next-line
      this.setState({
        stripe: window.Stripe(STRIPE_PK),
      });
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // eslint-disable-next-line
        this.setState({
          stripe: window.Stripe(STRIPE_PK),
        });
      });
    }
  }
  render() {
    return (
      <StripeProvider stripe={this.state.stripe}>
        <React.Fragment>
          <ThemeProvider theme={theme}>
            <Router history={history} />
          </ThemeProvider>
          <ToastContainer />
        </React.Fragment>
      </StripeProvider>
    );
  }
}

ReactDOM.render(
  <PotentialError>
    <ApolloProvider client={client}>
      <StripeApp />
    </ApolloProvider>
  </PotentialError>,
  document.getElementById('root'),
);
// registerServiceWorker();
