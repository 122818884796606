import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { onError } from 'apollo-link-error';
import { ApolloLink, Observable } from 'apollo-link';
import { createUploadLink } from 'apollo-upload-client';
import createHistory from 'history/createBrowserHistory';
import { BASE_URL } from 'core/constants';
import { load } from 'core/cookies';
import { toast } from 'core/utils/toast';

export const history = createHistory();

const request = async operation => {
  const token = load('authToken');
  operation.setContext({
    headers: {
      authorization: token,
    },
  });
};

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle;
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          });
        })
        .catch(observer.error.bind(observer));

      return () => {
        if (handle) handle.unsubscribe();
      };
    }),
);

const uploadLink = createUploadLink({
  uri: `${BASE_URL}graphql/v2`,
});

const handleError = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    let errorData;
    try {
      errorData = JSON.parse(graphQLErrors[0].message);
      if (Array.isArray(errorData)) {
        toast.error(errorData[0]);
      }
    } catch (e) {
      toast.error(graphQLErrors[0].message);
    }
    console.log(graphQLErrors);
  }
  if (networkError) {
    if (networkError.statusCode === 404 || networkError.statusCode === 403)
      history.replace('/404');
    if (networkError.statusCode === 401) history.replace('/logout');
    toast.error('Network error');
    console.log(networkError);
  }
});

export const client = new ApolloClient({
  link: ApolloLink.from([handleError, requestLink, uploadLink]),
  cache: new InMemoryCache(),
});
