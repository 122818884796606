import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Mutation } from 'react-apollo';
import SVG from 'react-inlinesvg';

import {
  CreatePrivateAnnotation,
  EditPrivateAnnotation,
  DeletePrivateAnnotation,
} from 'core/annotation/mutations';
import { GetSection } from 'core/section/queries';

import expand from 'views/assets/icons/expand.svg';
import collapse from 'views/assets/icons/collapse.svg';
import { SaveButton, DeleteButton } from 'views/elements/Buttons';
import { RightAlignedWrapper, EditorWrapper } from 'views/elements/Wrappers';
import { TextArea } from 'views/elements/Inputs';
import { Error } from 'views/elements/Texts';

class AnnotationEditor extends Component {
  static propTypes = {
    slug: PropTypes.string.isRequired,
    sectionId: PropTypes.string.isRequired,
    newAnnotation: PropTypes.bool.isRequired,
    annotationText: PropTypes.string,
    annotationId: PropTypes.string,
    successFunc: PropTypes.func,
    expanded: PropTypes.bool,
    expandFunc: PropTypes.func,
  };

  state = {
    text: this.props.annotationText,
    error: null,
  };

  changeText = text => this.setState({ text, error: null });

  changeError = error => this.setState({ error });

  render() {
    const {
      props: {
        slug,
        sectionId,
        newAnnotation,
        annotationId,
        successFunc,
        expanded,
        expandFunc,
      },
      state: { text, error },
      changeText,
      changeError,
    } = this;

    const variables = newAnnotation
      ? {
          data: {
            section_id: sectionId,
            text,
          },
        }
      : {
          data: {
            annotation_id: annotationId,
            text,
          },
        };

    return (
      <EditorWrapper expanded>
        <RightAlignedWrapper>
          {expanded ? (
            <button onClick={expandFunc}>
              <SVG src={collapse} />
            </button>
          ) : (
            <button onClick={expandFunc}>
              <SVG src={expand} />
            </button>
          )}
        </RightAlignedWrapper>
        <TextArea
          rows="20"
          onChange={e => changeText(e.target.value)}
          value={text}
          error={error}
        />
        <Error>{error}</Error>
        <RightAlignedWrapper>
          {annotationId != null && (
            <Mutation
              mutation={DeletePrivateAnnotation}
              variables={{
                data: {
                  annotation_id: annotationId,
                },
              }}
              refetchQueries={[
                {
                  query: GetSection,
                  variables: {
                    id: sectionId,
                  },
                },
              ]}
              onCompleted={successFunc}
              onError={() => changeError('Não foi possível apagar a anotação')}
            >
              {mutate => (
                <DeleteButton onClick={mutate}>Apagar Anotação</DeleteButton>
              )}
            </Mutation>
          )}
          <Mutation
            mutation={
              newAnnotation ? CreatePrivateAnnotation : EditPrivateAnnotation
            }
            variables={variables}
            refetchQueries={[
              {
                query: GetSection,
                variables: {
                  id: sectionId,
                },
              },
            ]}
            onCompleted={successFunc}
            onError={() => changeError('O texto da anotação é inválido')}
          >
            {mutate => (
              <SaveButton slug={slug} onClick={mutate}>
                Salvar Anotação
              </SaveButton>
            )}
          </Mutation>
        </RightAlignedWrapper>
      </EditorWrapper>
    );
  }
}

export default AnnotationEditor;
