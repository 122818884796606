import gql from 'graphql-tag';

export const BuyPremium = gql`
  mutation buyPremium($data: BuyPremiumInput!) {
    buyPremium(input: $data) {
      id
    }
  }
`;

export const BuyAnnotationBundle = gql`
  mutation buyAnnotationBundle($data: BuyAnnotationBundleInput!) {
    buyAnnotationBundle(input: $data) {
      id
    }
  }
`;
