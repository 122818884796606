import { BASE_URL } from 'core/constants';

export function request(method, endpoint, data, headers) {
  let sendData = null;
  if (method !== 'GET') sendData = JSON.stringify(data);
  const sendHeaders = {
    ...headers,
    'Content-Type': 'application/json',
  };

  return fetch(`${BASE_URL}${endpoint}`, {
    method,
    headers: sendHeaders,
    body: sendData,
  });
}

export function resendConfirmation(email) {
  const data = {
    user: {
      email,
    },
  };
  return request('POST', 'users/confirmation.json', data);
}

export function confirmAccount(token) {
  return request('GET', `users/confirmation.json?confirmation_token=${token}`);
}

export function login(email, pass) {
  const data = {
    user: {
      email,
      password: pass,
    },
  };
  return request('POST', 'users/sign_in.json', data);
}

export function loginOAuth(user) {
  const data = {
    user,
  };
  return request('POST', 'users/oauth.json', data);
}

export function logout(token) {
  const headers = {
    Authorization: `Bearer ${token}`,
    Accept: 'application/json',
  };
  return request('DELETE', 'users/sign_out.json', {}, headers);
}

export function recoverPassword(email) {
  const data = {
    user: {
      email,
    },
  };
  return request('POST', 'users/password.json', data);
}

export function resetPassword(pass, token) {
  const data = {
    user: {
      password: pass,
      reset_password_token: token,
    },
  };
  return request('PUT', 'users/password.json', data);
}

export function signUp(name, email, job, pass) {
  const data = {
    user: {
      name,
      email,
      job: job.toLowerCase(),
      password: pass,
    },
  };
  return request('POST', 'users.json', data);
}
