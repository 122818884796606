import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ModalContainer = styled.div`
  position: fixed;
  top: 50%;
  left: calc(25rem + (100% - 25rem) / 2);
  transform: translate(-50%, -50%);
  z-index: 99;
  display: flex;
  flex-direction: column;
  padding: 5rem 10rem;
  background-color: white;
  border-radius: 6px;
  border: solid 1px ${({ theme }) => theme.colors.textGreyPrimary};
  justify-content: center;
  align-content: center;
  align-items: center;
`;

const ModalCloseButton = styled.button`
  position: absolute;
  top: 1rem;
  right: 1rem;
  color: ${({ theme }) => theme.colors.textPrimary};
  font-family: 'NB International Regular Webfont', sans-serif;
  font-weight: 700;
  cursor: pointer;
`;

export const Modal = ({ isOpen, closeModal, children }) => {
  if (isOpen === false) return null;

  return (
    <ModalContainer>
      <ModalCloseButton onClick={closeModal}>X</ModalCloseButton>
      {children}
    </ModalContainer>
  );
};

Modal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
  children: PropTypes.arrayOf(PropTypes.element),
};
