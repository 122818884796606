import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const AppWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.backgroundPrimary};
  width: 100%;
  justify-content: center;
`;

export const LeftSidebarWrapper = styled.div`
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.backgroundSecondary};
  border-style: solid;
  border-width: 0 1px;
  border-color: lightgrey;
  width: 25rem;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 2rem;
  overflow-x: hidden;
  position: fixed;
  top: 0;
  left: 0;

  svg {
    margin-bottom: 5rem;
  }
`;

export const NavbarWrapper = styled.div`
  background-color: white;
  position: fixed;
  z-index: 2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 10rem;
  box-shadow: 0 2px 14px rgba(0, 0, 0, 0.1);
`;

export const AuthWrapper = styled.div`
  background-color: ${({ theme }) => theme.appColors.anotado.secondary};
  padding-top: 15rem;
  padding-bottom: 5rem;
  min-height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const MainWrapper = styled.div`
  padding: 5rem 5rem 5rem 30rem;
`;

export const ContentWrapper = styled.div`
  ${({ withSidebar }) => (withSidebar ? 'padding: 0 25rem 0 0' : '')};
`;

export const RightSidebarWrapper = styled.div`
  width: 25rem;
  height: 100vh;
  display: flex;
  flex-direction: column;
  padding: 5rem 5rem 5rem 0;
  position: fixed;
  top: 0;
  right: 0;

  svg {
    height: 1.2rem;
    padding: 1rem;
  }

  p {
    font-size: 1.2rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    color: ${({ theme }) => theme.colors.textGreySecondary};
    text-align: center;
    padding: 1rem 0;
  }
`;

export const TitleWrapper = styled(Link)`
  min-height: 8rem;
  padding: 1rem;
  position: relative;
  border-left: 2px solid
    ${({ theme, slug, selected }) =>
      selected
        ? theme.appColors[slug]
          ? theme.appColors[slug].primary
          : theme.appColors.anotado.primary
        : theme.colors.textGreySecondary};

  h1 {
    color: ${({ theme, slug, selected }) =>
      selected
        ? theme.appColors[slug]
          ? theme.appColors[slug].primary
          : theme.appColors.anotado.primary
        : theme.colors.textSecondary};
    font-family: 'NB International Regular Webfont', sans-serif;
    font-size: 1.2rem;
  }

  h2 {
    color: ${({ theme }) => theme.colors.textGreyPrimary};
    margin-top: 0.4rem;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    font-family: 'Source Sans Pro', sans-serif;
  }

  h3 {
    color: ${({ theme, slug, selected }) =>
      selected
        ? theme.appColors[slug]
          ? theme.appColors[slug].primary
          : theme.appColors.anotado.primary
        : theme.colors.textSecondary};
    font-size: 1.2rem;
    font-family: 'Source Sans Pro', sans-serif;
    font-weight: bold;
    position: absolute;
    bottom: 1rem;
  }
`;

export const FlexRowWrapWrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  width: 100%;
`;

export const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

export const RightAlignedWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export const LeftAlignedWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
`;

export const FlexWrapper = styled.div`
  display: flex;
`;

export const FlexRowWrapper = styled.div`
  display: flex;
`;

export const FlexColWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const FlexSpaceBetweenWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ArticleWrapper = styled.div`
  width: ${({ splitted }) => (splitted ? 'calc(50% - 2rem)' : '100%')};
`;

export const EditorWrapper = styled.div`
  width: 100%;
  padding: 2rem;
  background-color: white;
`;

export const NotFoundWrapper = styled.div`
  width: 100%;
  padding: 2rem 10rem;
`;

export const MarginLeftWrapper = styled.div`
  margin-left: ${({ level }) => (level ? `${level * 6.2}rem` : 0)};
`;

export const SidesMarginWrapper = styled.div`
  margin: 0 ${({ level }) => (level ? `${level * 6.2}rem` : 0)};
`;

export const FontSizeEditWrapper = styled.div`
  display: flex;

  span {
    padding: 1rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  button {
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: white;
    padding: 1rem;
    font-family: 'NB International Regular Webfont', sans-serif;
    font-size: 2rem;
    font-weight: 700;
    color: ${({ theme }) => theme.colors.textPrimary};
  }

  button:hover {
    opacity: 0.5;
    cursor: pointer;
  }

  button:disabled {
    opacity: 0.2;
    cursor: default;
  }
`;
