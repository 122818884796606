import React from 'react';
import PropTypes from 'prop-types';

import { GetCPCArticle } from 'core/app/cpc/queries';

import { Section } from 'views/components/Section';

export const Article = props => (
  <Section
    {...props}
    slug="cpc"
    query={GetCPCArticle}
    variables={{
      version: props.version,
      articleNumber: props.articleNumber,
    }}
    sectionKey="cpcArticle"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    }`}
  />
);

Article.propTypes = {
  version: PropTypes.string,
  articleNumber: PropTypes.number,
};

export default Article;
