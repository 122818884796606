import React from 'react';
import PropTypes from 'prop-types';

import { GetFamArticle } from 'core/app/fam/queries';

import { SidesMarginWrapper } from 'views/elements/Wrappers';
import { Section } from 'views/components/Section';
import ParagraphCard from '../ParagraphCard';

export const Sections = (articleNumber, selectParagraph, articlePath) => {
  const Paragraphs = ({ section: { paragraphs = [] } }) => (
    <SidesMarginWrapper level={1}>
      {paragraphs.length > 0 &&
        paragraphs.map(s => (
          <ParagraphCard
            key={s.id}
            data={s}
            articleNumber={articleNumber}
            articlePath={articlePath}
            selectParagraph={paragraphNumber =>
              selectParagraph(articleNumber, paragraphNumber)
            }
          />
        ))}
    </SidesMarginWrapper>
  );

  Paragraphs.propTypes = {
    section: PropTypes.shape({
      paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  };

  return Paragraphs;
};

export const Article = props => (
  <Section
    {...props}
    slug="fam"
    query={GetFamArticle}
    variables={{
      slug: props.lawSlug,
      articleNumber: props.articleNumber,
      articleItem: props.articleItem,
    }}
    sectionKey="famArticle"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    }`}
    Sections={Sections(
      props.articleNumber,
      props.selectParagraph,
      props.match.url,
    )}
  />
);

Article.propTypes = {
  lawSlug: PropTypes.string,
  articleNumber: PropTypes.number,
  articleItem: PropTypes.string,
  selectParagraph: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Article;
