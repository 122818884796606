import gql from 'graphql-tag';

export const GetUserFavourites = gql`
  query favourites($slug: String!) {
    favourites(slug: $slug) {
      id
      section {
        id
        path
        full_name
        content {
          id
          name
        }
        number
        text
      }
    }
  }
`;
