import React from 'react';
import PropTypes from 'prop-types';

import { GetLMSParagraph } from 'core/app/lms/queries';

import { SidesMarginWrapper } from 'views/elements/Wrappers';
import { Section } from 'views/components/Section';
import PointCard from '../PointCard';

export const Sections = (
  articleNumber,
  paragraphNumber,
  selectPoint,
  parentPath,
) => {
  const Points = ({ section: { points = [] } }) => (
    <SidesMarginWrapper level={1}>
      {points.length > 0 &&
        points.map(s => (
          <PointCard
            key={s.id}
            data={s}
            parentPath={parentPath}
            selectPoint={pointNumber =>
              selectPoint(pointNumber, articleNumber, paragraphNumber)
            }
          />
        ))}
    </SidesMarginWrapper>
  );

  Points.propTypes = {
    section: PropTypes.shape({
      paragraphs: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        }),
      ),
    }),
  };

  return Points;
};

export const Paragraph = props => (
  <Section
    {...props}
    slug="lms"
    query={GetLMSParagraph}
    variables={{
      articleNumber: props.articleNumber,
      paragraphNumber: props.paragraphNumber,
    }}
    sectionKey="lmsParagraph"
    sectionName={`Artigo ${props.articleNumber}${
      props.articleNumber < 10 ? 'º' : ''
    } - Parágrafo ${props.paragraphNumber}${
      props.paragraphNumber < 10 ? 'º' : ''
    }`}
    Sections={Sections(
      props.articleNumber,
      props.paragraphNumber,
      props.selectPoint,
      props.match.url,
    )}
  />
);

Paragraph.propTypes = {
  articleNumber: PropTypes.number,
  paragraphNumber: PropTypes.number,
  selectPoint: PropTypes.func,
  match: PropTypes.shape({
    url: PropTypes.string,
  }),
};

export default Paragraph;
