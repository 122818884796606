export const BASE_URL =
  process.env.REACT_APP_SERVER_HOST || 'http://localhost:3000/';
export const DEV = process.env.NODE_ENV === 'development';
export const STRIPE_PK = process.env.REACT_APP_STRIPE_PK;

export const MIN_PASSWORD_LENGTH = 6;

export const EMPTY_NAME_ERROR = 'Insira um nome';
export const EMPTY_EMAIL_ERROR = 'Insira um email';
export const INVALID_EMAIL_ERROR = 'Insira um email válido';
export const EMPTY_PASSWORD_ERROR = 'Insira uma senha';
export const EMPTY_CONFIRM_PASSWORD_ERROR = 'Confirme a sua senha';
export const SHORT_PASSWORD_ERROR = 'Senha demasiado curta';
export const UNMATCHED_PASSWORD_ERROR = 'Senhas não coincidem';
export const INVALID_JOB_ERROR = 'Escolha uma profissão válida';
export const FACEBOOK_PERMISSIONS_ERROR =
  'Não foi possível efetuar login com o Facebook';
export const GOOGLE_PERMISSIONS_ERROR =
  'Não foi possível efetuar login com o Google';

export const RESET_PASSWORD_SUCCESS = 'A sua senha foi alterada com sucesso.';
export const RECOVER_PASSWORD_SUCCESS =
  'Foi enviado um email para efetuar a alteração da password.';
export const CONFIRM_ACCOUNT_SUCCESS =
  'A sua conta foi confirmada com sucesso.';
export const RESEND_CONFIRMATION_SUCCESS =
  'Email de confirmação enviado com sucesso.';
export const SIGNUP_SUCCESS = 'A sua conta foi registada com sucesso.';
export const UPDATE_PROFILE_SUCCESS =
  'O seu perfil foi atualizado com sucesso.';

export const JOBS = ['Estudante', 'Professor', 'Jurista', 'Advogado', 'Outro'];

export const FONT_SIZES = [100, 125, 150];

export const searchApps = [
  {
    slug: '',
    name: 'Todas as Leis',
    categories: [
      {
        value: 'article',
        name: 'Artigos',
      },
      {
        value: 'subarticle',
        name: 'Items',
      },
      {
        value: 'paragraph',
        name: 'Parágrafos',
      },
      {
        value: 'point',
        name: 'Incisos',
      },
      {
        value: 'chapter',
        name: 'Capítulos',
      },
      {
        value: 'title',
        name: 'Títulos',
      },
      {
        value: 'annotation',
        name: 'Anotações',
      },
    ],
  },
  {
    slug: 'cpc',
    name: 'Código de Processo Civil',
    categories: [
      {
        value: 'article',
        name: 'Artigos',
      },
      {
        value: 'chapter',
        name: 'Capítulos',
      },
      {
        value: 'title',
        name: 'Títulos',
      },
      {
        value: 'annotation',
        name: 'Anotações',
      },
    ],
    contents: [
      {
        slug: 'cpc',
        name: 'Código de Processo Civil',
      },
    ],
  },
  {
    slug: 'lms',
    name: 'Lei do Mandado de Segurança',
    categories: [
      {
        value: 'article',
        name: 'Artigos',
      },
      {
        value: 'paragraph',
        name: 'Parágrafos',
      },
      {
        value: 'point',
        name: 'Incisos',
      },
      {
        value: 'annotation',
        name: 'Anotações',
      },
    ],
    contents: [
      {
        slug: 'lms',
        name: 'Lei do Mandado de Segurança',
      },
    ],
  },
  {
    slug: 'fam',
    name: 'Leis da Família',
    categories: [
      {
        value: 'article',
        name: 'Artigos',
      },
      {
        value: 'subarticle',
        name: 'Items',
      },
      {
        value: 'paragraph',
        name: 'Parágrafos',
      },
      {
        value: 'annotation',
        name: 'Anotações',
      },
    ],
    contents: [
      {
        slug: 'lgc',
        name: 'Lei da Guarda Compartilhada',
      },
      {
        slug: 'lip',
        name: 'Lei da Investigação de Paternidade',
      },
      {
        slug: 'la',
        name: 'Lei dos Alimentos',
      },
      {
        slug: 'lag',
        name: 'Lei dos Alimentos Gravídicos',
      },
      {
        slug: 'lsap',
        name: 'Lei Sobre Alienação Parental',
      },
    ],
  },
];
