import React from 'react';
import PropTypes from 'prop-types';

import { romanize } from 'core/utils/helpers';

import { ArticleCard as Card } from 'views/elements/Cards';
import { FlexColWrapper } from 'views/elements/Wrappers';
import { ArticleTitleLink } from 'views/elements/Texts';
import { SectionCardActions } from 'views/components/SectionCardActions';

export const PointCard = ({ data, parentPath, selectPoint }) => {
  const path = `${parentPath}/point/${data.number}`;

  return (
    <Card slug="lms">
      <SectionCardActions
        section={data}
        selectSection={selectPoint}
        sectionPath={path}
        slug="lms"
      />
      <FlexColWrapper>
        <ArticleTitleLink
          slug="lms"
          to={path}
          onClick={() => selectPoint(data.number)}
        >
          Inciso {romanize(data.number)}
        </ArticleTitleLink>
        <p>{data.text}</p>
      </FlexColWrapper>
    </Card>
  );
};

PointCard.propTypes = {
  data: PropTypes.shape({}),
  parentPath: PropTypes.string,
  selectPoint: PropTypes.func,
};

PointCard.displayName = 'PointCard';

export default React.memo(PointCard);
