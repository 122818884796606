import React, { Component, createContext } from 'react';
import PropTypes from 'prop-types';
import { save as saveCookie, remove as removeCookie } from 'core/cookies';
import { logout } from 'core/user/api';
import { FONT_SIZES } from 'core/constants';

const Context = createContext();

const { Provider, Consumer } = Context;

const merge = require('lodash/merge');

export const UserConsumer = Consumer;

/* eslint-disable react/no-unused-state */
export class ProviderComponent extends Component {
  static propTypes = {
    children: PropTypes.element.isRequired,
    history: PropTypes.shape(),
  };

  constructor(props) {
    super(props);
    const user = localStorage.getItem('user');
    const fontSizeIndex = localStorage.getItem('fontSizeIndex');

    this.state = {
      user: user != null ? JSON.parse(user) : null,
      fontSizeIndex: fontSizeIndex != null ? parseInt(fontSizeIndex, 10) : 0,
      error: null,
    };
  }

  setFontSize = () => {
    document.documentElement.className = `fontSize${
      FONT_SIZES[this.state.fontSizeIndex]
    }`;
  };

  login = (user, authToken) => {
    saveCookie('authToken', authToken, { path: '/' });
    localStorage.setItem('user', JSON.stringify(user));
    this.setState({ user, error: null });
    this.props.history.push('/profile');
  };

  logout = () => {
    const user = { ...this.state.user };
    if (user && user.jwt) logout(user.jwt);
    this.setState({ user: null, error: null });
    removeCookie('authToken');
    localStorage.clear();
  };

  update = user => {
    const updatedUser = merge(this.state.user, user);
    localStorage.setItem('user', JSON.stringify(updatedUser));
    this.setState({ user: updatedUser, error: null });
  };

  updateFontSize = fontSizeIndex => {
    if (fontSizeIndex >= FONT_SIZES.length) {
      localStorage.setItem('fontSizeIndex', FONT_SIZES.length - 1);
      this.setState({ fontSizeIndex: FONT_SIZES.length - 1 });
    } else if (fontSizeIndex < 0) {
      localStorage.setItem('fontSizeIndex', 0);
      this.setState({ fontSizeIndex: 0 });
    } else {
      localStorage.setItem('fontSizeIndex', fontSizeIndex);
      this.setState({ fontSizeIndex });
    }
    this.setFontSize();
  };

  render() {
    return (
      <Provider
        value={{
          state: this.state,
          actions: {
            loginUser: this.login,
            logoutUser: this.logout,
            updateUser: this.update,
            updateFontSize: this.updateFontSize,
            setFontSize: this.setFontSize,
          },
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const UserProvider = ProviderComponent;
