import styled from 'styled-components';

export const NavSeparator = styled.div`
  height: 1px;
  min-height: 1px;
  background-color: ${({ theme }) => theme.colors.textGreySecondary};
  width: 100%;
`;

export const HorizontalLine = styled.div`
  height: 1px;
  min-height: 1px;
  background-color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  width: 100%;
  margin: 1rem 0;
`;

export const VerticalLine = styled.div`
  width: 1px;
  background-color: ${({ theme, slug }) =>
    theme.appColors[slug]
      ? theme.appColors[slug].primary
      : theme.appColors.anotado.primary};
  height: initial;
`;

export const TextSeparator = styled.h1`
  font-family: 'Source Sans Pro', sans-serif;
  color: ${({ theme }) => theme.colors.textGreySecondary};
  overflow: hidden;
  text-align: center;
  width: 100%;

  margin: 1rem;

  &:before,
  &:after {
    background-color: ${({ theme }) => theme.colors.textGreySecondary};
    content: '';
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%;
  }

  &:before {
    right: 0.5em;
    margin-left: -50%;
  }

  &:after {
    left: 0.5em;
    margin-right: -50%;
  }
`;

export const AuthLine = styled.div`
  height: 2px;
  background-color: ${({ theme }) => theme.colors.textPrimary};
  width: 100%;
  margin-bottom: ${({ error }) => (error ? '0.5rem' : '2rem')};
`;
