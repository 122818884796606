import React from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from 'react-apollo';
import { Formik, Field, Form } from 'formik';
import * as yup from 'yup';

import { toast } from 'core/utils/toast';

import { MyBundle } from 'core/bundle/queries';
import { GetBundleAnnotation } from 'core/annotation/queries';
import {
  EditCollaborativeAnnotation,
  DeleteCollaborativeAnnotation,
} from 'core/annotation/mutations';

import Icon from 'views/components/Icon';

import {
  Info,
  Text,
  Title,
  SubTitle,
  CardSubTitle,
} from 'views/elements/Texts';
import {
  CenteredWrapper,
  FlexSpaceBetweenWrapper,
  FlexRowWrapWrapper,
  FlexColWrapper,
  RightAlignedWrapper,
  MarginLeftWrapper,
} from 'views/elements/Wrappers';
import { CircleLoader } from 'views/elements/Loaders';
import { ChangesCard } from 'views/elements/Cards';
import { SaveButton, DeleteButton } from 'views/elements/Buttons';

const annotationSchema = yup.object().shape({
  text: yup.string().required('texto obrigatorio'),
});

export class Annotation extends React.Component {
  static propTypes = {
    annotationId: PropTypes.string,
    bundleId: PropTypes.string,
    historyPush: PropTypes.func,
  };

  state = {
    editor: false,
  };

  toggleEditor = () =>
    this.setState(prevState => ({ editor: !prevState.editor }));

  render() {
    const {
      props: { annotationId, bundleId, historyPush },
      state: { editor },
      toggleEditor,
    } = this;

    return (
      <Query
        query={GetBundleAnnotation}
        variables={{
          bundle_id: bundleId,
          annotation_id: annotationId,
        }}
      >
        {({ data, loading, error }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <CircleLoader />
              </CenteredWrapper>
            );
          if (error || data.bundleAnnotation == null)
            return (
              <CenteredWrapper>
                <Info>Anotação não encontrada</Info>
              </CenteredWrapper>
            );

          const annotation = data.bundleAnnotation;

          return (
            <React.Fragment>
              {editor ? (
                <React.Fragment>
                  <Mutation
                    mutation={EditCollaborativeAnnotation}
                    refetchQueries={[
                      {
                        query: GetBundleAnnotation,
                        variables: {
                          bundle_id: bundleId,
                          annotation_id: annotationId,
                        },
                      },
                    ]}
                    onCompleted={() => {
                      toast.success('Anotação editada com successo');
                      toggleEditor();
                    }}
                    onError={error => {
                      toast.error('Não foi possível editar a anotação');
                    }}
                  >
                    {(mutate, { loading }) =>
                      loading ? (
                        <CenteredWrapper>
                          <CircleLoader />
                        </CenteredWrapper>
                      ) : (
                        <Formik
                          validationSchema={annotationSchema}
                          initialValues={{
                            text: annotation.text,
                          }}
                          onSubmit={(values, actions) => {
                            mutate({
                              variables: {
                                data: {
                                  annotation_id: annotationId,
                                  text: values.text,
                                },
                              },
                            });
                          }}
                          render={({
                            errors,
                            touched,
                            isValid,
                            isValidating,
                            isSubmitting,
                          }) => (
                            <Form>
                              <Field
                                name="text"
                                placeholder="Texto"
                                rows="20"
                                style={{
                                  width: '100%',
                                  backgroundColor: 'white',
                                  padding: '2rem',
                                  margin: '1rem',
                                }}
                                component="textarea"
                              />
                              <RightAlignedWrapper>
                                <SaveButton
                                  type="submit"
                                  disabled={
                                    isSubmitting || isValidating || !isValid
                                  }
                                >
                                  Guardar
                                </SaveButton>
                                <DeleteButton
                                  type="button"
                                  onClick={toggleEditor}
                                >
                                  Cancelar
                                </DeleteButton>
                                <Mutation
                                  mutation={DeleteCollaborativeAnnotation}
                                  variables={{
                                    data: {
                                      annotation_id: annotationId,
                                    },
                                  }}
                                  refetchQueries={[
                                    {
                                      query: MyBundle,
                                      variables: {
                                        bundle_id: bundleId,
                                      },
                                    },
                                  ]}
                                  onCompleted={() => {
                                    historyPush(`/my_bundles/${bundleId}`);
                                    toast.success(
                                      'Anotação eliminada com successo',
                                    );
                                  }}
                                  onError={() =>
                                    toast.error(
                                      'Não foi possível eliminar a anotação',
                                    )
                                  }
                                >
                                  {(deleteMutate, { loading }) =>
                                    loading ? (
                                      <CenteredWrapper>
                                        <CircleLoader />
                                      </CenteredWrapper>
                                    ) : (
                                      <DeleteButton
                                        onClick={() => {
                                          const remove = window.confirm(
                                            'Tem a certeza que pretende remover esta anotação?',
                                          );
                                          if (remove) deleteMutate();
                                          else
                                            toast.info(
                                              'A anotação não foi removida.',
                                            );
                                        }}
                                      >
                                        Apagar Anotação
                                      </DeleteButton>
                                    )
                                  }
                                </Mutation>
                              </RightAlignedWrapper>
                            </Form>
                          )}
                        />
                      )
                    }
                  </Mutation>
                </React.Fragment>
              ) : (
                <MarginLeftWrapper level={0.5}>
                  <FlexSpaceBetweenWrapper>
                    <FlexColWrapper>
                      <Title>{annotation.section.full_name}</Title>
                      <SubTitle>{annotation.section.content.name}</SubTitle>
                    </FlexColWrapper>
                    <button onClick={toggleEditor}>
                      <Icon name="edit" color="black" />
                    </button>
                  </FlexSpaceBetweenWrapper>
                  <Text>{annotation.text}</Text>
                </MarginLeftWrapper>
              )}
              {annotation.history.length > 0 && (
                <React.Fragment>
                  <CardSubTitle>Alterações</CardSubTitle>
                  <MarginLeftWrapper level={0.5}>
                    <FlexRowWrapWrapper>
                      {annotation.history.map(ann => (
                        <ChangesCard key={ann.version}>
                          <h1>{ann.author_name}</h1>
                          <h2>{ann.updated_at}</h2>
                          <p>{ann.text}</p>
                        </ChangesCard>
                      ))}
                    </FlexRowWrapWrapper>
                  </MarginLeftWrapper>
                </React.Fragment>
              )}
            </React.Fragment>
          );
        }}
      </Query>
    );
  }
}
