import React from 'react';
import { Query } from 'react-apollo';
import { Field } from 'formik';

import { AnnotatableSectionsSearch } from 'core/section/queries';

import FormWizard from 'views/components/Wizard';

export class Section extends React.Component {
  state = {
    searchText: '',
  };

  changeSearchText = searchText => this.setState({ searchText });

  render() {
    const {
      state: { searchText },
      changeSearchText,
    } = this;
    return (
      <FormWizard.Page>
        <h1>Anexar secção</h1>
        <input
          type="text"
          name="searchText"
          placeholder="Procurar"
          value={searchText}
          onChange={e => changeSearchText(e.target.value)}
        />
        <Query
          query={AnnotatableSectionsSearch}
          variables={{
            text: searchText,
            first: 6,
          }}
        >
          {({ data, fetchMore, error }) => {
            const results = data && data.annotatableSectionsSearch;

            const loadMore = () =>
              fetchMore({
                variables: {
                  after: results.pageInfo.endCursor,
                },
                updateQuery: (prev, { fetchMoreResult }) => {
                  if (!fetchMoreResult) return prev;
                  const newData = {
                    annotatableSectionsSearch: {
                      ...fetchMoreResult.annotatableSectionsSearch,
                      edges: [
                        ...prev.annotatableSectionsSearch.edges,
                        ...fetchMoreResult.annotatableSectionsSearch.edges,
                      ],
                      pageInfo: {
                        ...fetchMoreResult.annotatableSectionsSearch.pageInfo,
                        startCursor:
                          prev.annotatableSectionsSearch.pageInfo.startCursor,
                      },
                    },
                  };
                  return newData;
                },
              });

            return (
              <React.Fragment>
                <div>
                  {results &&
                    results.edges.map(edge => (
                      <div key={edge.node.id}>
                        <Field
                          type="radio"
                          name="section"
                          label="Texto"
                          value={edge.node.id}
                          component="input"
                        />
                        <p>{edge.node.name}</p>
                        <p>{edge.node.text}</p>
                      </div>
                    ))}
                </div>
                {results &&
                  results.pageInfo.hasNextPage && (
                    <button onClick={() => loadMore()}>MORE</button>
                  )}
              </React.Fragment>
            );
          }}
        </Query>
      </FormWizard.Page>
    );
  }
}
