import React from 'react';
import PropTypes from 'prop-types';

import { ArticleCard as Card } from 'views/elements/Cards';
import { FlexColWrapper } from 'views/elements/Wrappers';
import { ArticleTitleLink } from 'views/elements/Texts';
import { SectionCardActions } from 'views/components/SectionCardActions';

export const ArticleCard = ({
  data,
  version,
  partNumber,
  bookNumber,
  titleNumber,
  chapterNumber,
  selectArticle,
}) => {
  const path = `/app/cpc/code/${version}${
    partNumber ? `/part/${partNumber}` : ''
  }/book/${bookNumber}/title/${titleNumber}${
    chapterNumber ? `/chapter/${chapterNumber}` : ''
  }/article/${data.number}`;

  return (
    <Card slug="cpc">
      <SectionCardActions
        section={data}
        selectSection={selectArticle}
        sectionPath={path}
        slug="cpc"
      />
      <FlexColWrapper>
        <ArticleTitleLink
          slug="cpc"
          to={path}
          onClick={() => selectArticle(data.number)}
        >
          ARTIGO {data.number}º
        </ArticleTitleLink>
        <p>{data.text}</p>
      </FlexColWrapper>
    </Card>
  );
};

ArticleCard.propTypes = {
  data: PropTypes.shape({}),
  version: PropTypes.string,
  partNumber: PropTypes.number,
  bookNumber: PropTypes.number,
  titleNumber: PropTypes.number,
  chapterNumber: PropTypes.number,
  selectArticle: PropTypes.func,
};

ArticleCard.displayName = 'ArticleCard';

export default React.memo(ArticleCard);
