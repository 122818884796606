import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import back from 'views/assets/icons/back.svg';
import {
  ContentWrapper,
  CenteredWrapper,
  FlexRowWrapWrapper,
} from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { BackButton } from 'views/elements/Buttons';
import { Back } from 'views/components/Back';

import { BookmarksScreen } from 'views/pages/Bookmarks';
import LawCard from './LawCard';
import Law from './Law';
import Article from './Article';
import Paragraph from './Paragraph';

class FAM extends Component {
  static propTypes = {
    app: PropTypes.shape({}),
    match: PropTypes.shape({
      params: PropTypes.shape({
        lawSlug: PropTypes.string,
        articleNumber: PropTypes.string,
        paragraphNumber: PropTypes.string,
      }),
    }),
  };

  state = {
    selectedLaw: this.props.match.params.lawSlug,
    selectedArticle:
      parseInt(this.props.match.params.articleNumber, 10) || null,
    selectedParagraph:
      parseInt(this.props.match.params.paragraphNumber, 10) || null,
  };

  componentDidUpdate() {
    if (
      (parseInt(this.props.match.params.articleNumber, 10) || null) !==
      this.state.selectedArticle
    ) {
      this.selectLawArticleParagrah(
        this.props.match.params.lawSlug,
        parseInt(this.props.match.params.articleNumber, 10) || null,
        parseInt(this.props.match.params.paragraphNumber, 10) || null,
      );
    }
    if (
      (parseInt(this.props.match.params.paragraphNumber, 10) || null) !==
      this.state.selectedParagraph
    ) {
      this.selectLawArticleParagrah(
        this.props.match.params.lawSlug,
        parseInt(this.props.match.params.articleNumber, 10) || null,
        parseInt(this.props.match.params.paragraphNumber, 10) || null,
      );
    }
  }

  selectLawArticleParagrah = (slug, articleNumber, paragraphNumber) =>
    this.setState({
      selectedLaw: slug,
      selectedArticle: articleNumber,
      selectedParagraph: paragraphNumber,
    });

  selectLaw = slug =>
    this.setState({
      selectedLaw: slug,
      selectedArticle: null,
      selectedParagraph: null,
    });

  selectArticle = articleNumber =>
    this.setState({
      selectedArticle: articleNumber,
      selectedParagraph: null,
    });

  selectParagraph = (articleNumber, paragraphNumber) =>
    this.setState({
      selectedArticle: articleNumber,
      selectedParagraph: paragraphNumber,
    });

  render() {
    const {
      props: {
        app,
        match: { path, url },
      },
      state: { selectedLaw, selectedArticle, selectedParagraph },
      selectLaw,
      selectArticle,
      selectParagraph,
    } = this;

    return (
      <Switch>
        <Route
          exact
          path={path}
          render={props =>
            app.contents != null && app.contents.length > 0 ? (
              <ContentWrapper>
                <FlexRowWrapWrapper>
                  {app.contents.map(c => (
                    <LawCard key={c.id} data={c} selectLaw={selectLaw} />
                  ))}
                </FlexRowWrapWrapper>
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Não existem leis disponíveis</Info>
              </CenteredWrapper>
            )
          }
        />
        <Route
          exact
          path={[path, `${path}/law/:lawSlug`]}
          render={props => {
            const lawSlug = selectedLaw || props.match.params.lawSlug;
            const law = app.contents.find(content => content.slug === lawSlug);

            return law != null ? (
              <ContentWrapper>
                <BackButton slug="fam" to={url}>
                  <SVG src={back} />
                </BackButton>
                <Law
                  lawSlug={law.slug}
                  selectArticle={selectArticle}
                  selectParagraph={selectParagraph}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact={false}
          path={`${path}/law/:lawSlug/article/:articleNumber/paragraph/:paragraphNumber`}
          render={props => {
            const lawSlug = selectedLaw || props.match.params.lawSlug;
            const law = app.contents.find(content => content.slug === lawSlug);
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);
            const paragraphNumber =
              selectedParagraph ||
              parseInt(props.match.params.paragraphNumber, 10);

            return law != null ? (
              <ContentWrapper>
                <BackButton
                  slug="fam"
                  to={`${url}/law/${lawSlug}/article/${articleNumber}`}
                >
                  <SVG src={back} />
                </BackButton>
                <Paragraph
                  {...props}
                  lawSlug={law.slug}
                  articleNumber={articleNumber}
                  paragraphNumber={paragraphNumber}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact={false}
          path={[
            `${path}/law/:lawSlug/article/:articleNumber/item/:item`,
            `${path}/law/:lawSlug/article/:articleNumber`,
          ]}
          render={props => {
            const lawSlug = selectedLaw || props.match.params.lawSlug;
            const law = app.contents.find(content => content.slug === lawSlug);
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);

            return law != null ? (
              <ContentWrapper>
                <BackButton slug="fam" to={`${url}/law/${lawSlug}`}>
                  <SVG src={back} />
                </BackButton>
                <Article
                  {...props}
                  lawSlug={law.slug}
                  articleNumber={articleNumber}
                  articleItem={props.match.params.item}
                  selectParagraph={selectParagraph}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Esta lei não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact
          path={`${path}/bookmarks`}
          render={() => (
            <ContentWrapper>
              <Back slug="fam" url={url} title="Favoritos em Leis da Família" />
              <BookmarksScreen slug="fam" />
            </ContentWrapper>
          )}
        />
      </Switch>
    );
  }
}

export default FAM;
