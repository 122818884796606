export const ACTION = {
  SHOW: 0,
  CLEAR: 1,
};

export const TYPE = {
  INFO: 'info',
  SUCCESS: 'success',
  ERROR: 'error',
};

let toastId = 0;

function mergeOptions(options, type) {
  return { ...options, type, toastId: generateToastId(options) };
}

function generateToastId(options) {
  if (
    options &&
    ((typeof options.toastId === 'number' && !isNaN(options.toastId)) ||
      typeof options.toastId === 'string')
  ) {
    return options.toastId;
  }

  toastId += 1;

  return toastId;
}

export const eventManager = {
  list: new Map(),

  on(event, callback) {
    this.list.has(event) || this.list.set(event, []);

    this.list.get(event).push(callback);

    return this;
  },

  off(event = null) {
    this.list.delete(event);
    return this;
  },

  emit(event, ...args) {
    if (!this.list.has(event)) {
      return false;
    }
    this.list
      .get(event)
      .forEach(callback => setTimeout(() => callback.call(null, ...args), 0));

    return true;
  },
};

function emitEvent(content, options) {
  eventManager.emit(ACTION.SHOW, content, options);
  return options.toastId;
}

export const toast = {
  success: (content, options) =>
    emitEvent(content, mergeOptions(options, TYPE.SUCCESS)),
  info: (content, options) =>
    emitEvent(content, mergeOptions(options, TYPE.INFO)),
  error: (content, options) =>
    emitEvent(content, mergeOptions(options, TYPE.ERROR)),
  dismiss: (id = null) => eventManager.emit(ACTION.CLEAR, id),
};

window.toast = toast;
