import React from 'react';
import PropTypes from 'prop-types';
import { Query } from 'react-apollo';

import { BuyPremium } from 'core/purchase/mutations';
import { GetPremiumApp, GetApps } from 'core/app/queries';
import { listNames } from 'core/utils/helpers';

import { Checkout } from 'views/components/Checkout';

import {
  FlexSpaceBetweenWrapper,
  FlexColWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { PremiumBundleCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

const App = ({ slug }) => (
  <Query query={GetPremiumApp} variables={{ slug }}>
    {({ data, loading, error }) => {
      if (loading)
        return (
          <CenteredWrapper>
            <CircleLoader slug={slug} />
          </CenteredWrapper>
        );
      if (error || data.app == null)
        return (
          <CenteredWrapper>
            <Info>Aplicação não encontrada</Info>
          </CenteredWrapper>
        );

      const { app } = data;

      return (
        <PremiumBundleCard slug={slug}>
          <FlexSpaceBetweenWrapper>
            <FlexColWrapper>
              <h1>{app.name} Premium</h1>
              <h2>{app.annotations_count} anotações</h2>
              <h3>$RS {app.premium_price}</h3>
            </FlexColWrapper>
            <Checkout
              mutation={BuyPremium}
              mutationData={{ slug }}
              refetchQueries={[{ query: GetApps }]}
              price={app.premium_price}
              description={`PREMIUM ${app.name}`}
              active={!app.has_premium}
              slug={slug}
            />
          </FlexSpaceBetweenWrapper>
          <h4>Exemplos de anotações:</h4>
          {app.premium_bundles.map(b =>
            b.annotations.map(a => (
              <React.Fragment key={a.id}>
                <h5>{a.section.full_name}</h5>
                <h6>{a.section.content.name}</h6>
                <p>{a.text}</p>
              </React.Fragment>
            )),
          )}
          <h4>
            Anotações por {listNames(app.premium_authors)}
            .
          </h4>
        </PremiumBundleCard>
      );
    }}
  </Query>
);

App.propTypes = {
  slug: PropTypes.string,
};

export default App;
