import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { Switch, Route } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import { GetPremiumApps } from 'core/app/queries';
import { listBundleAuthors } from 'core/utils/helpers';

import premium from 'views/assets/icons/premium.svg';
import {
  FlexRowWrapWrapper,
  FlexColWrapper,
  FlexRowWrapper,
  CenteredWrapper,
} from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { PremiumCard } from 'views/elements/Cards';
import { CircleLoader } from 'views/elements/Loaders';

import TrialActivation from './TrialActivation';
import App from './App';

class PremiumMarketScreen extends Component {
  state = {
    selectedAppSlug: null,
  };

  selectApp = slug => this.setState({ selectedAppSlug: slug });

  render() {
    const {
      state: { selectedAppSlug },
      selectApp,
    } = this;

    return (
      <Query query={GetPremiumApps}>
        {({ data, loading, error }) => {
          if (loading)
            return (
              <CenteredWrapper>
                <CircleLoader />
              </CenteredWrapper>
            );
          if (error || data.apps == null || data.apps.length === 0)
            return (
              <CenteredWrapper>
                <Info>Não existem conteúdos disponíveis</Info>
              </CenteredWrapper>
            );

          const { apps } = data;
          return (
            <Switch>
              <Route
                exact
                path="/premium"
                render={() => (
                  <FlexColWrapper>
                    <FlexRowWrapWrapper>
                      {apps.map(app => (
                        <PremiumCard
                          key={app.id}
                          slug={app.slug}
                          onClick={() => selectApp(app.slug)}
                          disabled={app.purchased}
                          to={`/premium/${app.slug}`}
                        >
                          <h1>{app.name}</h1>
                          <h2>{app.annotations_count} Anotações</h2>
                          <h3>
                            Anotações por{' '}
                            {listBundleAuthors(app.premium_authors)}
                          </h3>
                          {app.has_premium ? (
                            <h4>Comprado</h4>
                          ) : (
                            <h4>Comprar por R${app.premium_price}</h4>
                          )}
                          <FlexRowWrapper>
                            <SVG src={premium} />
                            <p>Anotações Oficiais do Anotado</p>
                          </FlexRowWrapper>
                        </PremiumCard>
                      ))}
                    </FlexRowWrapWrapper>
                    <CenteredWrapper>
                      <TrialActivation />
                    </CenteredWrapper>
                  </FlexColWrapper>
                )}
              />
              <Route
                exact
                path="/premium/:slug"
                render={props => {
                  const selectedApp = apps.find(
                    app =>
                      app.slug === (selectedAppSlug || props.match.params.slug),
                  );

                  return selectedApp != null ? (
                    <CenteredWrapper>
                      <App slug={selectedApp.slug} />
                    </CenteredWrapper>
                  ) : (
                    <CenteredWrapper>
                      <Info>Esta aplicação não possui premium</Info>
                    </CenteredWrapper>
                  );
                }}
              />
            </Switch>
          );
        }}
      </Query>
    );
  }
}

export default PremiumMarketScreen;
