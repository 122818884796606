import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { FlexColWrapper as Wrapper } from 'views/elements/Wrappers';
import { Error } from 'views/elements/Texts';
import { Label, AuthSelect as Select } from 'views/elements/Inputs';
import { AuthLine } from 'views/elements/Separators';

export class AuthSelect extends Component {
  static propTypes = {
    field: PropTypes.shape({
      name: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      onChange: PropTypes.func.isRequired,
      onBlur: PropTypes.func.isRequired,
    }).isRequired,
    form: PropTypes.shape({
      errors: PropTypes.shape(),
      touched: PropTypes.shape(),
    }),
    options: PropTypes.arrayOf(PropTypes.string).isRequired,
    placeholder: PropTypes.string,
    label: PropTypes.string,
  };

  state = {
    active: false,
  };

  setActive = active => this.setState({ active });

  render() {
    const {
      props: {
        field: { name, value, onChange, onBlur },
        form: { errors, touched },
        options,
        placeholder,
        label,
      },
      state: { active },
      setActive,
    } = this;

    return (
      <Wrapper>
        <Label>{label}</Label>
        <Select
          placeholder={placeholder}
          name={name}
          value={value}
          active={active}
          onChange={onChange}
          onFocus={() => setActive(true)}
          onBlur={e => {
            setActive(false);
            onBlur(e);
          }}
        >
          {options.map(opt => (
            <option key={opt} value={opt}>
              {opt}
            </option>
          ))}
        </Select>
        <AuthLine error={touched[name] && errors[name]} />
        <Error>{touched[name] && errors[name]}</Error>
      </Wrapper>
    );
  }
}

export default AuthSelect;
