import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Switch, Route } from 'react-router-dom';
import SVG from 'react-inlinesvg';

import back from 'views/assets/icons/back.svg';
import { ContentWrapper, CenteredWrapper } from 'views/elements/Wrappers';
import { Info } from 'views/elements/Texts';
import { BackButton } from 'views/elements/Buttons';
import { Back } from 'views/components/Back';

import { BookmarksScreen } from 'views/pages/Bookmarks';
import { Header } from './Header';
import Code from './Code';
import Book from './Book';
import Article from './Article';

class CPC extends Component {
  static propTypes = {
    match: PropTypes.shape({
      params: PropTypes.shape({
        version: PropTypes.string,
        partNumber: PropTypes.string,
        bookNumber: PropTypes.string,
        titleNumber: PropTypes.string,
        chapterNumber: PropTypes.string,
        articleNumber: PropTypes.string,
      }),
    }),
    app: PropTypes.shape({}),
  };

  state = {
    selectedCode: this.props.match.params.version,
    selectedPart: parseInt(this.props.match.params.partNumber, 10) || null,
    selectedBook: parseInt(this.props.match.params.bookNumber, 10) || null,
    selectedTitle: parseInt(this.props.match.params.titleNumber, 10) || null,
    selectedArticle:
      parseInt(this.props.match.params.articleNumber, 10) || null,
  };

  componentDidUpdate() {
    if (
      (parseInt(this.props.match.params.articleNumber, 10) || null) !==
      this.state.selectedArticle
    ) {
      this.selectFullArticle(
        parseInt(this.props.match.params.partNumber, 10) || null,
        parseInt(this.props.match.params.bookNumber, 10) || null,
        parseInt(this.props.match.params.titleNumber, 10) || null,
        parseInt(this.props.match.params.articleNumber, 10) || null,
      );
    }
  }

  selectFullArticle = (partNumber, bookNumber, titleNumber, articleNumber) =>
    this.setState({
      selectedPart: partNumber,
      selectedBook: bookNumber,
      selectedTitle: titleNumber,
      selectedArticle: articleNumber,
    });

  selectPart = number =>
    this.setState({
      selectedPart: number,
      selectedBook: null,
      selectedTitle: null,
      selectedArticle: null,
    });
  selectBook = number =>
    this.setState({
      selectedBook: number,
      selectedTitle: null,
      selectedArticle: null,
    });
  selectTitle = number =>
    this.setState({
      selectedTitle: number,
      selectedArticle: null,
    });
  selectArticle = articleNumber =>
    this.setState({
      selectedArticle: articleNumber,
    });

  render() {
    const {
      props: {
        app,
        match: { path, url },
      },
      state: {
        selectedCode,
        selectedPart,
        selectedBook,
        selectedTitle,
        selectedArticle,
      },
      selectPart,
      selectBook,
      selectTitle,
      selectArticle,
    } = this;

    return (
      <Switch>
        <Route
          exact
          path={[
            path,
            `${path}/code/:version`,
            `${path}/code/:version/part/:partNumber`,
          ]}
          render={props => {
            const version =
              selectedCode ||
              props.match.params.version ||
              (app.contents != null &&
                app.contents.length > 0 &&
                app.contents[0].version);
            const code = app.contents.find(
              content => content.version === version,
            );

            const partNumber =
              selectedPart ||
              parseInt(props.match.params.partNumber, 10) ||
              (code &&
                code.main_sections != null &&
                code.main_sections.length > 0 &&
                code.main_sections[0].category === 'part' &&
                code.main_sections[0].number) ||
              null;

            return code != null ? (
              <ContentWrapper>
                <Header
                  app={app}
                  code={code}
                  version={version}
                  partNumber={partNumber}
                  selectPart={selectPart}
                />
                <Code
                  key={code.id}
                  data={code}
                  selectedCode={version}
                  selectedPart={partNumber}
                  selectBook={selectBook}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Este código não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact
          path={[
            `${path}/code/:version/part/:partNumber/book/:bookNumber`,
            `${path}/code/:version/part/:partNumber/book/:bookNumber/title/:titleNumber`,
            `${path}/code/:version/part/:partNumber/book/:bookNumber/title/:titleNumber/chapter/:chapterNumber`,
          ]}
          render={props => {
            const version = selectedCode || props.match.params.version;
            const code = app.contents.find(
              content => content.version === version,
            );
            const partNumber =
              selectedPart || parseInt(props.match.params.partNumber, 10);
            const bookNumber =
              selectedBook || parseInt(props.match.params.bookNumber, 10);
            const titleNumber =
              selectedTitle ||
              parseInt(props.match.params.titleNumber, 10) ||
              null;
            return code != null ? (
              <ContentWrapper withSidebar>
                <BackButton
                  slug="cpc"
                  to={`${url}/code/${version}/part/${partNumber}`}
                >
                  <SVG src={back} />
                </BackButton>
                <Book
                  version={version}
                  partNumber={partNumber}
                  partLevel={0}
                  bookNumber={bookNumber}
                  bookLevel={1}
                  titleNumber={titleNumber || 1}
                  selectTitle={selectTitle}
                  selectArticle={selectArticle}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Este código não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact={false}
          path={[
            `${path}/code/:version/part/:partNumber/book/:bookNumber/title/:titleNumber/article/:articleNumber`,
            `${path}/code/:version/part/:partNumber/book/:bookNumber/title/:titleNumber/chapter/:chapterNumber/article/:articleNumber`,
          ]}
          render={props => {
            const version = selectedCode || props.match.params.version;
            const code = app.contents.find(
              content => content.version === version,
            );
            const partNumber =
              selectedPart || parseInt(props.match.params.partNumber, 10);
            const bookNumber =
              selectedBook || parseInt(props.match.params.bookNumber, 10);
            const titleNumber =
              selectedTitle || parseInt(props.match.params.titleNumber, 10);
            const articleNumber =
              selectedArticle || parseInt(props.match.params.articleNumber, 10);

            return code != null ? (
              <ContentWrapper>
                <BackButton
                  slug="cpc"
                  to={`${url}/code/${version}/part/${partNumber}/book/${bookNumber}/title/${titleNumber}`}
                >
                  <SVG src={back} />
                </BackButton>
                <Article
                  {...props}
                  version={version}
                  articleNumber={articleNumber}
                />
              </ContentWrapper>
            ) : (
              <CenteredWrapper>
                <Info>Este código não está disponível</Info>
              </CenteredWrapper>
            );
          }}
        />
        <Route
          exact
          path={`${path}/bookmarks`}
          render={() => (
            <ContentWrapper>
              <Back
                slug="cpc"
                url={url}
                title="Favoritos em Código de Processo Civil"
              />
              <BookmarksScreen slug="cpc" />
            </ContentWrapper>
          )}
        />
      </Switch>
    );
  }
}

export default CPC;
