import React from 'react';
import PropTypes from 'prop-types';

import { ArticleCard as Card } from 'views/elements/Cards';
import { FlexColWrapper, MarginLeftWrapper } from 'views/elements/Wrappers';
import { ArticleTitleLink } from 'views/elements/Texts';
import { SectionCardActions } from 'views/components/SectionCardActions';

import PointCard from '../PointCard';

export const ParagraphCard = ({
  data,
  articlePath,
  selectParagraph,
  selectPoint,
}) => {
  const path = `${articlePath}/paragraph/${data.number}`;

  return (
    <React.Fragment>
      <Card slug="lms">
        <SectionCardActions
          section={data}
          selectSection={selectParagraph}
          sectionPath={path}
          slug="lms"
        />
        <FlexColWrapper>
          <ArticleTitleLink
            slug="lms"
            to={path}
            onClick={() => selectParagraph(data.number)}
          >
            Parágrafo {data.number}
            {data.number < 10 ? 'º' : ''}
          </ArticleTitleLink>
          <p>{data.text}</p>
        </FlexColWrapper>
      </Card>
      {data.points.length > 0 ? (
        <MarginLeftWrapper level={1}>
          {data.points.map(s => (
            <PointCard
              key={s.id}
              data={s}
              parentPath={path}
              selectPoint={number => selectPoint(number, data.number)}
            />
          ))}
        </MarginLeftWrapper>
      ) : null}
    </React.Fragment>
  );
};

ParagraphCard.propTypes = {
  data: PropTypes.shape({}),
  articlePath: PropTypes.string,
  selectParagraph: PropTypes.func,
  selectPoint: PropTypes.func,
};

ParagraphCard.displayName = 'ParagraphCard';

export default React.memo(ParagraphCard);
