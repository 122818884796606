import React from 'react';
import PropTypes from 'prop-types';

import { RightAlignedWrapper } from 'views/elements/Wrappers';
import { LinkButton } from 'views/elements/Buttons';

export const Header = ({ app, code, version, partNumber, selectPart }) => (
  <RightAlignedWrapper>
    {code &&
      code.main_sections[0].category === 'part' &&
      code.main_sections.map(section => (
        <LinkButton
          key={section.id}
          to={`/app/${app.slug}/code/${code.version}/part/${section.number}`}
          selected={partNumber === section.number}
          onClick={() => selectPart(section.number)}
        >
          {section.name}
        </LinkButton>
      ))}
  </RightAlignedWrapper>
);

Header.propTypes = {
  app: PropTypes.shape({}),
  code: PropTypes.shape({}),
  version: PropTypes.string,
  partNumber: PropTypes.number,
  selectPart: PropTypes.func,
};
